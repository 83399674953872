import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, debounceTime, filter, map, startWith, switchMap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DevizListFilters } from '../deviz-list-filters/deviz-list-filters.component';
import * as _ from 'lodash';

import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { ModalController } from '@ionic/angular';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'deviz-list',
  templateUrl: 'deviz-list.component.html',
  styleUrls: ['deviz-list.component.scss']
})
export class DevizList {
  @Input() standalone: boolean = false;
  @Input() filters: any = {
    "masina_id": "",
    
    "numar_deviz": "", // includes also // "nr_masina": this.filters?.nr_masina || "",
    "nume_proprietar": "", // includes also // "nr_masina": this.filters?.nr_masina || "",

    "page": 1,
    "per_page": 50,

    'start_date': null,
    'end_date': null,
    'proprietar_id': null
  };
  totalItems: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // searchForm = new FormGroup({
  //   masina_id: new FormControl(''),
  //   // nr_masina: new FormControl(''),
  //   nume_proprietar: new FormControl(''),
  //   page: new FormControl('1'),
  //   per_page: new FormControl('10'),
  //   start_date: new FormControl<any>(null),
  //   end_date: new FormControl<any>(null),
  // });
  
  devize_deschise: any[] = [];
  devize_inchise: any[] = [];
  // showList: boolean = true;
  // apiUrl: string = 'http://127.0.0.1:5000';
  apiUrl: string = environment.apiUrl;

  columns = new FormControl('');
  tableColumns: string[] = ['numar_deviz', 'nr_masina', 'nume_proprietar', 'executant', 'total', 'closed', 'options'];
  defaultTableColumns: string[] = ['numar_deviz', 'nr_masina', 'nume_proprietar', 'executant', 'total', 'closed', 'options'];

  constructor( 
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    public breakpointObserver: BreakpointObserver
  ) {
    
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  setTable(columns: any): void {
    this.columns.setValue(columns);
  }

  // ????? use this if there's too many requests
  setFilters(newFilters: any): void {
    if (!_.isEqual(this.filters, newFilters)) {
      this.filters = newFilters;
      this.getDevize();
    }
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(DevizListFilters, {
      data: this.filters,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
        this.filters = result; // sigur nu se seteaza in searchForm
      this.getDevize(); 
    });
  }

  ngOnInit(): void {
    this._cdr.detectChanges();
    //   {}, 
    //   this.searchForm.value, 
    //   { masina_id: this.filters?.masina_id }
    // ));

    // this.searchForm.setValue({
    //   "masina_id": this.filters?.masina_id || "",

    //   // "nr_masina": this.filters?.nr_masina || "",
    //   "nume_proprietar": this.filters?.nume_proprietar || "",

    //   "page": this.filters?.page || '1',
    //   "per_page": this.filters?.per_page || '10',

    //   'start_date': this.filters?.start_date ? this.filters.start_date.toISOString().split('T')[0] : null,
    //   'end_date': this.filters?.end_date ? this.searchForm.value.end_date.toISOString().split('T')[0] : null,
    // });

    this.breakpointObserver
    .observe(['(min-width: 700px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches && !this.standalone) {
        this.setTable(this.defaultTableColumns);
      } else {
        // las doar 4
        this.setTable(['numar_deviz', 'nr_masina', 'nume_proprietar', 'options'])
      }
    });

    this.getDevize();
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('deviz-item') || event.url.includes('deviz-create')) {
          // this.showList = false;
        } else {

          // todo: very inefiecient
          // this.getDevize({closed: false});
          this.getDevize(/*{closed: true}*/);
        }
      }
    });
  }

  getDevize(newFilters?: any): void {
    let params = new HttpParams();
    // const filters = this.filters;
    const filters = newFilters ? Object.assign({}, this.filters, newFilters) : this.filters;

    // check if undefined. if undefined, filters.closed = false
    filters.status = 'DESCHIS';
    if (!('closed' in filters) || filters['closed'] === undefined) {
      filters.closed = false;
    }
    filters.status = filters.closed ? 'INCHIS' : 'DESCHIS';
    // delete filters.closed;

    this.filters = _.cloneDeep(filters);

    if (filters?.nume_proprietar) {
      filters.q = filters.nume_proprietar;
    }
    
    if (filters?.start_date) {
      filters.start_date = this.formatDate(filters.start_date);
    }
  
    if (filters?.end_date) {
      // Add one day to the end date to include the entire last day
      const endDate = new Date(filters.end_date);
      endDate.setDate(endDate.getDate() + 1);
      filters.end_date = this.formatDate(endDate);
    }
  
    Object.keys(filters).forEach(key => {
      if (filters[key] || (filters[key] === false)) {
        params = params.append(key, filters[key]);
      }
    });
  
    this._http.get(`${this.apiUrl}/deviz`, { params: params }).subscribe((devize: any) => {
      this.devize_deschise = devize.data.filter((deviza: any) => !deviza.template);

      const seen: any = {};
      const devize_faulty: any[] = _.cloneDeep(this.devize_deschise);
      devize_faulty.forEach((item: any) => {
        if (seen[item.nume_proprietar]) {
        } else {
          seen[item.nume_proprietar] = true;
        }
      });

      this.totalItems = devize.total_items;
      this.filters.page = devize.current_page;
    });
  }

  pageChange(event: any): void {
    this.filters.page = event.pageIndex + 1;
    this.getDevize(this.filters);
  }

  isMatch(item: any, field: 'nr_masina' | 'nume_proprietar' | 'numar_deviz'): boolean {
    if (!this.filters.nume_proprietar) return false;
    return item[field]?.toString().toLowerCase().includes(this.filters.nume_proprietar.toString().toLowerCase());
  }

  // Remove the time part and convert to ISO string (YYYY-MM-DD)
  formatDate(date: Date): string {
    return new Date(date).toISOString().split('T')[0];
  }

  selectItem(item: any): void {
    this._router.navigate(['/tabs/tab1/deviz-item/' + item.deviz_id]);
  }

  clear(): void {
    // this.searchForm.setValue({
    //   "masina_id": "",

    //   // "nr_masina": this.filters?.nr_masina || "",
    //   "nume_proprietar": "",

    //   "page": '1',
    //   "per_page": '50',

    //   'start_date': null,
    //   'end_date': null
    // });

    this.filters = {
      "masina_id": "",

      // "nr_masina": this.filters?.nr_masina || "",
      "nume_proprietar": "",

      "page": 1,
      "per_page": 50,

      'start_date': null,
      'end_date': null
    };

    this.getDevize();
  }
}
