import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, debounceTime, filter, map, of, startWith, switchMap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { DevizWidget } from '../deviz-widget/deviz-widget.component';

export interface DialogData {
  // animal: string;
  // name: string;
  //
  masina_id?: string | null | undefined;
  nume_proprietar?: string | null | undefined; // searches also for: nr_masina: new FormControl(''),
  page?: string | null | undefined;
  per_page?: string | null | undefined;
  start_date?: string | null | undefined;
  end_date?: string | null | undefined;
  closed?: string;
}

@Component({
  selector: 'deviz-list-filters',
  templateUrl: 'deviz-list-filters.component.html',
  styleUrls: ['deviz-list-filters.component.scss'],
  // imports: [MatDialogModule]
})
export class DevizListFilters {
  destroyed: boolean = true;
  @Input() filters: any = {
    closed: false
  };
  @Output() triggerSearch = new EventEmitter<any>();
  isModal: boolean = false;
  closed = new FormControl(false);  
  searchForm = new FormGroup({
    masina_id: new FormControl(''),
    numar_deviz: new FormControl(''),
    nume_proprietar: new FormControl(''), // cautarea generala
    page: new FormControl(1),
    per_page: new FormControl(50),
    start_date: new FormControl<any>(null),
    end_date: new FormControl<any>(null),
    proprietar_id: new FormControl<any>(null)
  });

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    //
    public dialogRef: MatDialogRef<DevizListFilters>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.isModal = !!Object.keys(this.data).length;
    // this._cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isModal = !!Object.keys(this.data).length;
    this._cdr.detectChanges();
    if(_.isEqual(changes['filters'].previousValue, changes['filters'].currentValue)) return;
    this.filters = changes['filters'].currentValue; //Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;
    
    this.setForm();
  }
  
  ngOnInit(): void {
    this.isModal = !!Object.keys(this.data).length;
    // this._cdr.detectChanges();
    console.log('INIT ar cam face asta de 100 de ori');
    Object.keys(this.searchForm.controls).forEach(key => {
      console.log('FOREACH ar cam face asta de 100 de ori');
      const control = this.searchForm.get(key);
      if(!control) return;
      control.valueChanges.subscribe(value => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if(!value || typeof value === 'number' || !value.toUpperCase || regex.test(value)) return;
        console.log('ar cam face asta de 100 de ori', value);
        control.setValue(value.toUpperCase(), { emitEvent: false });
      });
    });

    const $q = this.searchForm.controls.nume_proprietar.valueChanges.pipe(
      debounceTime(300),
      switchMap(term => {
        this.search();
        return of(true);
      })
    ).subscribe();

    // commented because emit() now is not just setting, it's also searching
    // this.searchForm.valueChanges.pipe(
    //   debounceTime(300),
    // ).subscribe(filtersAsIType => {
    //   this.triggerSearch.emit(filtersAsIType)
    // });

    // const $sumSub = this.searchForm.valueChanges.pipe(
    //   debounceTime(300),
    //   switchMap((term: any) => {

    //     return this.triggerSearch.emit(term);
    //   })
    // );

    this.filters = Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;


    // TODO: also if there's any this.data
    this.setForm();
    // this.searchForm.setValue({
    //   "masina_id": this.filters?.masina_id || "",

    //   // "nr_masina": this.filters?.nr_masina || "",
    //   "nume_proprietar": this.filters?.nume_proprietar || "",

    //   "page": this.filters?.page || '1',
    //   "per_page": this.filters?.per_page || '10',

    //   'start_date': this.filters?.start_date ? this.filters.start_date.toISOString().split('T')[0] : null,
    //   'end_date': this.filters?.end_date ? this.searchForm.value.end_date.toISOString().split('T')[0] : null,
    // });
  }
  @ViewChild('numeProprietarInput') numeProprietarInput!: ElementRef;
  ngAfterViewInit() {
    if(this.destroyed) {
      // todo: search in code and do only on desktop, not on mobile
      // this.numeProprietarInput.nativeElement.focus();
      setTimeout(() => {
        // this.numeProprietarInput.nativeElement.focus();
        this._cdr.detectChanges();
        this.destroyed = false;
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  setForm(): void {
    this.searchForm.setValue({
      "masina_id": this.filters?.masina_id || "",

      "numar_deviz": this.filters?.numar_deviz || "",
      "nume_proprietar": this.filters?.nume_proprietar || "",

      "page": this.filters?.page || 1,
      "per_page": this.filters?.per_page || 50,

      'start_date': this.filters?.start_date ? new Date(this.filters.start_date).toISOString().split('T')[0] : null,
      'end_date': this.filters?.end_date ? new Date(this.filters.end_date).toISOString().split('T')[0] : null,      
      'proprietar_id': this.filters?.proprietar_id ? this.filters.proprietar_id : null
    });
    this.closed.setValue(this.filters.closed);

    console.log('se siiii');
  }

  onNoClick(): void {
    this.filters = this.searchForm.value;
    this.dialogRef.close();
  }

  search(): void {
    this.triggerSearch.emit(Object.assign(this.searchForm.value, { closed: this.closed.value }));
  }

  clear(): void {
    this.filters = {
      "masina_id": "",

      // "nr_masina": this.filters?.nr_masina || "",
      "nume_proprietar": "",

      "page": 1,
      "per_page": 50,

      'start_date': null,
      'end_date': null,
      'closed': false,
      'proprietar_id': null
    };

    this.setForm();
    this.search();
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(DevizListFilters, {
      data: this.filters,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
        this.filters = result; // sigur nu se seteaza in searchForm
      
      this.setForm();
      this.search();
    });
  }

  openTemplates(): void {
    const dialogRef = this.dialog.open(DevizWidget, {
      // data: this.deviz.operatiuni_manopera,
      height: '80%',
      width: '80%'
    });

    const instance = dialogRef.componentInstance;
    instance.justTemplates = true

    dialogRef.afterClosed().subscribe(result => {
      // if(!result.length) return;
      // result.forEach((newManopera: any) => {
      //   this.addItem(newManopera);
      // });
    });
  }
}
