import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ErrorStateMatcher, MatNativeDateModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from "@angular/material/menu";
import { QuillModule } from 'ngx-quill';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';

import { NomenclatorList } from './nomenclator/components/nomenclator-list/nomenclator-list.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MasinaList } from './masina/components/masina-list/masina-list.component';
import { DevizList } from './deviz/components/deviz-list/deviz-list.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DevizListFilters } from './deviz/components/deviz-list-filters/deviz-list-filters.component';
import { MasinaListFilters } from './masina/components/masina-list-filters/masina-list-filters.component';
import { PieseListFilters } from './lucrare/components/piese-list-filters/piese-list-filters.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeComponent } from './home/home.component';
import { AuthService } from './home/auth.service';
import { PieseList } from './lucrare/components/piese-list/piese-list.component';
import { DevizWidget } from './deviz/components/deviz-widget/deviz-widget.component';
import { PiesaItemView } from './lucrare/components/piesa-item-view/piesa-item-view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SavingDialogComponent } from './deviz/components/dialogs/saving-dialog/saving-dialog.component';

// different project
import { S1Component } from './forbidden/s1/s1.component';
import { AiParseComponent } from './forbidden/s1/components/ai-parse/ai-parse.component';
import { PullTagComponent } from './forbidden/s1/components/pull-tag/pull-tag.component';
import { CreateManageComponent } from './forbidden/s1/components/create-manage/create-manage.component';
import { ListenComponent } from './forbidden/s1/components/listen/listen.component';
import { DevizForm, KeyboardAdjustDirective } from './deviz/components/deviz-form/deviz-form.component';
import { ConfirmCreateDialog } from './deviz/components/dialogs/confirm-create-deviz-dialog/confirm-create-dialog.component';
import { ConfirmMakeDialog } from './deviz/components/dialogs/confirm-create-modify-sablon-dialog/confirm-make-dialog.component';
import { SheetForm } from './deviz/components/deviz-form/sheet-form/sheet-form.component';
import { SongListComponent } from './forbidden/s1/components/global/song-list/song-list.component';
import { CheckServiceComponent } from './forbidden/s1/components/global/check-service/check-service.component';

@Inject({provideIn: 'root'})
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid) || true;
  }
}

@Pipe({
  name: 'filterByKey',
  // standalone: true
})
export class FilterByKeyPipe implements PipeTransform {
  transform(items: any[], key: string): any[] {
    if (!items) return [];
    if (!key) return items;
    return items.filter(item => item.hasOwnProperty(key) && item[key]);
  }
}

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule, 
    // BrowserAnimationsModule,
    
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatCheckbox,
    MatExpansionModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatStepperModule,
    MatChipsModule,
    QuillModule.forRoot(),
    
    LayoutModule
  ],
  declarations: [
    FilterByKeyPipe,

    HomeComponent,

    NomenclatorList,

    MasinaList,
    MasinaListFilters,
    
    PieseListFilters,
    PieseList,
    PiesaItemView,
    
    DevizForm,
    DevizList,
    DevizWidget,
    DevizListFilters,
    ConfirmCreateDialog,
    ConfirmMakeDialog,
    SheetForm,

    SavingDialogComponent,

    // different project
    S1Component,
    SongListComponent,
    CheckServiceComponent,
    CheckServiceComponent,
    PullTagComponent,
    CreateManageComponent,
    ListenComponent,
    AiParseComponent,

    KeyboardAdjustDirective
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // BrowserAnimationsModule,
    
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatCheckbox,
    MatExpansionModule,
    QuillModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatStepperModule,
    MatChipsModule,

    // from other modules
    NomenclatorList,

    MasinaList,
    MasinaListFilters,

    PieseListFilters,
    PieseList,
    PiesaItemView,

    DevizForm,
    DevizList,
    DevizWidget,
    ConfirmCreateDialog,
    ConfirmMakeDialog,
    SheetForm,
    SavingDialogComponent,

    FilterByKeyPipe,

    KeyboardAdjustDirective
  ],
  providers: [
    AuthService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    // { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {provide: MAT_DIALOG_DATA,useValue:{}}
    // DialogService
 ],
})
export class InterCommonModule {}
