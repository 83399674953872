import { Component, Inject, Input, Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DevizListFilters } from '../deviz-list-filters/deviz-list-filters.component';
import * as _ from 'lodash';
import { DevizItemView } from '../deviz-item-view/deviz-item-view.component';
import { ConfirmCreateDialog } from '../dialogs/confirm-create-deviz-dialog/confirm-create-dialog.component';

export interface DialogData {
  operatiuni_manopera: any[]
}

// fac side scrolling sau buton cu 'deschide nesalvate'
@Component({
  selector: 'deviz-widget',
  templateUrl: 'deviz-widget.component.html',
  styleUrls: ['deviz-widget.component.scss'],
  // standalone: true,
  // imports: [FilterByKeyPipe]
})
export class DevizWidget {
  apiUrl: string = environment.apiUrl;
  devize: any[] = [];
  sabloane: any[] = [];
  // draftsArray: any[] = [];
  defaultDeviz: any = {
    deviz_id: null
  };

  // searchCtrl = new FormControl();
  search: string | null = null;

  @Input() justTemplates: boolean = false;
  // @Input() currentDeviz: boolean = false;

  @Input() 
  set data(smth: any) {
    this.refresh();
  }

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DevizItemView>,
    @Inject(MAT_DIALOG_DATA) public dataFromDeviz: DialogData
  ) {
    // simply on form changes trigger obsevable for drafts in localstorage
    // maybe in the future: save with date_created, and just show drafts fro today. have link to open modal showing all drafts
    this.getDrafts();
    const devizId = this._activatedRoute.snapshot.paramMap.get('deviz_id');
    if (devizId) {
      this.getDeviz(devizId);
    }

    // const $search = this.searchCtrl.valueChanges.pipe(
    //   debounceTime(300),
    //   tap(term => {
    //     this.searchSablon(term);
    //   })
    // );
    // $search.subscribe();
  }

  searchSablon(): void {
    let params
    if(this.search) {
      params = new HttpParams().append('search', this.search);
    }
    this._http.get(`${this.apiUrl}/devize/templates`, { params: params }).subscribe((devize: any) => {
      this.sabloane = devize;
      this.checkFirst();
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.getSabloane();
    this.getDevize();
  }

  getDeviz(deviz_id: any): void {
    this._http.get(`${this.apiUrl}/deviz/${deviz_id}`).subscribe((deviz: any) => {
      this.defaultDeviz = deviz;
      this.checkFirst();
    });
  }

  getDevize(): void {
    this._http.get(`${this.apiUrl}/deviz`/*, { params: params }*/).subscribe((devize: any) => {
      this.devize = devize.data;
      this.checkFirst();
    });
  }

  getSabloane(): void {
    this._http.get(`${this.apiUrl}/devize/templates`/*, { params: params }*/).subscribe((devize: any) => {
      this.sabloane = devize;
      this.checkFirst();
    });
  }

  selectSablon(item: any): void {
    if(this.dialogRef?.close && !this.justTemplates) {
      this.dialogRef.close(item.operatiuni_manopera);
      return;
    } 

    const dialogRef = this.dialog.open(ConfirmCreateDialog, {
      width: '700px',
      data: item
    });
  
    dialogRef.afterClosed().subscribe(result => {
      // Handle the dialog result here (true for Yes, false for No)
      if(this.dialogRef?.close && result) {
        this.dialogRef.close();
      }
    });
  }


  openDeviz(item: any): void {
    this._router.navigate(['/tabs/tab1/deviz-item/' + item.deviz_id]);
  }

  createTemplate(): void {
    this._router.navigate(['/tabs/tab1/deviz-create/']);
    if(this.dialogRef?.close) {
      this.dialogRef.close();
    }
  }

  checkFirst(): void {
    if(this.defaultDeviz) {
      let exists = this.devize.some(obj => obj.deviz_id === this.defaultDeviz.deviz_id);

      if (!exists) {
          this.devize.unshift(this.defaultDeviz);
      }
    }
  }


  // later
  getDrafts(): void {
    // this.draftsService.get().subscribe(drafts => {
    //   // put drafts in draftsArray;
    // });
  }

}