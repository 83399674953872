<div class="page">
  <div class="pageContent">

    <div role="alert" class="flex flex-row flex-wrap rounded border-s-4 _border-red-500 _bg-red-50 border-green-600 bg-green-100 bg-opacity-10 p-4 mb-4 w-full" *ngIf="mergedProprietari?.length">
      <div class="flex flex-col w-full lg:w-6/12 lg: pr-6">
        <div class="flex items-center gap-2 _text-red-800 text-green-200">
          <ion-icon name="checkmark-circle-outline" class="text-xl"></ion-icon>
          <strong class="block font-medium"> Succes! </strong>
        </div>
      
        <p class="mt-2 text-sm _text-red-700 text-green-500">
          Ai unit acest proprietar cu urmatorii proprietari. Puteti deschide intr-o fereastra noua pentru a elimina profilurile din baza de date.
        </p>
      </div>

      <div class="_scrollable-list overflow-x-auto flex flex-row justify-center items-center w-full lg:w-6/12">
        <div
        *ngFor="let uuid of mergedProprietari; let i = index;"
        class="min-w-36 mx-2 bg-transparent border shadow-md rounded-lg px-2 hover:shadow-lg transition-shadow duration-300"
        >
          <a
            [href]="'/tabs/tab4/proprietari-item/' + uuid"
            target="_blank"
            class="block text-white font-semibold text-lg text-center"
          >
            {{ mergedProprietariNames[i] }}
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full" [ngClass]="{'flex-wrap': (viewState !== 'SECONDARY_ROUTE'), 'flex-wrap-reverse': (viewState == 'SECONDARY_ROUTE')}">
      <div class="flex flex-col w-full lg:w-7/12">
        <span class="text-lg lg:text-3xl font-bold" *ngIf="!formEnabled">{{ defaultItem.nume_proprietar }}</span>
        <span class="text-base lg:text-xl opacity-90" *ngIf="!formEnabled">{{ getClientState(defaultItem.client_state) }}</span>

        <form [formGroup]="form" class="mt-2 flex flex-col gap-2 w-full">

          <div class="flex flex-col md:flex-row _lg:flex-row justify-start md:justify-between gap-4 w-full">
            <mat-form-field appearance="fill" class="w-full md:w-8/12" *ngIf="formEnabled">
              <mat-label>Nume proprietar</mat-label>
              <input matInput type="text" formControlName="nume_proprietar">
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full md:w-8/12" *ngIf="formEnabled">
              <mat-label>Tip utilizator</mat-label>
              <mat-select formControlName="client_state">
                <mat-option *ngFor="let state of clientStates" [value]="state">
                  {{ getClientState(state) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
    
            <!-- <div class="grid grid-cols-7 gap-2" *ngIf="(viewState !== 'SECONDARY_ROUTE') && isSuperAdmin">
              <mat-form-field appearance="fill" class="col-span-5">
                <mat-label>Firebase UID</mat-label>
                <input matInput type="text" formControlName="firebase_uid">
              </mat-form-field>
            </div> -->
    
            <!-- <div class="flex flex-row justify-start items-center w-full _mt-1 _mb-3"> 
              <mat-form-field class="mr-2 min-w-52" *ngIf="defaultItem.numere && defaultItem.numere?.length">
                <mat-label>Numere de telefon</mat-label>
                <mat-select formControlName="numere" (onclick)="copyNumber($event)">
                  @for (numar of contacteCtrl.value; track numar) {
                    <mat-option [value]="contacteCtrl.value">
                      <div class="grid grid-cols-5 w-full h-full">
                        <span class="col-span-4 w-full h-full" (click)="copyNumber(numar)">{{numar}}</span>
                        <span class="col-span-1 w-full h-full pl-2 border-l" (click)="deleteNumber(numar)" *ngIf="formEnabled"><ion-icon name="trash-bin-outline"></ion-icon></span>
                      </div>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div class="flex flex-row justify-center items-center" *ngIf="formEnabled && (viewState === 'PRIMARY_ROUTE')">
                <mat-form-field appearance="fill" class="mr-1 w-full">
                  <mat-label>Numar nou</mat-label>
                  <input matInput type="text" formControlName="numar_nou">
                </mat-form-field>
                <button mat-stroked-button (click)="adaugaNumar()" class="_col-span-2 flex flex-row justify-center items-center ml-3 h-14">
                  <ion-icon name="add-circle-outline" class="text-2xl mt-2"></ion-icon>
                </button>
              </div>
            </div> -->
          </div>
        </form>
      </div>

      <div class="flex flex-row justify-between lg:justify-end items-center mt-4 lg:mt-0 w-full lg:w-5/12">
        <div class="flex flex-row justify-end items-center _mt-2 mr-2">
          <button mat-stroked-button (click)="createDeviz()" *ngIf="(viewState !== 'SECONDARY_ROUTE') && defaultItem?.proprietar_id && !formEnabled" class="w-full _h-14">
            <div class="w-full flex flex-row justify-center items-center">
              <ion-icon name="add-circle-outline" class="text-2xl mr-2"></ion-icon>
              <span class="block">Creeaza deviz</span>
            </div>
          </button>
        </div>

        <div class="flex flex-row justify-start items-center _mt-2">
          <button mat-stroked-button type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && defaultItem?.proprietar_id" (click)="edit()">
            <span *ngIf="!formEnabled">Editeaza</span>
            <span *ngIf="formEnabled">Cancel</span>
          </button>
          <button mat-raised-button color="accent" type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && formEnabled && defaultItem.proprietar_id"(click)="updateProprietar()" class="ml-4">Salveaza schimbari</button>
          <button mat-raised-button color="accent" type="button" *ngIf="!defaultItem.proprietar_id && formEnabled" (click)="saveProprietar()" class="ml-2">Salveaza</button>
          
          <button mat-button [matMenuTriggerFor]="menu" *ngIf="defaultItem.proprietar_id">
            <ion-icon name="ellipsis-horizontal-circle-outline" class="text-3xl mt-1"></ion-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item type="button" (click)="startMerge()" class="w-full _h-14 whitespace-nowrap" *ngIf="(viewState !== 'SECONDARY_ROUTE') && defaultItem?.proprietar_id">
              <div class="w-full flex flex-row justify-start items-center">
                <ion-icon name="share-social-outline" class="text-xl mr-2"></ion-icon>
                <span class="block">Uneste proprietarul</span>
              </div>
            </button>
  
            <button mat-menu-item (click)="addFirebaseUID()" class="col-span-2 flex flex-row justify-center items-center w-full whitespace-nowrap">
              <div class="w-full flex flex-row justify-start items-center">
                <ion-icon name="add-circle-outline" class="text-xl mr-2"></ion-icon>
                <span class="block">Adauga utilizator extern</span>
              </div>
            </button>

            <button mat-menu-item type="button" color="warn" *ngIf="(viewState === 'PRIMARY_ROUTE')" (click)="deleteProprietar()" class="mr-4 text-red-500">
              <div class="w-full flex flex-row justify-start items-center">
                <ion-icon name="trash-outline" class="text-xl mr-2"></ion-icon>
                <span>Elimina</span>
              </div>
            </button>
          </mat-menu>
        </div>

      </div>
    </div>
 


      <div class="flex flex-row flex-wrap justify-start items-start w-full mt-8 mb-3 bg-slate-600 bg-opacity-20 p-2 rounded-s" [ngClass]="{'h-[455px] _lg:h-[246px] lg:h-64': !formEnabled, 'h-[590px] lg:h-72': formEnabled}">
        <div class="h-52 w-full lg:w-8/12 lg:pr-4">
          <quill-editor class="h-56 w-full" *ngIf="formEnabled" [ngModelOptions]="{standalone: true}" [disabled]="false" [(ngModel)]="richTextContent" placeholder="Detaliii proprietar" [modules]="quillOptions"></quill-editor>
          
          <div *ngIf="!formEnabled" class="h-52 w-full border-slate-900 p-1">
            <h3 class="font-bold text-xl mb-2">Note:</h3>
            <div class="quill-html w-full prose text-white" *ngIf="richTextContent" [innerHTML]="richTextContent"></div>
            <span *ngIf="!richTextContent">Nu existe note aditionale</span>
          </div>
        </div>
        <div class="_h-36 w-full lg:w-4/12 flex flex-col">
          <div class="flex flex-row mt-[87px] lg:mt-0 mb-2" *ngIf="formEnabled">
            <mat-form-field appearance="fill" class="w-9/12">
              <mat-label>Numar nou</mat-label>
              <input matInput type="text" [(ngModel)]="numar_nou" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <button mat-stroked-button class="w-3/12 ml-2 h-14" (click)="adaugaNumar()"> 
              <div class="flex flex-row justify-center items-center">
                <ion-icon name="add-circle-outline" class="mr-1"></ion-icon>
                <span>Adauga</span>
              </div>
            </button>
          </div>

          <h3 class="font-bold text-xl mb-2" *ngIf="!formEnabled">Numere de telefon:</h3>
          <!-- <mat-selection-list #numar_telefon *ngIf="defaultItem?.numere_array?.length">
              <div *ngFor="let numar of defaultItem.numere_array; let i = index;">
                <mat-list-option (click)="1 + 'edit somehow'">{{numar}}</mat-list-option>
                <mat-form-field appearance="fill" *ngIf="defaultItem.numere_array[i]">
                  <input matInput type="text" [(ngModel)]="defaultItem.numere_array[i]" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>
          </mat-selection-list> -->

          <div class="scrollable-list" [ngClass]="{'mt-2 lg:mt-0': formEnabled}" *ngIf="defaultItem?.numere_array?.length">
            <mat-accordion>
              <mat-expansion-panel *ngFor="let numar of defaultItem.numere_array; let i = index; trackBy: trackByIndex" #expansionPanel [(expanded)]="expanded_array[i]">
                <mat-expansion-panel-header>
                  <mat-panel-title *ngIf="formEnabled">
                    {{ expansionPanel.expanded ? 'Editing' : numar }}
                  </mat-panel-title>
                  <mat-panel-title *ngIf="!formEnabled">
                    {{ expansionPanel.expanded ? 'Optiuni' : numar }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
          
                <div class="input-container">
                  <mat-form-field class="w-full" appearance="fill" *ngIf="(defaultItem.numere_array[i] !== null || defaultItem.numere_array[i] !== undefined) && formEnabled">
                    <input matInput type="tel" [(ngModel)]="defaultItem.numere_array[i]" [ngModelOptions]="{standalone: true}" (keypress)="$event.stopPropagation()">
                  </mat-form-field>
                  <div *ngIf="!formEnabled">
                    <button mat-stroked-button (click)="copyNumber(numar)">
                      <div class="flex flex-row justify-center items-center">
                        <ion-icon name="copy-outline" class="mr-2"></ion-icon>
                        <span>Copy</span>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <span class="_text-center mt-2 min-h-52" *ngIf="!defaultItem?.numere_array?.length">Nu exista numere de telefon</span>
        </div>
      </div>

    <!-- <mat-divider class="my-4 mt-16 w-full" *ngIf="(viewState !== 'SECONDARY_ROUTE') && (viewState === 'PRIMARY_ROUTE')"></mat-divider> -->

    <!-- <div class="flex flex-row justify-between items-center w-full" *ngIf="(viewState !== 'SECONDARY_ROUTE') && (viewState === 'PRIMARY_ROUTE')">
      <span class="block font-bold text-3xl mb-4">Masinile proprietarului</span>
      <button mat-stroked-button (click)="creeazaMasina()" class="_w-2/12 mb-5 h-14">
        <div class="flex flex-row justify-center items-center">
          <ion-icon name="add-circle-outline" 
                    class="block text-2xl mr-2"></ion-icon>
          <span class="block"><span>Creeaza </span> </span>
        </div>
      </button>
    </div> -->

    <mat-tab-group class="_col-span-2 _tabs-height w-full mt-14" *ngIf="(viewState !== 'SECONDARY_ROUTE') && defaultItem.proprietar_id">
      <mat-tab [label]="'Devize'">

        <button mat-stroked-button (click)="createDeviz()" class="w-full mb-2 mt-4 h-14">
          <div class="w-full flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" class="text-2xl mr-2"></ion-icon>
            <span class="block">Creeaza deviz</span>
          </div>
        </button>

        <deviz-list [standalone]="true" [filters]="{
          proprietar_id: defaultItem.proprietar_id
        }" class="w-full"></deviz-list>
      </mat-tab>
      <mat-tab [label]="'Masini'">

        <button mat-stroked-button (click)="creeazaMasina()" class="w-full mb-2 mt-4 h-14">
          <div class="flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" 
                      class="block text-2xl mr-2"></ion-icon>
            <span class="block"><span>Creeaza </span> </span>
          </div>
        </button>

        <masina-list [filters]="{
          proprietar_id: defaultItem.proprietar_id
        }" class="w-full"></masina-list>
        </mat-tab>
    </mat-tab-group>

  </div>
</div>
