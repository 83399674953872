<ion-header></ion-header>
<ion-content class="ion-padding">
    <mat-tab-group class="w-full overflow-scroll" [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onChangeTab($event)">
      <mat-tab label="PULL / TAG" class="overflow-scroll w-full">
        <pull-tag-component></pull-tag-component>
      </mat-tab>

      <mat-tab label="Create / Manage" class="overflow-scroll w-full"> 
        <create-manage-component></create-manage-component>  
      </mat-tab>

      <mat-tab label="Listen" class="overflow-scroll h-full"> 
        <listen-component class="h-full block"></listen-component>

        <!-- <div *ngFor="let item of allTags" class="overflow-scroll">
          <button (click)="manageTag(item)">
            <p>{{item.tag}} - {{ item.Description }}</p>
          </button>
        </div> -->
      </mat-tab>
  
      <mat-tab label="AI">
        <!-- 
          text box, send to openAi, make payload for some /search endpoint 
          which will find song id's for the service(spotify), and send to spotify to create a playlist
        -->
        <ai-parse-component></ai-parse-component>
      </mat-tab>
    </mat-tab-group>
</ion-content>

<!-- 
  all / old / meta / dont't forget date added etc
  
  create/manage 
  -is actually the pop-up;alsdmfansdg;kadklfm nu inteleg
  - should list also songs to be added - or adds to the listen state where you can filter and tag further - should have some filter
  
  pull/tag
  
  untagged / old_untagged / just metadata

-->







































<!-- other feature -->
<!-- <ion-button primary (click)="read()">
  Read for
</ion-button>
<ion-button secondary (click)="clear()">
  Clear
</ion-button>

<div>
  <h3>Result</h3>
  <div *ngFor="let item of data">
    <p>{{item.text}}</p>
    <img [src]="item.image" />
  </div>
</div> -->