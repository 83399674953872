import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PieseListFilters } from '../piese-list-filters/piese-list-filters.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { PiesaItemView } from '../piesa-item-view/piesa-item-view.component';
import { DialogData } from 'src/app/deviz/components/deviz-list-filters/deviz-list-filters.component';
import { MenuController } from '@ionic/angular';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

@Component({
  selector: 'piese-list',
  templateUrl: 'piese-list.component.html',
  styleUrls: ['piese-list.component.scss']
})
export class PieseList {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
             'SECONDARY_ROUTE' | // used on deviz to create a new car
             'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
             'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG

  // END KEEP

  smallScreen: boolean = false;
  @Input() filters: any = {
    search_query: '',
    fk_masina_id: '',
    serie_sasiu: '',
    lucrare_id: '',
    denumire_piesa: '',
    cod_oe_piesa: '',
    denumire_producator: '',
    cod_producator: '',
    start_date: '',
    end_date: '',
    page: 1,
    per_page: 50
  };
  totalItems: any;

  lucrari: any[] = [];
  apiUrl: string = environment.apiUrl;

  columns = new FormControl('');
  tableColumns: string[] = ['denumire_piesa', 'cod_oe_piesa', 'denumire_producator', 'cod_producator'/*, 'adaos'*/, 'pret_unitar_fara_tva_lei', 'options'];
  defaultTableColumns: string[] = ['denumire_piesa', 'cod_oe_piesa', 'denumire_producator', 'cod_producator'/*, 'adaos'*/, 'pret_unitar_fara_tva_lei', 'options'];
  minimizedColumns: string[] = ['options', 'denumire_piesa'/*, 'cod_oe_piesa'*/, 'denumire_producator'/*, 'cod_producator'*/, 'pret_unitar_fara_tva_lei'];

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private menuCtrl: MenuController,
    private _interface: InterfaceService,
    // public dialogRef: MatDialogRef<PiesaItemView>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  setFilters(newFilters: any): void {
    if(!_.isEqual(newFilters, this.filters)) {
      this.filters = newFilters;
      this.getPiese(this.filters);
    }
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(PieseListFilters, {
      data: this.filters,
      width: '90%',
      height: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
        this.filters = result;
      this.getPiese(); 
    });
  }

  setTable(columns: any): void {
    this.columns.setValue(columns);
  }
  
  ngOnInit(): void {
    // figure out state
    this.viewState = !!Object.keys(this.data).length ? 'DIALOG' : this.viewState;
    this.viewState = this.filters.fk_masina_id ? 'STANDALONE': this.viewState;
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }

    if(this.viewState === 'DIALOG') {
      this.filters = Object.assign({}, this.filters, this.data);
    }

    if(this.viewState !== 'PRIMARY_ROUTE') {
      this.setTable(this.minimizedColumns);
    } else {
      this.setTable(this.defaultTableColumns);
    }

    this.breakpointObserver
    .observe(['(min-width: 700px)'])
    .subscribe((state: BreakpointState) => {
      this.smallScreen = !state.matches;
      if (state.matches && this.viewState !== 'DIALOG') { 
        this.setTable(this.defaultTableColumns);
      } else { 
        this.setTable(this.minimizedColumns);
      }
    });

    this.getPiese();
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('piese-item') || event.url.includes('piese-create')) {
        } else {
          this.getPiese();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(_.isEqual(changes['filters'].previousValue, changes['filters'].currentValue)) return;
    this.filters = changes['filters'].currentValue;

    this.getPiese();
  }

  getPiese(newFilters?: any): void {
    let params = new HttpParams();
    const filters = newFilters ? Object.assign({}, this.filters, newFilters) : this.filters;
    this.filters = _.cloneDeep(filters);

    if(!this.filters.page) {
      this.filters.page = 1;
    }
    if(!this.filters.per) {
      this.filters.per_page = 50;
    }

    filters.q = filters.search_query;
    filters.search_query = null;
    
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        params = params.append(key, filters[key]);
      }
    });
    this._http.get(`${this.apiUrl}/lucrare`, { params: params }).subscribe((piese: any) => {
      this.lucrari = piese.data;

      this.totalItems = piese.total_items;
      this.filters.page = piese.current_page;
    });
  }

  pageChange(event: any): void {
    this.filters.page = event.pageIndex + 1;  // Update the page number in filters
    this.getPiese(this.filters);
  }

  selectItem(item: any): void {
    if(this.viewState === 'DIALOG') {
      this._interface.closeDialog(item);
    } else {
      this._router.navigate(['/tabs/tab5/piese-item/' + item.lucrare_id])
    }
  }

  clearFilters(): void {
    this.filters = {
      search_query: '',
      fk_masina_id: '',
      denumire_piesa: '',
      cod_oe_piesa: '',
      denumire_producator: '',
      cod_producator: '',
      start_date: '',
      end_date: '',
      page: 1,
      per_page: 50
    };

    this.getPiese();
  }

  getColumnDisplayName(column_id: string): string {
    switch (column_id) {
      case 'lucrare_id':
        return 'Lucrare ID'
      case 'fk_masina_id':
        return 'Masina ID'
      case 'old_excel_index':
        return 'Old Excel Index'
      case 'denumire_piesa':
        return 'Denumire Piesa'
      case 'cod_oe_piesa':
        return 'Cod OE Piesa'
      case 'denumire_producator':
        return 'Denumire Producator'
      case 'cod_producator':
        return 'Cod Producator'
      case 'adaos':
        return 'Adaos'
      case 'pret_unitar_fara_tva_lei':
        return 'Pret fara TVA'
      case 'approximate_date':
        return 'Data aproximativa'
      default:
        return ''
    }
  }

  async addPiesa(): Promise<void> {
    this._interface.piesaCreate(this.filters?.fk_masina_id)
  }

}
