<!-- <ion-menu side="start" menuId="first" contentId="main"> -->
    <ion-header>
        <ion-toolbar>
        <ion-title>Meniu</ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content id="main">
        <ion-list>
            <ion-item button (click)="navigate('/tabs/home')">
                <ion-icon name="home" slot="start"></ion-icon>
                Pagina principala
            </ion-item>
            <ion-item button (click)="navigate('/tabs/tab1')">
                <ion-icon name="desktop-outline" slot="start"></ion-icon>
                Devize
            </ion-item>
            <ion-item button (click)="navigate('/tabs/tab2')">
                <ion-icon name="book-outline" slot="start"></ion-icon>
                Nomenclator
            </ion-item>
            <ion-item button (click)="navigate('/tabs/tab3')">
                <ion-icon name="car-outline" slot="start"></ion-icon>
                Masini
            </ion-item>
            <ion-item button (click)="navigate('/tabs/tab4')">
                <ion-icon name="people-outline" slot="start"></ion-icon>
                Proprietari
            </ion-item>
            <ion-item button (click)="navigate('/tabs/tab5')">
                <ion-icon name="hammer-outline" slot="start"></ion-icon>
                Piese
            </ion-item>

            <ion-item button (click)="logout()">
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                Log out
            </ion-item>
        </ion-list>
    </ion-content>
<!-- </ion-menu> -->
