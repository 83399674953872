import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

interface DialogData {
  any?: any
}

@Component({
  selector: 'masina-list-filters',
  templateUrl: 'masina-list-filters.component.html',
  styleUrls: ['masina-list-filters.component.scss']
})
export class MasinaListFilters {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' |   // normal item route - not applicable here
             'SECONDARY_ROUTE' | // - not applicable here
             'STANDALONE' | // for lists and filters - applicable
             'DIALOG' = 'PRIMARY_ROUTE'; // applicable

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG

  // END KEEP

  // @Input() standalone: boolean = false;
  @Input() filters: any;
  @Output() triggerSearch = new EventEmitter<any>();
  // isModal: boolean = false;

  searchForm = new FormGroup({
    masina_id: new FormControl(''),
    search_query: new FormControl(''),
    serie_sasiu: new FormControl(''),
    nr_masina: new FormControl(''),
    tip_motor_masina: new FormControl(''),
    proprietar_id: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    page: new FormControl(1),
    per_page: new FormControl(50)
  });



  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _interface: InterfaceService,

    // public dialogRef: MatDialogRef<MasinaListFilters>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    
  }

  ngOnInit(): void {
    /* BLOCK THAT FIGURES OUT THE STATE */
    // FIRST
    this.viewState = !!Object.keys(this.data).length ? 'DIALOG' : this.viewState;
    // if (this._interface.isSecondaryOutletActive()) {
    //   this.viewState = 'SECONDARY_ROUTE';
    // }

    // ELSE, REMAINS 'PRIMARY_ROUTE'
    /* END BLOCK THAT FIGURES OUT THE STATE */

    // FORM RELATED
    Object.keys(this.searchForm.controls).forEach(key => {
      const control = this.searchForm.get(key);
      if(!control) return;
      control.valueChanges.subscribe(value => {
        if(!value || (typeof value === 'number')) return;
        control.setValue(value.toUpperCase(), { emitEvent: false });
      });
    });

    this.searchForm.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(filtersAsIType => {
      this.search();
    });

    this.filters = Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;

    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(_.isEqual(changes['filters'].previousValue, changes['filters'].currentValue)) return;
    this.filters = changes['filters'].currentValue; //Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;
    this.setForm();
  }

  setForm(): void {
    this.searchForm.setValue({
      "masina_id": this.filters?.masina_id || "",
      "search_query": this.filters?.search_query || "",
      "serie_sasiu": this.filters?.serie_sasiu || "",
      "nr_masina": this.filters?.nr_masina || "",
      "tip_motor_masina": this.filters?.tip_motor_masina || "",
      "proprietar_id": this.filters?.proprietar_id || "",
      "start_date": this.filters?.start_date || "",
      "end_date": this.filters?.end_date || "",
      "page": this.filters?.page || 1,
      "per_page": this.filters?.per_page || 50
    });
  }

  onNoClick(): void {
    this.filters = this.searchForm.value;
    this._interface.closeDialog();
  }

  search(): void {
    this.triggerSearch.emit(this.searchForm.value);
    // in list this also has to trigger the form
    // send with output, outputh catches and gives this.searchform.value to this.filters in list and triggers the search
  }

  clear(): void {
    this.filters = {
      search_query: '',
      serie_sasiu: '',
      nr_masina: '',      
      tip_motor_masina: '',
      proprietar_id: '',
      start_date: '',
      end_date: '',
      page: 1,
      per_page: 50
    };

    this.setForm();
    this.search();
  }

  openFilters(): void {
    const $sub = this._interface.openCarFilters(this.filters);
    $sub.subscribe((result: any) => {
      if(result) 
        this.filters = result;
      this.setForm();
      this.search();
    })
  }
}
