import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ListenService } from '../../providers/listen.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'create-manage-component',
  templateUrl: './create-manage.component.html',
  styleUrls: ['./create-manage.component.scss']
})
export class CreateManageComponent {
  public apiUrl: string = 'http://localhost:3000';

  allTags: any[] = [];
  oldTags: any[] = [];
  @Input() selectedTags: any[] = [];
  filteredTags = [...this.allTags];
  searchTerm: string | null = null;
  @Input() songId: string | undefined = undefined;

  types: any[] = [];

  newTag: any = {
    tag: null,
    type: null,
    Color: null,
    Description: null
  }
  
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _httpClient: HttpClient,
    private _listenService: ListenService,
    private _modalCtrl: ModalController
  ) {
  }
  
  ngOnInit(): void {
    // this.songId = '1721062c-401c-4ea3-8c14-abc151a0d124'; // lil DURK // !! can come also with existent tags when component triggered from listen tab
    console.log('opened with song id? or selected tags?', this.songId);
    this.getTags(this.songId, this.selectedTags);
    this.getTypes();
  }

  getTags(songId?: string, selectedTags?: string[]): void {
    let params = new HttpParams();
    this._httpClient.get(this.apiUrl + '/tags').subscribe((data: any) => {
      // do in another array with dropdown or smth to show old tags songs and tag from listen somehow
      this.allTags = data.filter((tg: any) => oldTags.indexOf(tg.id) === -1);
      this.filteredTags = this.allTags;
      if(songId) {
        // get tags of a song, mark alltags with the ones already tagged with
        this._httpClient.get(this.apiUrl + '/song/' + songId).subscribe((data: any) => {
          // this.allTags = data.filter((tg: any) => oldTags.indexOf(tg.id) === -1);
          // this.filteredTags = this.allTags;
          console.log('tags of the song', data);
          // now update selectedtags and grey out the ones in filteredtags
          this.selectedTags = data;
          if(data.length) {
            data.forEach((tag: any) => {
              const targetButton = this.filteredTags.find(tg => tg.id === tag.id);
              if (targetButton) {
                targetButton.disabled = true;
              }
            });
          }
        });
      }
      if(this.selectedTags.length) {
        this.selectedTags.forEach((tag: any) => {
          const targetButton = this.filteredTags.find(tg => tg.id === tag.id);
          if (targetButton) {
            targetButton.disabled = true;
          }
        });
      }
    });
  }

  setSelectedToSong(): void {
    console.log('setting tags to: ', this.songId, this.selectedTags);
    // then close modal
    const payload = {
      tags: this.selectedTags.map((tg) => tg.id)
    }

    console.log('the payload for put', payload);

    this._httpClient.post(this.apiUrl + '/tag/' + this.songId, payload).subscribe(data => {
      console.log('updated a song\'s tags', data);
      this.getTags(this.songId);
    });
  }

  setSelectedToFilters(): void {
    console.log('this.selectedTags', this.selectedTags);
    // this._modalCtrl.dismiss(this.selectedTags.map((tag: any) => tag.id));
    // if(this.selectedTags && this.selectedTags.length) {
      this._modalCtrl.dismiss(this.selectedTags);
    // }
  }

  getTypes(): void {
    this._httpClient.get(this.apiUrl + '/tags/types').subscribe((data: any) => {
      this.types = data;
    });
  }

  getTagsByType(type: string | null): any[] {
    if(type) {
      const filteredTags = this.filteredTags.filter((tg: any) => tg.type === type);
      return filteredTags;
    } else {
      const filteredTags = this.filteredTags.filter((tg: any) => !tg.type);
      return filteredTags;
    }
  }

  filterTags(searchTerm: string) {
    if (searchTerm) {
      this.filteredTags = this.allTags.filter(tagObj => 
        tagObj.tag.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.filteredTags = [...this.allTags];
    }
  }

  sendToListen(): void {
    // it's the whitelist
    this._listenService.updateTagsToListen(this.selectedTags);

    const queryParams: NavigationExtras = {
      queryParams: { 
        // tagsToListen: this.selectedTags.map(),
        section: 2
      }
    };
    this._router.navigate([], queryParams);
  }

  selectTag(tag: any): void {
    this.selectedTags.push(tag); // !!! push or remove
  }

  removeTag(tag_id: string): void {
    this.selectedTags = this.selectedTags.filter(obj => obj.id !== tag_id);
  }

  removeTagFromSong(tag_id: string): void {
    this._httpClient.delete(this.apiUrl + '/tag/song/' + this.songId + `?tag_id=${tag_id}`).subscribe(data => {
      console.log('updated a song\'s tags (deleted one)', data);
      this.getTags(this.songId);
    });
  }

  // 'PLAYLIST'
  createTag(tag_name: string, tag_type: string = 'GENERAL', tag_color?: string, tag_description?: string): void {
    const payload = {
      tag: tag_name,
      type: (tag_type && tag_type !== '') ? tag_type : 'GENERAL',
      Color: tag_color,
      Description: tag_description
    }

    console.log('the payload for create', payload);

    this._httpClient.post(this.apiUrl + '/tags', payload).subscribe(data => {
      console.log('created a tag', data);
      this.getTags();
      this.getTypes();
      this.clearCurrentTag();
    });
  }

  updateTag(tag_id: string, tag_name?: string, tag_type?: string, tag_color?: string, tag_description?: string): void {
    const payload = {
      tag: tag_name,
      type: (tag_type && tag_type !== '') ? tag_type : 'GENERAL',
      Color: tag_color,
      Description: tag_description
    }

    console.log('the payload for put', payload);

    this._httpClient.put(this.apiUrl + '/tags' + `?tag_id=${tag_id}`, payload).subscribe(data => {
      console.log('updated a tag', data);
      this.getTags();
      this.getTypes();
      this.clearCurrentTag();
    });
  }

  editTag(tagBody: any): any {
    console.log('to edit', tagBody);
    this.newTag = tagBody;
  }

  clearCurrentTag(): void {
    this.newTag = {
      tag: null,
      type: null,
      Color: null,
      Description: null
    }
  }

  deleteTag(id: string): void {
    // alert('sure you want to delete?');
    
    let params = new HttpParams()
      .set('tag_id', id);

    this._httpClient.delete(this.apiUrl + '/tags', { params }).subscribe(data => {
      this.getTags();
      this.getTypes();
    });
  }

}


const oldTags = [
  "03a109e6-29ae-43fd-aeb3-afac39c99f5c",
  "0548a714-23b5-4e1c-b6d8-9605622a5430",
  "06dbd1f5-0cc0-4114-8515-c1f6e380cc2e",
  "09d203bc-4f14-4537-9dba-100a877e2ade",
  "17e8235b-1e5d-447d-a3e3-10c1b097917e",
  "1dd89f5b-b577-4a2b-909a-575300cab174",
  "1efc2129-139e-429c-ac4c-ec6a47e2d356",
  "232cde27-1c67-4447-9432-c49c58dfcadb",
  "242de3ba-ff23-4773-bf4c-2ab9e79d6638",
  "2d6c3ba7-4593-43b3-8d51-8f70e0f2c55c",
  "2e329f67-60a9-4818-97f9-2f43f8273b85",
  "3f422f88-e15e-4a14-ad66-cc41bca68d3f",
  "3fd5a381-a325-464e-a176-ba30ba594e41",
  "41e74d41-9c48-494f-b5b0-708c422c14b7",
  "4608820f-bb6a-4529-a1a3-99e860dad692",
  "4656aace-2988-41a4-88c3-94915c7049f2",
  "4dbbd247-bd51-458f-89f4-28a84ab05389",
  "5516d82a-deb4-403a-95d4-71660b678fca",
  "572a502f-156c-4655-a5ee-762a2ba10cc2",
  "5a8f3f51-3744-4f86-ab7f-056ff15097d4",
  "5f047070-424b-4ce5-9eba-36352bb61bba",
  "5fabc562-d054-4d73-8a7b-69a32b087883",
  "61377d5f-cb0c-44a0-bd08-ffc5a7de7d0a",
  "62273513-feac-4a8a-99b4-952c14ca4647",
  "6de80ac6-8f28-4840-b986-9ca062d203b7",
  "733650cc-97c6-473a-9ba1-a491244940f5",
  "73a2279d-99e3-44e8-b7c2-7bb3be4afe1e",
  "7a6eeacc-786f-443c-8784-24939030971e",
  "83520338-10c0-40da-a036-b3a9f522fc51",
  "891fe2d1-3f6b-4952-95c7-6775bcb672ef",
  "8a63d246-e3fa-4226-8ded-7c4a8b733cbc",
  "935b5d9f-08f8-4156-88fc-46037df4f3b4",
  "95ddd5e7-2c7b-43d8-a498-52c3bd1067bf",
  "9a31c1f4-708a-4e97-bb91-a3006a35d0eb",
  "9b30c586-8027-4337-9cbb-7333cc171c08",
  "a5a2f689-2c95-45fd-a75f-7e612d66fef0",
  "af87af94-a51e-416b-bc93-9882794f1622",
  "b1edc2de-e0a9-4094-9201-53892ce0e051",
  "b3689760-c050-4f8a-a692-21615be01932",
  "b383be24-6ae6-4a20-bae8-4c770e02f58b",
  "b571ca22-bffe-4b1b-874f-21a0bfbfc432",
  "b5750cd1-fbc2-43bf-b4c7-81dbb3aa9e33",
  "c08309c8-a17e-403e-8904-c7dc43797c46",
  "c15394ce-5620-487a-b701-5bfbb74df73e",
  "c8b0cda4-ca31-4e7c-a6a6-b8de98dee8d7",
  "cafa871c-c172-4b5c-8ed0-06ce70d80c0f",
  "d163827a-4c6c-46a0-a8d3-e361f3a704cd",
  "d6e0fd56-314b-456f-8210-def309510b7d",
  "def831b5-c9a7-4db5-85c9-e669cbf1d773",
  "e055464d-8e3b-4a6c-91cb-1d49ec351722",
  "e68a5aef-e782-469b-9f6d-dca66c67fbec",
  "e8e6d60a-b386-4b10-b61a-f18f0f278bee",
  "eadb8184-8ccb-4986-b08d-b3ff14450cbd",
  "f114efc8-5760-495d-b41c-9fb2ce7abca4",
  "f47101f8-5030-4a7b-ba1c-4c668de20186",
  "f89318b7-7844-4448-9e86-65d37bb708f1",
  "f89eca30-34ec-4309-8a60-854ac6301736",
  "f8f87ee2-30cb-46ff-b99e-bdd5678bca32",
  "f98a11c2-341a-4ea1-a644-efe41b3eb1b5",
  "fcddc589-291a-4345-ac36-0cdc31e7a3d5",
  "fe2a03f4-82b1-4d8a-b1c5-6b6121459363"
]