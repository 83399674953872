import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IonContent, MenuController } from '@ionic/angular';
import { Observable, debounceTime, map, of, switchMap } from 'rxjs';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { ProprietariFirebase } from './components/proprietari-firebase/proprietari-firebase.component';
import { environment } from 'src/environments/environment';
import { InterfaceService } from '../deviz/providers/interface.service';

@Component({
  selector: 'app-tab4',
  templateUrl: 'tab4.page.html',
  styleUrls: ['tab4.page.scss']
})
export class Tab4Page {
  proprietari: any[] = [];
  showList: boolean = true;
  apiUrl: string = environment.apiUrl;

  // for search
  selected = null;
  proprietar: any;
  proprietariObservable!: Observable<any>;

  proprietariNameCtrl = new FormControl();
  proprietariFirebaseUIDCtrl = new FormControl();
  proprietariIdCtrl = new FormControl();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  page = new FormGroup({
    page: new FormControl<Number | null>(1),
    per_page: new FormControl<Number | null>(50)
  });

  form: FormGroup = new FormGroup({
    // q: null,
    firebase_uid: this.proprietariFirebaseUIDCtrl,
    proprietar_id: this.proprietariIdCtrl,

    // start_date: this.range.start
    // end_date: this.range.value.end

    // page: this.page.value.page
    // per_page: this.page.value.per_page
  });

  merge_id:  string | null = null;
  mergingProprietar: any = null;
  selectedProprietari: string[] = [];
  mergeOptions: any = {
    devize: true,
    masini: true
  }

  @ViewChild(IonContent) content!: IonContent;

  defaultParams = {
    'q': null,
    'start_date': null,
    'end_date': null,
    'firebase_uid': null,
    'proprietar_id': null,
    'page': '1',
    'per_page': '50'
  }

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private renderer: Renderer2, 
    private el: ElementRef,
    private menuCtrl: MenuController,
    private _interface: InterfaceService
  ) {

  }

  ngOnInit(): void {
    this.getProprietari();

    this._activatedRoute.queryParams.subscribe(params => {
      this.merge_id = params['merge_id_proprietar'];
      if(this.merge_id) {
        this.getProprietar(this.merge_id);
      }
      if(params['prefill']) {
        console.log('general search', params['prefill']);
        this.proprietariNameCtrl.setValue({
          nume_proprietar: params['prefill'],
          proprietar_id: null
        });
        // this.searchProprietariByName(params['prefill']);

        setTimeout(() => {
          this.proprietariNameCtrl.setValue({
            nume_proprietar: params['prefill'],
            proprietar_id: null
          })
          // this.proprietariNameCtrl.setValue(params['prefill']);
          this.searchProprietariByName(params['prefill']);
        }, 100);
      }
    });

    this.proprietariObservable = this.proprietariNameCtrl.valueChanges.pipe(
      debounceTime(300),
      switchMap(term => {
        console.log('ii mai da si aici dupa general search', term);
        this.searchProprietariByName(term).subscribe();
        return of([]);        
        // return this.searchProprietariByName(term);
      })
    );

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('event', event);
        if (this.content && !event.url.includes('secondary')) {
          // todo: implement everywhere && still broken as you need to figure out also if the previous route contained 'secondary'
          this.content.scrollToTop();
        }
        if (event.url.includes('proprietari-item') || event.url.includes('proprietari-create')) {
          this.showList = false;
        } else {
          this.showList = true;
        }
      }
    });
  }

  getProprietari(page: number = 1, per_page: number = 50): void {
    this.selected = null;

    let params = new HttpParams()
      .set('page', page.toString())
      .set('per_page', per_page.toString())

    this._http.get(`${this.apiUrl}/proprietari`, { params }).subscribe(
      (response: any) => {
        this.proprietari = response.data;
      },
      (err) => {
      }
    );
  }

  // for search
  searchProprietariByName(term: any): Observable<any> {
    if(!term) return of([]);

    let search;
    if (typeof term === 'string' || term instanceof String) {
      search = term;
    } else {
      search = term.nume_proprietar;
    }

    let params = new HttpParams()
    .set('q', search)

    return this._http.get<any>(`${this.apiUrl}/proprietari/search`, { params }).pipe(
      map(prp => {
        this.proprietari = prp;
        return prp;
      })
    )
  }

  displayFirst(event: any): void {
    const proprietar = event.option.value;
    
    const index = this.proprietari.findIndex(obj => obj['proprietar_id'] === proprietar.proprietar_id);
  
    // If the object is found and it is not already the first element
    if (index >= 0) {
      // Remove the object from the array
      const [object] = this.proprietari.splice(index, 1);
      // Insert the object at the beginning of the array
      this.proprietari.unshift(object);
      this.selected = object.proprietar_id;
    } else {
      this.proprietari.unshift(proprietar);
      this.selected = proprietar.proprietar_id;
    }
  }

  displayProprietarFn(proprietar: any): string {
    return proprietar && proprietar.nume_proprietar ? proprietar.nume_proprietar : '';
  }

  generalSearch(q?: string): void {
    let httpParams = new HttpParams();
    const params: any = {
      'start_date': this.range.value.start ? this.range.value.start.toISOString().split('T')[0] : null,
      'end_date': this.range.value.end ? this.range.value.end.toISOString().split('T')[0] : null,
      'firebase_uid': this.proprietariFirebaseUIDCtrl.value,
      'proprietar_id': this.proprietariIdCtrl.value,
      'page': '1',
      'per_page': '50'
    }


    Object.keys(params).forEach(key => {
      if(params[key])
        httpParams = httpParams.set(key, params[key]);
    });

    this._http.get<any>(this.apiUrl + '/proprietari', { params: httpParams }).subscribe(data => {
      this.proprietari = data.data;
    });
  }

  clearParams(): void {
    // need to clear form controls too
    this.form.setValue({
      'firebase_uid': null,
      'proprietar_id': null,
    });

    this.range.setValue({
      'start': null, // new Date(),
      'end': null // new Date(),
    });

    this.proprietariNameCtrl.setValue(null);

    this.getProprietari();

    this.selected = null;

    this.defaultParams = {
      'q': null,
      'start_date': null,
      'end_date': null,
      'firebase_uid': null,
      'proprietar_id': null,
      'page': '1',
      'per_page': '50'
    };
  }


  // for the modal
  async selectProprietar(proprietar_id: string): Promise<void> {
    if(this.merge_id) return;
    if (this._interface.isSecondaryOutletActive()) {
      this._router.navigate([{outlets: { secondary: null}}], {
        relativeTo: this._activatedRoute,
        queryParams: { proprietar_id: proprietar_id },
        queryParamsHandling: 'merge'
      });
      await this.menuCtrl.close('second-menu');
    } else {
      this._router.navigate(['/tabs/tab4/proprietari-item/' + proprietar_id]);
    }
  }

  // merge feature

  getProprietar(proprietarId: any): void {
    this.form.disable();

    this._http.get(`${this.apiUrl}/proprietari/${proprietarId}`).subscribe((proprietar: any) => {
      this.mergingProprietar = proprietar;
    });
  }

  merge(): void {

    if (confirm('Esti sigur ca doresti ca toate datele proprietarilor selectati sa fie transferate catre ' + this.mergingProprietar.nume_proprietar)) {
    
      let params = new HttpParams();
      if (this.mergeOptions.proprietari !== undefined) {
        params = params.set('masini', this.mergeOptions.masini.toString());
      }
      if (this.mergeOptions.devize !== undefined) {
        params = params.set('devize', this.mergeOptions.devize.toString());
      }
  
      const body = {
        proprietar_id: this.merge_id,
        proprietar_ids: this.selectedProprietari
      };
  
      this._http.post<any>(this.apiUrl + '/merge_proprietari', body, { params }).subscribe(data => {
        // do toast
        this._interface.presentToast('Datele proprietarilor selectati au fost transferate.');
        this.merge_id = null;
        
        this._interface.clearMenu();
        setTimeout(() => {
          this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: { uuids: this.selectedProprietari, proprietari_names: this.proprietari.filter(p => this.selectedProprietari.includes(p.proprietar_id)).map(p => p.nume_proprietar)},
            queryParamsHandling: 'merge' // preserves the existing query params
          });
          this.selectedProprietari = [];
        }, 1000);
      })
    }
    return
  }

  addProprietarForMerge(proprietar_id: string): void {
    const index = this.selectedProprietari.indexOf(proprietar_id);
    if (index !== -1) {
      // If the string exists, remove it
      this.selectedProprietari.splice(index, 1);
    } else {
      // Add the string to the array
      this.selectedProprietari.push(proprietar_id);
    }
  }

  checkOwner(proprietar_id: string): void {
    console.log('check owner', proprietar_id);
    if(this.merge_id) {
      const url = this._router.serializeUrl(this._router.createUrlTree(['/tabs/tab4/proprietari-item/' + proprietar_id]));
      window.open(url, '_blank');
      return;
    }
  }
}
