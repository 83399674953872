
    <form [formGroup]="searchForm" class="w-full flex flex-col gap-4" [ngClass]="{'p-5': isModal}" (ngSubmit)="search(); closeDialog(searchForm.value);">
  
      <div class="col-span-4 flex flex-col lg:flex-row justify-center items-center">
        <div class="grid grid-cols-1 lg:grid-cols-2 w-full gap-2 main-filters" [ngClass]="{'lg:w-10/12':  !isModal, 'lg:w-full':  isModal, }">
          <mat-form-field appearance="fill" class="_col-span-4" [ngClass]="{
            '_hidden _md:block': !this.isModal,
            'block': this.isModal
          }">
            <mat-label>Cauta... denumire piesa/cod oe piesa/denumire producator/cod oe producator</mat-label>
            <input matInput placeholder="Search for piesa/cod/producator/etc." formControlName="search_query">
          </mat-form-field>
          <mat-form-field appearance="fill" class="_col-span-4 w-full">
            <mat-label>Serie sasiu</mat-label>
            <input matInput placeholder="Search for serie sasiu" formControlName="serie_sasiu">
          </mat-form-field>
        </div>
        <div class="flex flex-row justify-center lg:justify-end items-center w-full lg:w-2/12 pt-3 lg:pt-0 hide-controls" *ngIf="!isModal">
          <button class="block" type="submit"><ion-icon name="search-outline" class="text-2xl pl-3"></ion-icon></button>
          <button class="block" type="button" (click)="clearFilters()"><ion-icon name="close-outline" class="text-2xl pl-3"></ion-icon></button>
          <button class="block" type="button" (click)="openFilters()"><ion-icon name="filter-circle-outline" class="text-2xl pl-3"></ion-icon></button>
        </div>
        <button mat-stroked-button type="button" (click)="addPiesa()" class="w-5/12 ml-2 h-14 hidden show-controls">
          <div class="w-full flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" class="text-2xl mr-2"></ion-icon>
            <span class="block">Creeaza</span>
          </div>
        </button>
      </div>
      <div class="grid gap-3" [ngClass]="{
        'grid-cols-2': this.isModal,        
        'grid-cols-2 lg:grid-cols-4': !this.isModal
      }">
        <!-- be369b12-e634-4114-a548-ae949b6afce4 -->


        <!-- <div class="flex flex-col"> -->
          <!-- <button mat-raised-button color="primary" type="submit" class="ml-4">Search</button>
          <button mat-stroked-button color="primary" type="button" class="ml-8" (click)="clearFilters()">Clear</button> -->
        <!-- </div> -->
            
        <!-- show only in admin mode -->
        <!-- <mat-form-field appearance="fill" class="col-span-2" [ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Mașină ID</mat-label>
          <input matInput placeholder="FK Mașină ID" formControlName="fk_masina_id">
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-span-2" [ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Lucrare ID</mat-label>
          <input matInput placeholder="FK Mașină ID" formControlName="lucrare_id">
        </mat-form-field> -->
    
        <mat-form-field appearance="fill" class="flex-grow hide-controls" [ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Denumire Piesă</mat-label>
          <input matInput placeholder="Denumire Piesă" formControlName="denumire_piesa">
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="flex-grow hide-controls"[ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Cod OE Piesă</mat-label>
          <input matInput placeholder="Cod OE Piesă" formControlName="cod_oe_piesa">
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="flex-grow  hide-controls" [ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Denumire Producător</mat-label>
          <input matInput placeholder="Denumire Producător" formControlName="denumire_producator">
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="flex-grow hide-controls" [ngClass]="{
          'hidden _md:block': !this.isModal,
          'block': this.isModal
        }">
          <mat-label>Cod Producător</mat-label>
          <input matInput placeholder="Cod Producător" formControlName="cod_producator">
        </mat-form-field>
      </div>
  
      
      <!-- not needed at this point -->
      <!-- <div class="flex gap-2 items-center w-full" [ngClass]="{
        'hidden md:block': !this.isModal,
        'block': this.isModal
      }">
        <mat-form-field appearance="fill" class="flex-grow w-6/12 pr-2">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="start_date">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="flex-grow w-6/12 pl-2">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="end_date">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div> -->

      <div class="flex flex-row w-full justify-center items-center">
        <button class="_mt-3 mr-4" type="button" mat-button (click)="onNoClick()" *ngIf="isModal">Cancel</button>
        <button class="_mt-3 hide-controls" type="submit" mat-flat-button [mat-dialog-close]="searchForm.value" cdkFocusInitial *ngIf="isModal">Cauta</button>
        <button class="_mt-3 hidden show-controls" type="button" mat-flat-button cdkFocusInitial (click)="search()" *ngIf="isModal">Cauta</button>
      </div>
    
    </form>


  <!-- <mat-card *ngFor="let item of masini" (click)="selectItem(item)">
    <mat-card-title>{{ item.serie_sasiu }}</mat-card-title>
    <mat-card-content>
      <p>tip motor: {{ item.tip_motor_masina }}</p>
      <p>id: {{ item.masina_id }}</p>
    </mat-card-content>
  </mat-card> -->

