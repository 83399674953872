<ion-app class="print:hidden">
  <!-- <ion-menu menuId="first-menu" contentId="main-content" side="start"  id="first-menu">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu Content</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

    <ion-button (click)="doLink()">
      <ion-icon aria-hidden="true" name="triangle"></ion-icon>
      <ion-label>Tab 1</ion-label>
    </ion-button>

    </ion-content>
  </ion-menu> -->

  <ion-menu class="print:hidden" menuId="second-menu" contentId="main-content" side="end" id="second-menu" (ionDidClose)="clearRouter($event)">
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>Create new</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-content class="_ion-padding safe-area">
      <!-- Content for the secondary menu goes here -->
      <router-outlet name="secondary"></router-outlet>
    </ion-content>
  </ion-menu>

  <div class="ion-page" id="main-content">

    <ion-header class="print:hidden">
      <ion-toolbar mode="md">

        <a class="text-material mt-1 mx-2 cursor-pointer" slot="start" (click)="back()" *ngIf="section !== 'Pagina principala'">
          <ion-icon name="arrow-back-circle-outline" class="text-2xl"></ion-icon>
        </a>

        <ion-title text-left>{{ section }}</ion-title>

        <ion-button slot="end" color="tertiary" fill="outline" class="mr-2 create-button" (click)="intrare()" *ngIf="section !== 'Home' && state?.isLoggedIn">
          Intrari
        </ion-button>
        
        <ion-button slot="end" color="tertiary" fill="outline" class="_mr-4 create-button" (click)="create()" *ngIf="section !== 'Home' && state?.isLoggedIn && section !== 'Pagina principala'">
          Creeaza {{ section === 'Intrari' ? 'Devize' : section }}
        </ion-button>

        <ion-button slot="end" color="dark" fill="clear" class="mr-4 menu-button" *ngIf="state.isLoggedIn"  (click)="openSecondaryMenu()">
        <!-- [routerLink]="['/tabs/home']" -->
          <span class="overflow-hidden whitespace-nowrap text-ellipsis w-36 text-xs md:text-base">{{ state.data?.admin_name }}</span> <!--.split(' ')[0]-->
          <ion-icon name="menu-outline" class="text-5xl mx-1"></ion-icon>
 
          <!-- <ion-icon name="log-in"></ion-icon> -->
        </ion-button>

      </ion-toolbar>

    </ion-header>

    <ion-content>
      <div #scrollTarget></div>
      <router-outlet class="print:hidden"></router-outlet>
      
    </ion-content>
  <!-- research at some point, might have had problems in the past because there was no consistency between <ion-router si <router -->

    
  </div>
  
  
  
  
</ion-app>
<!-- sau !!!! printeaza pe rand -->
<!-- <div id="deviz-hack"> -->
  <deviz-print [isPreview]="false" [deviz]="deviz" *ngFor="let deviz of devizPrintArray; index as i" id="deviz-hack-{{i}}"></deviz-print>
<!-- </div> -->

<!-- <div > -->
<!-- </div> -->

