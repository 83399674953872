<div class="page">
  <div class="pageContent">

    <div role="alert" class="flex flex-row flex-wrap rounded border-s-4 _border-red-500 _bg-red-50 border-green-600 bg-green-100 bg-opacity-10 p-4 mb-4 w-full" *ngIf="mergedCars.length">
      <div class="flex flex-col w-full lg:w-6/12 lg: pr-6">
        <div class="flex items-center gap-2 _text-red-800 text-green-200">
          <ion-icon name="checkmark-circle-outline" class="text-xl"></ion-icon>
          <strong class="block font-medium"> Succes! </strong>
        </div>
      
        <p class="mt-2 text-sm _text-red-700 text-green-500">
          Ai unit aceasta masina cu masinile urmatoare. Le puteti deschide intr-o fereastra noua pentru a le elimina din baza de date
        </p>
      </div>

      <div class="_scrollable-list overflow-x-auto flex flex-row justify-center items-center w-full lg:w-6/12">
        <div
        *ngFor="let uuid of mergedCars; let i = index;"
        class="min-w-36 mx-2 bg-transparent border shadow-md rounded-lg px-2 hover:shadow-lg transition-shadow duration-300"
        >
          <a
            [href]="'/tabs/tab3/masini-item/' + uuid"
            target="_blank"
            class="block text-white font-semibold text-lg text-center"
          >
            {{ mergedLicenceNumbers[i] }}
          </a>
        </div>
      </div>
    </div>


    <div class="flex flex-row w-full flex-wrap">
      <div class="flex flex-col w-full md:w-7/12">
        <span class="text-lg lg:text-3xl font-bold" *ngIf="true || !formEnabled">{{ defaultItem.nr_masina }}</span>
        <span class="text-base lg:text-xl opacity-90" *ngIf="true || !formEnabled">{{ defaultItem.serie_sasiu }}</span>
      </div>
  
      <div class="flex flex-row lg:flex-row mt-2 md:mt-0 w-full md:w-5/12 justify-between md:justify-end items-center gap-2">
        <!-- <div class="flex flex-row gap-2 w-full">
        </div> -->

        <button mat-stroked-button color="warn" type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && !formEnabled" (click)="deleteMasina()">Elimina</button>

        <button mat-stroked-button type="button" (click)="startMerge()" *ngIf="defaultItem.masina_id && !formEnabled">
          <div class="w-full flex flex-row justify-center items-center">
            <ion-icon name="share-social-outline" class="text-2xl mr-2"></ion-icon>
            <span class="block ml-2 whitespace-nowrap">Uneste masina</span>
          </div>
        </button>

        <button mat-stroked-button type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && defaultItem?.masina_id" (click)="edit()">
          <span *ngIf="!formEnabled">Editeaza</span>
          <span *ngIf="formEnabled">Cancel</span>
        </button>

        <button mat-raised-button color="accent" type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && formEnabled && defaultItem.masina_id" (click)="updateMasina()">Salveaza schimbari</button>
        <button mat-raised-button color="accent" type="button" (click)="saveMasina()" *ngIf="!defaultItem.masina_id">Salveaza</button>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="saveMasina();" class="flex flex-row flex-wrap gap-2 w-full mt-6">
      <div class="grid grid-cols-3 md:grid-cols-6 w-full gap-2" *ngIf="formEnabled">
        <div class="col-span-3">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Serie sasiu</mat-label>
            <input matInput type="text" [formControl]="serie_sasiu_ctrl">
            @if (serie_sasiu_ctrl.hasError('required')) {
              <mat-error>Seria de sasiu este obligatorie</mat-error>
            }
            @if (serie_sasiu_ctrl.hasError('pattern')) {
              <mat-error>Seria de sasiu trebuie sa aibe 17 caractere</mat-error>
            }
  
            <!-- <mat-error *ngIf="form.get('serie_sasiu')?.hasError('required') && form.get('serie_sasiu')?.touched">
              Seria de sasiu este obligatorie
            </mat-error>
            <mat-error *ngIf="form.get('serie_sasiu')?.hasError('pattern') && form.get('serie_sasiu')?.touched">
              Seria de sasiu nu este valida
            </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-span-3">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Numar masina</mat-label>
            <input matInput type="text" formControlName="nr_masina">
            @if (nr_masina_ctrl.hasError('required')) {
              <mat-error>Tipul motorului este obligatoriu</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      
      <!-- Row for Tip Motor and Capacitate Cilindrica -->
      <div class="flex flex-row w-full gap-2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Tip motor</mat-label>
          <input matInput type="text" formControlName="tip_motor_masina">
          @if (tip_motor_masina_ctrl.hasError('required')) {
            <mat-error>Tipul motorului este obligatoriu</mat-error>
          }
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Capacitate cilindrica</mat-label>
          <input matInput type="text" formControlName="cap_cil_masina">
        </mat-form-field>
      </div>
      
      <!-- Row for KW and An Fabricatie -->
      <div class="flex flex-row w-full gap-2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>KW</mat-label>
          <input matInput type="text" formControlName="kw_masina">
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>An fabricatie</mat-label>
          <input matInput type="text" formControlName="an_fab_masina">
        </mat-form-field>
      </div>
      
      <!-- Row for Model and Marca -->
      <div class="flex flex-row w-full gap-2">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Marca</mat-label>
          <input matInput type="text" formControlName="marca_masina">
          @if (marca_masina_ctrl.hasError('required')) {
            <mat-error>Marca este obligatorie</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Model</mat-label>
          <input matInput type="text" formControlName="model_masina">
          @if (model_masina_ctrl.hasError('required')) {
            <mat-error>Modelul este obligatoriu</mat-error>
          }
        </mat-form-field>
      </div>
    </form>

    <div class="flex flex-row flex-wrap justify-start items-start w-full mt-8 mb-3 bg-slate-600 bg-opacity-20 p-2 rounded" [ngClass]="{'h-[455px] _lg:h-[246px] lg:h-64': !formEnabled, 'h-[590px] lg:h-72': formEnabled}" *ngIf="(viewState !== 'SECONDARY_ROUTE')">
      <!-- LEFT PART OF DETAILS -->
      <div class="h-52 w-full lg:w-6/12 lg:pr-4">
        <quill-editor class="h-56 w-full" *ngIf="formEnabled" [ngModelOptions]="{standalone: true}" [disabled]="false" [(ngModel)]="richTextContent" placeholder="Detaliii proprietar" [modules]="quillOptions"></quill-editor>
        
        <div *ngIf="!formEnabled" class="h-52 w-full border-slate-900 p-1">
          <h3 class="font-bold text-xl mb-2">Note:</h3>
          <div class="quill-html w-full prose text-white" *ngIf="richTextContent" [innerHTML]="richTextContent"></div>
          <span *ngIf="!richTextContent">Nu existe note aditionale</span>
        </div>
      </div>
      <!-- RIGHT PART OF DETAILS -->
      <div class="_h-36 w-full lg:w-6/12 flex flex-col">
        <div class="flex flex-row mt-[87px] lg:mt-0 mb-2" *ngIf="formEnabled && !currentOwner">
          <button mat-stroked-button class="w-6/12 mr-2" (click)="selectProprietar()">Adauga din lista</button>
          <button mat-stroked-button class="w-6/12" (click)="createProprietar()">Creeaza un nou proprietar</button>
        </div>
        <div class="flex flex-col mt-[87px] lg:mt-0 mb-2 text-center" *ngIf="formEnabled && currentOwner">
          <span class="mb-3 text-lg block"><strong>{{ currentOwner.nume_proprietar }}</strong></span>
          <button mat-flat-button color="accent" class="mb-1 ml-4 block" *ngIf="defaultItem.masina_id"
          (click)="proprietar_id_ctrl.setValue(currentOwner.proprietar_id); this.updateMasina()"
          >Salveaza noul proprietar</button>
        </div>

        <h3 class="font-bold text-xl mb-2" *ngIf="!formEnabled">Lista proprietari</h3>

        <div class="scrollable-list" [ngClass]="{'mt-2 lg:mt-0': formEnabled}" *ngIf="proprietari.length">
          <mat-accordion>
            <!-- ; trackBy: trackByIndex [(expanded)]="expanded_array[i]"-->
            <mat-expansion-panel *ngFor="let proprietar of proprietari; let i = index" #expansionPanel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <!-- {{ expansionPanel.expanded ? 'Editing' : numar }} -->
                  {{ proprietar.nume_proprietar }}
                </mat-panel-title>
              </mat-expansion-panel-header>
        
              <div class="input-container">
                <!-- <mat-form-field class="w-full" appearance="fill" *ngIf="(defaultItem.numere_array[i] !== null || defaultItem.numere_array[i] !== undefined) && formEnabled">
                  <input matInput type="text" [(ngModel)]="defaultItem.numere_array[i]" [ngModelOptions]="{standalone: true}" (keypress)="$event.stopPropagation()">
                </mat-form-field> -->
                <div *ngIf="true">
                  <!-- <button mat-stroked-button (click)="'somefnonaproprietar like delete go to etc'">
                    <div class="flex flex-row justify-center items-center">
                      <ion-icon name="copy-outline" class="mr-2"></ion-icon>
                      <span>Copy</span>
                    </div>
                  </button> -->

                  <button type="button" mat-stroked-button color="warn" class="mr-2" (click)="removeOwner(proprietar)">Elimina proprietarul</button>
                  <button type="button" mat-stroked-button class="_mb-5" (click)="checkOwner(proprietar)">Verifica proprietarul</button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <span class="text-center mt-2" *ngIf="!proprietari?.length">Nu exista proprietari</span>
      </div>
    </div>


    <!-- <div class="flex flex-row w-full gap-2" [ngClass]="{'grid grid-cols-2': viewState === 'SECONDARY_ROUTE'}">
      <div class="flex flex-row flex-wrap w-full justify-between items-center" *ngIf="viewState !== 'SECONDARY_ROUTE'">
        <mat-form-field class="w-1/2 pr-1">
          <mat-label>Lista Proprietari</mat-label>
          <mat-select>
            <mat-option [value]="null" (onSelectionChange)="selectProprietar()">Adauga proprietar din lista + </mat-option>
            <mat-option [value]="null" (onSelectionChange)="createProprietar()">Creeaza un nou proprietar + </mat-option>
            @for (proprietar of proprietari; track proprietar) {
              <mat-option [value]="proprietar" (onSelectionChange)="selectionChange($event)">{{proprietar.nume_proprietar}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="w-1/2 flex flex-row items-center justify-end">

          <div *ngIf="currentOwner" class="_w-full _py-3">
            <div *ngIf="isOwner()" class="flex flex-row gap-2 justify-center items-center">
              <button type="button" mat-stroked-button color="warn" class="_mb-5" (click)="removeOwner(currentOwner)">Elimina proprietarul</button>
              <button type="button" mat-stroked-button class="_mb-5" (click)="checkOwner(currentOwner)">Verifica proprietarul</button>
            </div>
            <div *ngIf="!isOwner()" class="mb-5 flex flex-row justify-between items-center">
              <span class="mr-3 text-lg block">Proprietar: <strong>{{ currentOwner.nume_proprietar }}</strong></span>

              <button mat-flat-button color="success" class="mb-1 ml-4 block" *ngIf="defaultItem.masina_id"
                (click)="proprietar_id_ctrl.setValue(currentOwner.proprietar_id); this.updateMasina()"
              >Salveaza noul proprietar</button>
            </div>
          </div>

        </div>

      </div>
      
    </div> -->
    

    <!-- <mat-divider class="my-4 mt-16 w-full" *ngIf="(viewState !== 'SECONDARY_ROUTE') && defaultItem.masina_id"></mat-divider> -->

    <mat-tab-group class="_col-span-2 _tabs-height w-full mt-14" *ngIf="(viewState !== 'SECONDARY_ROUTE') && defaultItem.masina_id">
      <mat-tab [label]="'Devize'">

        <button mat-stroked-button (click)="createDeviz()" class="w-full mb-2 mt-4 h-14">
          <div class="w-full flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" class="text-2xl mr-2"></ion-icon>
            <span class="block">Creeaza deviz</span>
          </div>
        </button>

        <deviz-list [standalone]="true" [filters]="{
          masina_id: defaultItem.masina_id
        }" *ngIf="defaultItem.masina_id" class="w-full"></deviz-list>
      </mat-tab>
      <mat-tab [label]="'Piese'">
        <button mat-stroked-button (click)="addPiesa()" class="w-full mb-2 mt-4 h-14">
          <div class="w-full flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" class="text-2xl mr-2"></ion-icon>
            <span class="block">Creeaza</span>
          </div>
        </button>
        <piese-list [filters]="{
          fk_masina_id: defaultItem.masina_id
        }" *ngIf="defaultItem.masina_id" class="w-full"></piese-list>
      </mat-tab>
      <!-- <mat-tab [label]="'Proprietari'">

      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>