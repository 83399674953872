<div class="page" [ngStyle]="{'min-height': ((viewState === 'DIALOG') ? '700px' : 'unset')}">
  <div class="pageContent">
    <form [formGroup]="form" class="mt-2 grid gap-4 w-full" [ngClass]="{' lg:grid-cols-1': (viewState === 'PRIMARY_ROUTE'), 'grid-cols-1': (viewState !== 'PRIMARY_ROUTE')}">
        <!-- GENERAL -->
        <mat-form-field class="_h-24">
          <mat-label>Denumire manopera:</mat-label>
          <input matInput type="text" formControlName="operation_name">
        </mat-form-field>
      
        <!-- For norma_de_timp - Number -->
        <mat-form-field class="relative">
          <mat-label>Norma de timp ( 1 = 120 RON):</mat-label>
          <input matInput type="number" formControlName="norma_de_timp" placeholder="1.2">
          <ion-icon name="information-circle" class="text-xl absolute right-0 top-4 opacity-50 cursor-pointer pointer-events-auto" [matTooltip]="'Introduceți durata operațiunii în ore (de exemplu, 0.3 ore pentru 36 RON). Tariful orar este de 120 RON. Norma de timp se poate modifica din pagina principala'" [matTooltipPosition]="'above'"></ion-icon>
        </mat-form-field>
  
      
        <!-- For pret_unitar_fara_tva_lei - Number -->
        <mat-form-field>
          <mat-label>Pret unitar fara TVA (RON):</mat-label>
          <input matInput type="number" formControlName="pret_unitar_fara_tva_lei" [disabled]="true">
        </mat-form-field>
    
        <!-- For adding operation in the database-->
        <div class="flex flex-row _col-span-2">
          <button mat-raised-button color="accent" type="button" (click)="saveOperation()" *ngIf="!defaultItem.operation_id" class="mr-4">Salveaza</button>
          <button mat-stroked-button type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && !formEnabled && defaultItem.operation_id"(click)="edit()" class="mr-4">Editeaza</button>
          <button mat-raised-button color="accent" type="button" *ngIf="(viewState === 'PRIMARY_ROUTE') && formEnabled && defaultItem.operation_id"(click)="updateOperation()" class="mr-4">Salveaza schimbari</button>
          <button mat-stroked-button type="button" color="warn" *ngIf="(viewState === 'PRIMARY_ROUTE') && defaultItem.operation_id"(click)="deleteOperation()" class="mr-4">Elimina</button>
        </div>
    </form>
  </div>
</div>
  