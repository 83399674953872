import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { devizStore } from './store';

import { Deviz, Nomenclator, Lucrare, Proprietar, Masina } from './models';
import { updateEntities, addEntities, deleteEntities, withEntities } from '@ngneat/elf-entities';
import { createStore, withProps, setProp } from '@ngneat/elf';

@Injectable({
  providedIn: 'root'
})
// used for print and reloading data to the bottom tab
export class SharedService {
  // ELF

  // UTILS
  private dataSubject = new BehaviorSubject<any>({});  
  private reloadSubject = new BehaviorSubject<any>(true);

  constructor(
    
  ) {

  }

  // ELF - attempt
  // updateDevizProperty<T extends keyof Deviz>(prop: T, value: Deviz[T]) {
  //   devizStore.update((state) => {
  //     return {
  //       ...state,
  //       [prop]: value,
  //     };
  //   });
  // }

  // addOperatiuneManopera(operation: Nomenclator) {
  //   devizStore.update(addEntities(operation, { ref: devizStore.state.operatiuni_manopera }));
  // }

  // updateOperatiuneManopera(id: string, changes: Partial<Nomenclator>) {
  //   devizStore.update(updateEntities(id, changes, { ref: 'operatiuni_manopera' }));
  // }

  // addOperatiuneMateriale(work: Lucrare) {
  //   devizStore.update(addEntities(work, { name: 'operatiuni_materiale' }));
  // }

  // updateOperatiuneMateriale(id: string, changes: Partial<Lucrare>) {
  //   devizStore.update(updateEntities(id, changes, { name: 'operatiuni_materiale' }));
  // }


  // UTILS
  getDataObservable() {
    return this.dataSubject.asObservable();
  }
  
  getReloadObservable() {
    return this.reloadSubject.asObservable();
  }

  updateData(data: any) {
    this.dataSubject.next(data);
  }

  reload() {
    this.reloadSubject.next(true);
  }
}