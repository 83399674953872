import { Component, WritableSignal, signal, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IonContent, MenuController } from '@ionic/angular';
import { AuthService } from './home/auth.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Location } from '@angular/common';
import { SharedService } from './deviz/providers/shared.service';
import * as _ from 'lodash';
import { InterfaceService } from './deviz/providers/interface.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  counts: WritableSignal<number> = signal(0);
  currentUrl: string = '';
  section: string = '';
  state: any = {
    isLoggedIn: false,
    data: null
  }
  @ViewChild(IonContent) content!: IonContent
  @ViewChild('scrollTarget', { static: false }) scrollTarget!: ElementRef;
  isInitial: boolean = true;

  devizPrintArray: any[] = [];
  isBig: boolean = false;

  constructor(
    private _router: Router,
    private menuCtrl: MenuController,
    private _authService: AuthService,
    private zone: NgZone,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private _interface: InterfaceService
  ) {

    this.sharedService.getDataObservable().subscribe(deviz => {
      // this.deviz = data;
      // this.defaultDeviz = this.deviz;
      this.devizPrintArray = [];
      let sumItems = 0;
      if(deviz?.operatiuni_manopera && deviz?.operatiuni_materiale) {
        sumItems = ((deviz.operatiuni_manopera.length || 0) + (deviz.operatiuni_materiale.length || 0));
        this.isBig = sumItems > 30;
      }

      // making devizPrintArray
      if(this.isBig) {

        // now split in 2
        const deviz_one = _.cloneDeep(deviz);
        const numberOfElementsToRemove = sumItems - 30;
        const remainder = deviz_one.operatiuni_materiale.splice(-numberOfElementsToRemove);

        const deviz_two = _.cloneDeep(deviz);
        deviz_two.operatiuni_manopera = [];
        deviz_two.operatiuni_materiale = remainder;

        this.devizPrintArray.push(deviz_one);
        this.devizPrintArray.push(deviz_two);

      } else {
        this.devizPrintArray = [deviz];
      }


      // todo, any devizPrintArray item needs to be the height of the page
    })

    const routerSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.content) {
          // this.content.scrollToTop(); // no need, used in tabs page
        }
        
        if(this.isInitial) {
          this.isInitial = false;
          this._router.navigate([{ outlets: { secondary: null } }], { relativeTo: this._activatedRoute });

          // this._router.navigate([event.urlAfterRedirects.split('(')[0], {outlets: { secondary: null}}], { skipLocationChange: false, relativeTo: this._activatedRoute, });
        }
        
        this.currentUrl = event.url;

        if (this.currentUrl.includes('intrare')) {
          this.section = 'Intrari';
        } else if (this.currentUrl.includes('tabs/tab1')) {
          this.section = 'Devize';
        } else if (this.currentUrl.includes('tabs/tab2')) {
          this.section = 'Nomenclator';
        } else if (this.currentUrl.includes('tabs/tab3')) {
          this.section = 'Masini';
        } else if (this.currentUrl.includes('tabs/tab4')) {
          this.section = 'Proprietari';
        } else if (this.currentUrl.includes('tabs/tab5')) {
          this.section = 'Piese';
        } else if (this.currentUrl.includes('home')) {
          this.section = 'Pagina principala';
        } else if (!this.currentUrl.includes('tabs')) {

          // recheck - currentUrl happens to not be set sometimes on mobile ios
          const recheckUrl = location.pathname;
          if (recheckUrl.includes('intrare')) {
            this.section = 'Intrari';
          } else if (recheckUrl.includes('tabs/tab1')) {
            this.section = 'Devize';
          } else if (recheckUrl.includes('tabs/tab2')) {
            this.section = 'Nomenclator';
          } else if (recheckUrl.includes('tabs/tab3')) {
            this.section = 'Masini';
          } else if (recheckUrl.includes('tabs/tab4')) {
            this.section = 'Proprietari';
          } else if (recheckUrl.includes('tabs/tab5')) {
            this.section = 'Piese';
          } else if (recheckUrl.includes('home')) {
            this.section = 'Home';

          //default
          } else {
            this.section = 'Devize';
          }

        }
      }
    });

    this._authService.subscribeIsLoggedIn().subscribe(state => {
      this.state = state;
    });

    this.initializeApp();
  }


  ngAfterViewInit(): void {
    this._interface.setIonContent(this.content);
    this._interface.setScrollTarget(this.scrollTarget);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split(".app").pop();
            if (slug) {
                this._router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    });
}

back(): void {
  if (window.history.length > 1 && !location.pathname.includes('home')) {
    this._location.back();
  } else {
    this._router.navigateByUrl('/tabs/home');
  }
}

  logout(): void {
    this._authService.logout().then();
  }

  doLink(): void {
    this._router.navigate(['tabs', 'tab2']);
  }

  async openPrimaryMenu(): Promise<void> {
    // await this.menuCtrl.close(); // Close the primary menu
    // await this.menuCtrl.enable(true, 'first-menu'); // Ensure the secondary menu is enabled
    await this.menuCtrl.open('first-menu'); // Open the secondary menu
  }

  async openSecondaryMenu(): Promise<void> {
    this._router.navigate([{outlets: { secondary: 'menu'}}], { skipLocationChange: true });
    await this.menuCtrl.open('second-menu'); // Open the secondary menu
  }

  clearRouter($event: any): void {
    this._router.navigate([{outlets: { secondary: null}}], { skipLocationChange: false });
  }

  intrare(): void {
    this._router.navigate(['/tabs/tab1/deviz-intrare-create']);
  }

  create(): void {
    const recheckUrl = location.pathname;
    // alert('curr:' + this.currentUrl + ':' + recheckUrl + ':' + this.section);
    if (this.section === 'Devize' || this.section === 'Intrari') {
      this._router.navigate(['/tabs/tab1/deviz-create']);
    } else if (this.section === 'Nomenclator') {
      this._router.navigate(['/tabs/tab2/nomenclator-create']);
    } else if (this.section === 'Masini') {
      this._router.navigate(['/tabs/tab3/masini-create']);
    } else if (this.section === 'Proprietari') {
      this._router.navigate(['/tabs/tab4/proprietari-create']);
    } else if (this.section === 'Piese') {
      this._router.navigate(['/tabs/tab5/piese-create']);
    }
  }
}
