import { Component, Inject, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, debounceTime, filter, map, startWith, switchMap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AuthService } from 'src/app/home/auth.service';
import { ModalController } from '@ionic/angular';

// fac side scrolling sau buton cu 'deschide nesalvate'
@Component({
  selector: 'confirm-create-dialog',
  templateUrl: 'confirm-create-dialog.component.html',
//   styleUrls: ['deviz-widget.component.scss']
})
export class ConfirmCreateDialog {
    apiUrl: string = environment.apiUrl;
    currentUser: string = '';

    constructor(
        private _router: Router,
        private _http: HttpClient,
        private modalCtrl: ModalController,
        private _authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this._authService.$loggedIn.subscribe(state => {
            this.currentUser = state?.data?.uid;
        });
    }

    create() {

        this.dismissModal();
        // http
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        this.data.template = null;
        this.data.subtotal_manopera = null;
        this.data.subtotal_materiale = null;
        this.data.total_general = null;
        this.data.total_manopera = null;
        this.data.total_materiale = null;
        this.data.total_materiale_plus_total_manopera = null;

        this.data.total_tva = null;
        this.data.total_tva_manopera = null;
        this.data.total_tva_materiale = null;

        this.data.KM = null;
        this.data.executant = null;
        this.data.deviz_id = null;
        this.data.numar_deviz = null;
        this.data.date_created = null;
        this.data.editing = this.currentUser;
        // this.data.closed = false;
        this.data.status = 'DESCHIS';
        
        delete this.data.car_details;
        delete this.data.proprietar_id;
        delete this.data.merchant_id;
        
        // this.data.nume_proprietar = null;
        // this.data.adresa_proprietar = null;
        // this.data.nr_masina = null;

        this._http.post(`${this.apiUrl}/deviz`, this.data, { headers: headers }).subscribe((response: any) => {
            this._router.navigate(['tabs', 'tab1', 'deviz-item', response.deviz_id])
          }, error => {
            console.error('There was an error:', error);
        });
    }

    go(): void {
        this.dismissModal();
        this._router.navigate(['tabs', 'tab1', 'deviz-item', this.data.deviz_id])
    }


    dismissModal(): void {
        this.modalCtrl.dismiss();
    }
}