<div class="flex flex-col w-full">
    <!-- <mat-card *ngFor="let item of devize" (click)=selectItem(item) class="md:odd:mr-4 mb-4"> -->

      <!-- <mat-card-header>
        <mat-card-title><strong>{{ item.nr_masina }}</strong> - {{ item.nume_proprietar }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="flex flex-row justify-between my-3">
          <p class="block">Executant: {{ item.executant }}</p>
          <p class="block">Total: {{ item.total_general | number }} RON</p>
        </div>

      </mat-card-content>              
      <mat-card-footer>
      </mat-card-footer> -->

      <mat-card class="flex mb-5 p-2 max-card-height-sablon mt-6" [ngClass]="{'flex-col p-3 full-height-sablon': true || !justTemplates}"> <!--, 'flex-row': justTemplates-->
        <!-- <span class="text-xl font-bold">Sabloane</span> -->
        <div class="flex flex-row justify-center items-center">
          <mat-form-field class="mr-2 w-10/12" > <!-- [ngClass]="{'w-full': !justTemplates}" style="width: 180px;" -->
            <mat-label>Cauta</mat-label>
            <input matInput [(ngModel)]="search" (ngModelChange)="searchSablon()">
          </mat-form-field>
          <button mat-stroked-button (click)="createTemplate()" class="h-14">
            <div class="flex flex-row justify-center items-center">
              <ion-icon name="add-circle-outline" class="text-2xl"></ion-icon>
              <span class="hidden md:block ml-2 mr-1">Creeaza</span>
            </div>
          </button>
        </div>
        <mat-list #shoes class="scrollable-list-sablon"> <!-- [ngClass]="{'horizontal-list': justTemplates}"-->
            <mat-list-item class="cursor-pointer hover:underline" *ngFor="let item of sabloane" [ngStyle]="{'': true}" (click)="selectSablon(item)">
                {{ item.template }}
            </mat-list-item>
        </mat-list>
      </mat-card>

      <mat-card class="flex flex-col justify-center items-center mb-5 p-2 max-card-height-sablon" *ngIf="!sabloane || !sabloane.length" [ngClass]="{'flex-col p-3 full-height-sablon': true || !justTemplates}">
        <span class="text-2xl my-5">Nu exista sabloane</span>
        <span class="text-lg opacity-85 mb-2">Pentru a crea, salvati un deviz ca si sablon</span>
        <button mat-stroked-button (click)="createTemplate()" class="h-14">
          <div class="flex flex-row justify-center items-center">
            <ion-icon name="add-circle-outline" class="text-2xl"></ion-icon>
            <span class="hidden md:block ml-2 mr-1">Creeaza</span>
          </div>
        </button>
      </mat-card>


      
      <!-- <mat-card class="flex flex-col p-2 max-card-height-deschise" *ngIf="!justTemplates">
        <span class="text-xl font-bold">Devize deschise</span>
        <mat-selection-list #shoes [multiple]="false" class="scrollable-list-deschise">
            <mat-list-option *ngFor="let item of devize | filterByKey:'deviz_id'" [value]="item" (click)="openDeviz(item)" [selected]="item.deviz_id === defaultDeviz.deviz_id">
                {{ item.nr_masina }} - {{ item.numar_deviz }}
            </mat-list-option>
        </mat-selection-list>
      </mat-card> -->
      
      
    <!-- </mat-card> -->
</div>