<ion-content [fullscreen]="true">
  <router-outlet></router-outlet>

  <div class="component-nomenclator-list-view page" *ngIf="showList">
    <div class="pageContent">

      <form class="flex flex-col lg:flex-col _gap-4 _mb-10 w-full mb-6" [formGroup]="form" (ngSubmit)="generalSearch()">
        
        <div class="rounded-lg bg-slate-800 border border-slate-500 p-3 flex flex-col w-full mb-6" *ngIf="merge_id && mergingProprietar">
          <span>Alege proprietarii cu care doresti sa unesti {{ mergingProprietar.nume_proprietar }}</span>
    
           <div class="flex flex-row">
             <mat-checkbox [checked]="mergeOptions.devize" (change)="mergeOptions.devize = $event.checked">
               Devize
             </mat-checkbox>
             <mat-checkbox [checked]="mergeOptions.masini" (change)="mergeOptions.masini = $event.checked">
               Masini
             </mat-checkbox>
           </div>
    
           <button mat-stroked-button (click)="merge()">Uneste</button>
        </div>

        <div class="flex flex-row justify-center items-center w-full gap-1">

          <mat-form-field class="my-2 _h-20 mr-4 w-full col-span-2">
            <input type="text" placeholder="Nume proprietar" matInput [matAutocomplete]="auto" [formControl]="proprietariNameCtrl">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="displayFirst($event)" [displayWith]="displayProprietarFn">
                <mat-option *ngFor="let proprietar of proprietariObservable | async" [value]="proprietar">
                {{ proprietar.nume_proprietar | json }}
                </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button class="block" type="submit" *ngIf="true"><ion-icon name="search-outline" class="text-2xl pl-3"></ion-icon></button>
          <button class="block" type="button" (click)="clearParams()" *ngIf="true"><ion-icon name="close-outline" class="text-2xl pl-3"></ion-icon></button>
    
          <mat-form-field class="_h-20 mr-4 w-full" style="display: none;">
            <input type="text" placeholder="Firebase UID" matInput [formControl]="proprietariFirebaseUIDCtrl">
          </mat-form-field>
    
          <mat-form-field class="_h-20 mr-4 w-full" style="display: none;">
            <input type="text" placeholder="Proprietar ID" matInput [formControl]="proprietariIdCtrl">
          </mat-form-field>
        </div>
        
        <!-- !!! just removed on mobile -->
        <form class="example-form flex flex-col mb-10 hidden" [formGroup]="range">
          <mat-form-field class="h-20 mr-4 w-full">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </form>
      </form>
      
      <div class="grid gap-4 w-full" [ngClass]="{'grid-cols-1 lg:grid-cols-3': !merge_id, 'grid-cols-1 lg:grid-cols-2': merge_id}">

        <mat-card appearance="outlined"
                                      *ngFor="let item of proprietari" 
                                      (click)="selectProprietar(item.proprietar_id)"
                                      id="prefix-{{item.proprietar_id}}"
                                      class="cursor-pointer"
                                      [ngClass]="{'border-2 border-green-300': item.proprietar_id == selected}">
          <mat-card-header>
            <div mat-card-avatar class="_example-header-image flex flex-row justify-center items-center">
              <ion-icon name="person" class="text-2xl relative mt-3"></ion-icon>
            </div>
            <mat-card-title class="leading-none flex flex-col justify-center items-center h-full pt-1 pb-1">
              <span class="text-lg relative _-bottom-2 leading-5">{{ item.nume_proprietar }}</span>
            </mat-card-title>
            <!-- <mat-card-subtitle>INTER S.R.L.  / NULL</mat-card-subtitle> -->
          </mat-card-header>
          <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
          <mat-card-content *ngIf="false">
            <!-- <p></p> -->
          </mat-card-content>
          <mat-card-actions *ngIf="merge_id">
            <mat-checkbox (change)="addProprietarForMerge(item.proprietar_id)" *ngIf="merge_id" class="_absolute _top-1 _left-1 mr-4"></mat-checkbox>
            <button mat-button (click)="checkOwner(item.proprietar_id)">
              <div class="flex flex-row justify-center items-center">
                <span class="mr-2">Verifica proprietarul</span>
                <ion-icon name="enter-outline" class="text-2xl relative" ></ion-icon>
              </div>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
  
      <strong class="block ml-auto mr-auto w-7/12 mt-8 text-3xl text-center" *ngIf="!proprietari.length">Nu exista proprietari</strong>

    </div>
  
  
  </div>

</ion-content>
