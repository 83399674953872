import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retry, retryWhen, scan, switchMap } from 'rxjs/operators';  // Using mergeMap to handle the inner Observable
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private afAuth: AngularFireAuth, private _authService: AuthService) { }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return this.afAuth.idToken.pipe(  // Access the idToken directly from AngularFireAuth
  //   mergeMap(token => {
  //       if (token) {
  //         // Clone the request and update the header
  //         const clonedRequest = request.clone({
  //           headers: request.headers.set('Authorization', `Bearer ${token}`)
  //         });
  //         return next.handle(clonedRequest);
  //       }
  //       return next.handle(request);
  //     })
  //   );
  // }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const token = this._authService.getToken();
  //   const merchant_id = this._authService.getMerchantId() || this._authService.getItem('alon_merchant_id');
  //   console.log('HAVE INTERCEPTOR?', token, merchant_id, request.url);
  //   if (token) {
  //     // Clone the request and update the header
  //     let clonedRequest = undefined;
  //     if(merchant_id) {
  //       clonedRequest = request.clone({
  //         headers: request.headers
  //                  .set('Authorization', `Bearer ${token}`)
  //                  .set('X-Merchant-ID', merchant_id)
  //       });
  //     } else {
  //       clonedRequest = request.clone({
  //         headers: request.headers
  //                  .set('Authorization', `Bearer ${token}`)
  //       });
  //     }


  //     return next.handle(clonedRequest).pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status === 0) {
  //           // Client-side error or network error
  //           Sentry.captureException(new Error(`Network Error: ${clonedRequest.url}`));
  //         } else {
  //           // Server-side error
  //           Sentry.captureException(error);
  //         }
  //         return throwError(() => error);
  //       })
  //     );
  //   }
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 0) {
  //         // Client-side error or network error
  //         Sentry.captureException(new Error(`Network Error: ${request.url}`));
  //       } else {
  //         // Server-side error
  //         Sentry.captureException(error);
  //       }
  //       return throwError(() => error);
  //     })
  //   );
  // }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._authService.getToken();
    let local_merchant_id = environment?.merchantId || null;
    if (!local_merchant_id && localStorage.getItem('alon_merchant_id')) {
      local_merchant_id = JSON.parse((<any>localStorage.getItem('alon_merchant_id')));
    }
    let merchant_id = local_merchant_id || this._authService.getMerchantId() || this._authService.getItem('alon_merchant_id');

    if (token) {
      let clonedRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });

      if (merchant_id) {
        clonedRequest = clonedRequest.clone({
          headers: clonedRequest.headers.set('X-Merchant-ID', merchant_id)
        });
      }

      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0 || !merchant_id) {
            return this.handleRetry(clonedRequest, next);
          } else {
            return this.handleError(clonedRequest, error);
          }
        })
      );
    } else {
      return next.handle(request); // If there's no token, proceed without modifying the request
    }
  }

  private handleRetry(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(
      delay(1000),
      mergeMap(() => {
        let local_merchant_id = environment?.merchantId || null;
        if (!local_merchant_id && localStorage.getItem('alon_merchant_id')) {
          local_merchant_id = JSON.parse((<any>localStorage.getItem('alon_merchant_id')));
        }
        let merchant_id = local_merchant_id || this._authService.getMerchantId() || this._authService.getItem('alon_merchant_id');
    
        if (merchant_id) {
          const updatedRequest = request.clone({
            headers: request.headers.set('X-Merchant-ID', merchant_id)
          });
          return next.handle(updatedRequest);
        } else {
          return throwError(() => new Error('Merchant ID not found after retrying.' + environment?.merchantId + '\\' + merchant_id));
        }
      }),
      retry(1), // Retry once after the first failed attempt (total of two attempts)
      catchError((error: HttpErrorResponse) => this.handleError(request, error))
    );
  }

  private handleError(request: HttpRequest<any>, error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // Client-side error or network error
      Sentry.captureException(new Error(`Network Error: ${request.url}`));
    } else {
      // Server-side error
      Sentry.captureException(error);
    }
    return throwError(() => error);
  }
}
