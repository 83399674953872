import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProprietarItemView } from './proprietar/components/proprietar-item-view/proprietar-item-view.component';
import { InterCommonModule } from './common.module';
import { NomenclatorItemView } from './nomenclator/components/nomenclator-item-view/nomenclator-item-view.component';
import { MasinaItemView } from './masina/components/masina-item-view/masina-item-view.component';
import { Tab4Page } from './proprietar/tab4.page';
import { ProprietariFirebase } from './proprietar/components/proprietari-firebase/proprietari-firebase.component';
import { MasinaList } from './masina/components/masina-list/masina-list.component';
import { HomeComponent } from './home/home.component';
import { S1Component } from './forbidden/s1/s1.component';
import { PiesaItemView } from './lucrare/components/piesa-item-view/piesa-item-view.component';
import { MenuComponent } from './tabs/menu-component/menu.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'menu',
    component: MenuComponent,
    outlet: 'secondary'
  },
  {
    path: 'add-firebase',
    component: ProprietariFirebase,
    outlet: 'secondary'
  },
  {
    path: 'proprietari-create',
    component: ProprietarItemView,
    outlet: 'secondary'
  },
  {
    path: 'proprietari-create-list',
    component: Tab4Page,
    outlet: 'secondary'
  },
  {
    path: 'nomenclator-create',
    component: NomenclatorItemView,
    outlet: 'secondary'
  },
  {
    path: 'masini-create',
    component: MasinaItemView,
    outlet: 'secondary'
  },
  {
    path: 'masini-list',
    component: MasinaList,
    outlet: 'secondary'
  },
  {
    path: 'piese-create',
    component: PiesaItemView,
    outlet: 'secondary'
  },
  {
    path: 's1',
    component: S1Component
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    InterCommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
