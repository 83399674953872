<form [formGroup]="searchForm" class="w-full flex flex-col gap-1" [ngClass]="{'p-5': viewState === 'DIALOG', 'lg:p-0': viewState !== 'DIALOG'}" (ngSubmit)="search()">
  <div class="flex flex-row flex-wrap justify-center items-center">
    <div class="w-full lg:w-10/12">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Cauta.. serie/numar</mat-label>
        <input matInput placeholder="Cauta...serie/numar" formControlName="search_query">
      </mat-form-field>
    </div>
    
    <div class="flex flex-row justify-center lg:justify-end items-center w-full lg:w-2/12 pt-3 lg:pt-0">
      <button class="block" type="submit" *ngIf="viewState !== 'DIALOG'"><ion-icon name="search-outline" class="text-2xl _pb-4 pl-3"></ion-icon></button>
      <button class="block" type="button" (click)="clear()" *ngIf="viewState !== 'DIALOG'"><ion-icon name="close-outline" class="text-2xl _pb-4 pl-3"></ion-icon></button>
      <button class="block" type="button" (click)="openFilters()" *ngIf="viewState !== 'DIALOG'"><ion-icon name="filter-circle-outline" class="text-2xl _pb-4 pl-3"></ion-icon></button>
    </div>
  </div>


  
  <!-- Row for the next three input fields -->
  <div class="gap-2 grid-cols-3 _hidden _lg:flex" *ngIf="viewState === 'DIALOG'" [ngClass]="{
    'hidden': viewState !== 'DIALOG',
    'block': viewState === 'DIALOG'
  }">
    <mat-form-field appearance="fill" class="flex-grow w-full mt-1">
      <mat-label>Serie Sasiu</mat-label>
      <input matInput placeholder="Serie Sasiu" formControlName="serie_sasiu">
    </mat-form-field>

    <mat-form-field appearance="fill" class="flex-grow w-full mt-2">
      <mat-label>Numar masina</mat-label>
      <input matInput placeholder="Nr Masina" formControlName="nr_masina">
    </mat-form-field>

    <mat-form-field appearance="fill" class="flex-grow w-full mt-2">
      <mat-label>Tip motor</mat-label>
      <input matInput placeholder="Tip motor" formControlName="tip_motor_masina">
    </mat-form-field>
  </div>

  <div class="flex flex-row w-full justify-center items-center">
    <button class="mt-3 mr-4" mat-button (click)="onNoClick()" *ngIf="viewState === 'DIALOG'">Cancel</button>
    <button class="mt-3" mat-flat-button (click)="search()" [mat-dialog-close]="searchForm.value" cdkFocusInitial *ngIf="viewState === 'DIALOG'">Cauta</button>
  </div>
</form>
  

<!-- when adding isAdmin add these things as well -->

<!-- <mat-form-field appearance="fill" class="w-full"[ngClass]="{
  'hidden': viewState !== 'DIALOG',
  'block': viewState === 'DIALOG'
}">
  <mat-label>Masina ID</mat-label>
  <input matInput placeholder="database id..." formControlName="masina_id">
</mat-form-field> -->

<!-- <mat-form-field appearance="fill" class="flex-grow w-full">
  <mat-label>Proprietar ID</mat-label>
  <input matInput placeholder="Proprietar ID" formControlName="proprietar_id">
</mat-form-field> -->

<!-- <div class="gap-2 items-center _hidden _lg:flex" *ngIf="!standalone" [ngClass]="{
  'hidden _md:block': !this.isModal,
  'block': this.isModal
}">
  <mat-form-field appearance="fill" class="flex-grow w-full">
    <mat-label>Start Date</mat-label>
    <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="start_date">
    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill" class="flex-grow w-full">
    <mat-label>End Date</mat-label>
    <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="end_date">
    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</div> -->