import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CreateManageComponent } from '../../create-manage/create-manage.component';
import { CheckServiceComponent } from '../check-service/check-service.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'song-list-component',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent {
  public apiUrl: string = 'http://localhost:3000';

  @Input('songs') songs: any[] = [];
  dataSource = new MatTableDataSource<any>(this.songs);
  @Output('sendSelected') sendSelected = new EventEmitter<any>();
  selectedSongs = new SelectionModel<any>(true, []);
  displayedColumns: any[] = ['select', 'Tr. Name Original', 'Tr. Artist Original', 'Controls', 'Services', 'Created at', 'Release date']
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _httpClient: HttpClient,
    private _modalCtrl: ModalController
  ) {

  }

  ngOnInit(): void {
    this.selectedSongs.changed.subscribe((selected: any) => {
      console.log('selected songs', selected.source.selected);
      this.sendSelected.emit(selected.source.selected)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.songs = changes['songs'].currentValue;
    this.dataSource.data = this.songs;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async openTags(song_id: string): Promise<void> {
    const modal = await this._modalCtrl.create({
      component: CreateManageComponent,
      componentProps: {
        'songId': song_id
      },

      // cssClass: 'rotate-that-shit',
      // showBackdrop: false,
      breakpoints: [0.85],
      // initialBreakpoint: 1,
      // canDismiss: false,
      // handle: false,
      // backdropDismiss: false,
      // backdropBreakpoint: 1
    });

    modal.present();

    const { data, role } = await modal.onWillDismiss();
  }

  autoTag(song: any): void {
    // from song name artist etc, fetch and show
  }

  deleteSong(song_id: string): void {
    this._httpClient.delete(this.apiUrl + '/song/' + song_id).subscribe(data => {
      console.log('deleted song', data);
      this.songs = this.songs.filter(song => song.song_id !== song_id);
      this.dataSource.data = this.songs;
    });
    // and remove from this.songs song.song_id
  }

  // will show the services available, and offer to search the song in one of the services and show alternatives and also show metadata from the beets or others
  async checkService(song: any): Promise<void> {
    const modal = await this._modalCtrl.create({
      component: CheckServiceComponent,
      componentProps: {
        'song': song
      },

      // cssClass: 'rotate-that-shit',
      // showBackdrop: false,
      breakpoints: [0.85],
      // initialBreakpoint: 1,
      // canDismiss: false,
      // handle: false,
      // backdropDismiss: false,
      // backdropBreakpoint: 1
    });

    modal.present();

    const { data, role } = await modal.onWillDismiss();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selectedSongs.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedSongs.clear();
      // console.log('selected', this.selectedSongs);
      return;
    }
    this.selectedSongs.select(...this.dataSource.data);
    // console.log('selected', this.selectedSongs);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    // console.log('selected', this.selectedSongs);
    return `${this.selectedSongs.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

}