<div class="page">
  <div class="pageContent">

    <form [formGroup]="form" class="flex flex-row flex-wrap gap-2 w-full mt-2">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Denumire Piesă (apare pe deviz)</mat-label>
        <input matInput type="text" formControlName="denumire_piesa" #denumirePiesaInput>
        @if (form.controls.denumire_piesa.hasError('required')) {
          <mat-error>Denumirea este obligatorie</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Cod Piesă (apare pe deviz)</mat-label>
        <input matInput type="text" formControlName="cod_oe_piesa">
        @if (form.controls.cod_oe_piesa.hasError('required')) {
          <mat-error>Codul este obligatoriu</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Denumire Producător</mat-label>
        <input matInput type="text" formControlName="denumire_producator">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Cod Producător</mat-label>
        <input matInput type="text" formControlName="cod_producator">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Adaos</mat-label>
        <input matInput type="text" formControlName="adaos">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full" *ngIf="!isSecondaryOutletActive()">
        <mat-label>Pret unitar fara TVA lei</mat-label>
        <input matInput type="number" formControlName="pret_unitar_fara_tva_lei">
      </mat-form-field>
      
      <!-- Button row -->
      <div class="flex flex-row gap-2 w-full mt-2">
        <button mat-flat-button color="accent" type="submit" *ngIf="!defaultItem.lucrare_id" (click)="savePiesa()">Salveaza</button>
        <button mat-stroked-button type="button" *ngIf="!formEnabled" (click)="edit()">Editeaza</button>
        <button mat-flat-button type="submit" *ngIf="formEnabled && defaultItem.lucrare_id" (click)="updatePiesa()">Salveaza schimbari</button>
        <button mat-stroked-button color="warn" type="button" *ngIf="viewState === 'PRIMARY_ROUTE'" (click)="deletePiesa()">Elimina</button>
      </div>
    
    </form>
    
  </div>
</div>