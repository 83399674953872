import { Component, Inject, Input, SimpleChanges } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SharedService } from "../../providers/shared.service";
import { AuthService } from "src/app/home/auth.service";

@Component({
    selector: 'deviz-print',
    templateUrl: 'deviz-print.component.html',
    styleUrls: ['deviz-print.component.scss'],
  })
  export class DevizPrint {
    @Input() deviz: any = {};    
    @Input() isPreview: boolean = true;

    isBig: boolean = false;

    defaultDeviz: any = {};
    defaults: any = this._authService.getDefaults();

    constructor(
        private _authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sharedService: SharedService
    ) {
        if(data) {
            this.defaultDeviz = data;
            this.deviz = data;
        } else {
            this.defaultDeviz = this.deviz;
        }

        this.sharedService.getDataObservable().subscribe(data => {
            this.deviz = data;
            this.defaultDeviz = this.deviz;

            if(this.deviz?.operatiuni_manopera && this.deviz?.operatiuni_materiale) {
                this.isBig = ((this.deviz.operatiuni_manopera.length || 0) + (this.deviz.operatiuni_materiale.length || 0)) > 30;
            }

        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if(_.isEqual(changes['filters'].previousValue, changes['filters'].currentValue)) return;
        // if(!changes['deviz']?.currentValue) return;
        this.defaultDeviz = changes['deviz']?.currentValue;
    }

    isNumber(value: any): boolean {
        return !(typeof value === 'string');
    }

    getTotalTimp(cantitate: number, norma: string): any {
        return (parseFloat(norma) * cantitate);
    }
  }