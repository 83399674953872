<div class="w-full h-full flex-col">
  <!-- <pre>{{ songs | json}}</pre> -->

  <!-- open tag modal, have option to autotag or search for a tag, or create another tag -->
  <!-- <div *ngFor="let item of songs">
    <button>
      <p>{{item.track_name_original}} - {{ item.track_artists_original }}</p>
    </button>
  </div> -->

  <!-- somewhere should see tags applied -->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full h-full" matSort>

    <!-- todo: on select output -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selectedSongs.hasValue() && isAllSelected()"
                      [indeterminate]="selectedSongs.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selectedSongs.toggle(row) : null"
                      [checked]="selectedSongs.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Tr. Name Original">
      <th mat-header-cell *matHeaderCellDef> Tr. Name Original </th>
      <td mat-cell *matCellDef="let song"> {{song.track_name_original}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="Tr. Artist Original">
      <th mat-header-cell *matHeaderCellDef> Tr. Artist Original </th>
      <td mat-cell *matCellDef="let song"> {{song.track_artists_original}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="Controls">
      <th mat-header-cell *matHeaderCellDef> Controls </th>
      <td mat-cell *matCellDef="let song"> 
        <button mat-button (click)="deleteSong(song.song_id)">Delete</button>
        <!-- should also be able to delete tags  -->
        <button mat-button (click)="openTags(song.song_id)">Tag</button> 
        <!-- fetches metadata from music brainz, shows to the create/manage modal the metadata selected, can deselect or edit metadata_tags that are wrong -->
        <!-- <button mat-button (click)="autoTag(song)">AutoTag</button>  -->
        <button mat-button (click)="checkService(song)">CheckService/AutoTag</button> 
      </td>
    </ng-container>

    <ng-container matColumnDef="Services">
      <th mat-header-cell *matHeaderCellDef> Services </th>
      <td mat-cell *matCellDef="let song"> 
        <span *ngIf="song.song_id_spotify">spotify[*]</span>
        <span *ngIf="song.song_id_ytmusic">ytmusic[*]</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Created at">
      <th mat-header-cell *matHeaderCellDef> Created at </th>
      <td mat-cell *matCellDef="let song"> 
        <span>{{ song.created_at }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Release date">
      <th mat-header-cell *matHeaderCellDef> Release date </th>
      <td mat-cell *matCellDef="let song"> 
        <span>{{ song.release_date }}</span>
      </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <!-- <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let song"> {{song.symbol}} </td>
    </ng-container> -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedSongs.toggle(row)"></tr>
  </table>
</div>