import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ListenService {
    private data: BehaviorSubject<any> = new BehaviorSubject(null); // think music or smth
    private whitelistTags: BehaviorSubject<[]> = new BehaviorSubject([]);
    private blacklistlistTags: BehaviorSubject<[]> = new BehaviorSubject([]);

    constructor() { }

    getTagsToListen() {
        return this.data.asObservable();
    }

    updateTagsToListen(newValue: any) {
        this.data.next(newValue);
    }

    getWhitelistTags() {
        return this.whitelistTags.asObservable();
    }

    updateWhitelistTags(newValue: any) {
        this.whitelistTags.next(newValue);
    }

    getBlacklistTags() {
        return this.blacklistlistTags.asObservable();
    }

    updateBlacklistTags(newValue: any) {
        this.blacklistlistTags.next(newValue);
    }
}
