import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';


interface DialogData {
  any?: any
}
@Component({
  selector: 'piese-list-filters',
  templateUrl: 'piese-list-filters.component.html',
  styleUrls: ['piese-list-filters.component.scss']
})
export class PieseListFilters {
  @Input() filters: any;
  @Output() triggerSearch = new EventEmitter<any>();
  isModal: boolean = false;

  searchForm = new FormGroup({
    search_query: new FormControl(''),
    fk_masina_id: new FormControl(''),
    serie_sasiu: new FormControl(''),
    lucrare_id: new FormControl(''),
    denumire_piesa: new FormControl(''),
    cod_oe_piesa: new FormControl(''),
    denumire_producator: new FormControl(''),
    cod_producator: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    page: new FormControl(1),
    per_page: new FormControl(50)
  });

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _interface: InterfaceService,

    //
    public dialogRef: MatDialogRef<PieseListFilters>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.isModal = !!Object.keys(this.data).length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(_.isEqual(changes['filters'].previousValue, changes['filters'].currentValue)) return;
    this.filters = changes['filters'].currentValue; //Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;

    this.setForm();
  }
  
  ngOnInit(): void {
    // this.searchForm.valueChanges.pipe(
    //   debounceTime(300),
    // ).subscribe(filtersAsIType => {
    //   this.triggerSearch.emit(filtersAsIType)
    // });

    this.filters = Object.keys(this.data).length ? Object.assign({}, this.filters, this.data) : this.filters;
    
    this.setForm();
  }


  setForm(): void {
    this.searchForm.setValue({
      "search_query": this.filters?.search_query || "",
      "fk_masina_id": this.filters?.fk_masina_id || "",
      "serie_sasiu": this.filters?.serie_sasiu || "",
      "lucrare_id": this.filters?.lucrare_id || "",
      "denumire_piesa": this.filters?.denumire_piesa || "",
      "cod_oe_piesa": this.filters?.cod_oe_piesa || "",
      "denumire_producator": this.filters?.denumire_producator || "",
      "cod_producator": this.filters?.cod_producator || "",
      "start_date": this.filters?.start_date || "",
      "end_date": this.filters?.end_date || "",
      "page": this.filters?.page || 1,
      "per_page": this.filters?.per_page || 50
    });
  }

  clearFilters(): void {
    this.searchForm.reset({
      search_query: '',
      fk_masina_id: '',
      serie_sasiu: '',
      lucrare_id: '',
      denumire_piesa: '',
      cod_oe_piesa: '',
      denumire_producator: '',
      cod_producator: '',
      start_date: '',
      end_date: '',
      page: 1, // Assuming default page is 1
      per_page: 50 // Assuming default per_page is 50
    });
  
    // Optionally, if you need to perform additional actions after clearing the filters
    // For example, reloading data with default filters
    this.search();
  }

  onNoClick(): void {
    this.filters = this.searchForm.value;
    this.closeDialog();
  }

  search(): void {
    this.triggerSearch.emit(this.searchForm.value);
  }

  closeDialog(filters?: any): void {
    if(this.dialogRef?.close) {
      this.dialogRef.close(filters);
    }
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(PieseListFilters, {
      data: this.filters,
      width: '90%',
      // height: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.filters = result; // sigur nu se seteaza in searchForm
        this.setForm();
        this.search();
      }
      // this.clearFilters(); 
    });
  }

  async addPiesa(): Promise<void> {
    this._interface.piesaCreate(this.filters?.fk_masina_id)
  }
}
