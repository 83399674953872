import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, debounceTime, map, of, switchMap } from 'rxjs';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';
import { AuthService } from 'src/app/home/auth.service';
import { environment } from 'src/environments/environment';

interface DialogData {
  any?: any
}

@Component({
  selector: 'nomenclator-list',
  templateUrl: 'nomenclator-list.component.html',
  styleUrls: ['nomenclator-list.component.scss']
})
export class NomenclatorList {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
    'SECONDARY_ROUTE' | // used on deviz to create a new car
    'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
    'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG


  // END KEEP

  operationNameCtrl = new FormControl();
  legacyCtrl = new FormControl();

  form: FormGroup = new FormGroup({
    operation_name: this.operationNameCtrl,
    legacy_code: this.legacyCtrl
  });

  operationsObservable: Observable<any> | undefined;

  operations: any[] = [];
  types: string[] = [];
  apiUrl: string = environment.apiUrl;
  defaults: any = this._authService.getDefaults();

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private menuCtrl: MenuController,
    private _authService: AuthService,
    private _interface: InterfaceService,

    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  ngOnInit(): void {
    this.getOperations();

    // figure out state
    this.viewState = !!Object.keys(this.data).length ? 'DIALOG' : this.viewState;
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }


    // for search
    this.operationsObservable = this.operationNameCtrl.valueChanges.pipe(
      debounceTime(300),
      switchMap(term => {
        this.getOperations();
        return of([]);
        // return this.searchOperationsByName(term);
      })
    );

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('nomenclator-item') || event.url.includes('nomenclator-create')) {
          // this.showList = false;
        } else {
          // this.showList = true;

          // retriggers list generation upon creating on the deviz page a new nomenclator item
          this.getOperations();
        }
      }
    });
  }

  ngOnDestroy(): void {
  }

  getOperations(): void {
    let params = new HttpParams();

    const filters: any = {
      page: '1',
      per_page: '50',
      q: this.operationNameCtrl.value || null,
      legacy_code: this.legacyCtrl.value || null
    }

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        params = params.append(key, filters[key]);
      }
    });

    this._http.get(`${this.apiUrl}/nomenclator`, { params: params }).subscribe((operations: any) => {
      this.operations = operations.operations.map((obj: any) => ({ ...obj, type: obj.type || 'manopera' })).filter((operation: any) => (operation.pret_unitar_fara_tva_lei !== null)).filter((operation: any) => operation.norma_de_timp !== null); // temp
    });
  }

  selectItem(item: any): void {
    if (this.viewState === 'PRIMARY_ROUTE') {
      this._router.navigate(['/tabs/tab2/nomenclator-item/' + item.operation_id]);
    } 
    
    if(this.viewState === 'DIALOG') {
      this._interface.closeDialog(item);
    }
  }

  clear(): void {
    this.operationNameCtrl.setValue(null);
    this.legacyCtrl.setValue(null);
    this.getOperations();
  }

  // for search
  searchOperationsByName(term: any): Observable<any> {
    if (!term) return of([]);

    let search;
    if (typeof term === 'string' || term instanceof String) {
      search = term;
    } else {
      search = term.nume_proprietar;
    }

    return this._http.get<any>(`${this.apiUrl}/nomenclator?q=${search}`).pipe(
      map(operation => {
        return operation.operations;
      })
    )
  }

  displayFirst(event: any): void {
    const operation = event.option.value;

    const index = this.operations.findIndex(obj => {
      return obj['operation_id'] === operation.operation_id;
    });

    if (index >= 0) {
      const [object] = this.operations.splice(index, 1);
      this.operations.unshift(object);
    } else {
      this.operations.unshift(operation);
    }
  }

  displayOperationFn(operation: any): string {
    return operation && operation.operation_name ? operation.operation_name : '';
  }

  async addOperatiune(): Promise<void> {
    let prefill = null;
    if (this.operationNameCtrl.value && typeof this.operationNameCtrl.value === 'string') {
      prefill = this.operationNameCtrl.value;
    }

    this._interface.openNomenclatorCreate(prefill);
  }
}


// let prefill = null;
// if(this.operationNameCtrl.value && typeof this.operationNameCtrl.value === 'string') {
//   prefill = this.operationNameCtrl.value;
// }

// if(this.dialogRef.close) {
//   this.dialogRef.close();
// }
// this._router.navigate([{outlets: { secondary: 'nomenclator-create'}}], { skipLocationChange: false, queryParams: { prefill: prefill } });
// await this.menuCtrl.open('second-menu');