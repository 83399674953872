<div class="w-full h-full flex-col">
  <div class="flex flex-col text-center py-4">
    Just the homescreen, gets the data. Responsible for auth
  </div>
  <div class="flex flex-row justify-start">
    <div class="flex flex-col w-1/2">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="bold" (click)="getHome()">HOME_UNTAGGED</mat-button-toggle>
        <mat-button-toggle value="italic" (click)="getOlds()">OLD_UNTAGGED</mat-button-toggle>
        <mat-button-toggle value="underline" (click)="next()">Next ({{ page }})</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="flex flex-row justify-end w-1/2">
      <ion-button primary (click)="getHome()">
        Refresh home
      </ion-button>
      
      <ion-button primary (click)="spotifySync()" *ngIf="true">
        Spotify sync
      </ion-button>
    
      <!-- just go back after pressing, or can just be http'ed ?? -->
      <ion-button primary (click)="spotifyLogin()" *ngIf="true">
        Spotify login
      </ion-button>
    </div>
  </div>

  <!-- clicking should be create/manage -->
  <song-list-component [songs]="homeSongs"></song-list-component>
</div>