<div class="p-2 pl-16 pr-7 bg-white _overflow-scroll text-black deviz-print" [ngClass]="{'hidden print:block': !isPreview, 'block': isPreview}" *ngIf="true ||defaultDeviz?.proprietar_id && defaultDeviz?.masina_id">
  <div class="flex flex-col w-full h-full justify-between ">
    <!-- style="height: 96vh !important;" -->

    <div class="header flex flex-col justify-between items-start _mb-1 pt-4">

      <div class="flex flex-row justify-between w-full text-xs">
        <div class="text-left text-xs">
          <p class="text-xs font-bold">S.C. INTER NT SERVICE S.R.L.</p>
          <p class="text-xs">TEL/FAX: 0236 477 660</p>
          <div class="border-t text-xs">
            <span class="block text-xs">Str. BRĂILEI nr. 280 GALAŢI  </span>
           <!-- <span class="block text-xs">Code 800396	SERVICE AUTO</span> -->
          </div>
          <!-- <p class="italic text-xs">{{ defaultDeviz.date_created | date: 'mediumDate' }}</p> -->
        </div>

        <strong class="block">Deviz de lucrari service #{{ defaultDeviz.numar_deviz }} </strong>

        <div class="text-xs text-right"> 
          <div class="flex flex-row justify-end">
            <p class="italic text-xs">&nbsp; {{ defaultDeviz.date_created | date: 'mediumDate' }}</p>
          </div> 
          
          
          <div class="client-details my-1 text-md">
            <div class="flex flex-row justify-end">
              <h1 class="text-md font-bold _mt-1">CLIENT  - &nbsp;</h1>
              <p class="italic text-xs">{{ defaultDeviz.nume_proprietar }} - &nbsp;</p>
              <p class="italic text-xs">{{ defaultDeviz.adresa_proprietar }}</p>
            </div>
            <div class="flex flex-row justify-end">
              <p class="italic text-xs">{{ defaultDeviz.nr_masina }} - </p>
              <p class="italic text-xs">&nbsp; {{ defaultDeviz.KM }} km - &nbsp;</p>
              <p class="italic text-xs">{{ defaultDeviz.car_details?.marca_masina }} </p>
              <p class="italic text-xs">&nbsp; {{ defaultDeviz.car_details?.model_masina }}</p>
            </div>

            <div class="flex flex-row justify-end  border-t">
              <p class="mb-1"><strong>Executat de:</strong> {{ defaultDeviz.executant }}</p>
              <!-- <p class="italic text-xs">{{ defaultDeviz.car_details?.marca_masina }} </p>
              <p class="italic text-xs">&nbsp; {{ defaultDeviz.car_details?.model_masina }}</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center items-center w-full _h-full !!!!!!!!!!!!!!"  [ngClass]="{ 'height-for-big': isBig, 'height-for-small': !isBig }">
        <!-- TODO 2 PAGINI SAU MY-4 SA FIE IN MY-1 DACA E PREA MARE-->
        <table class="table-auto w-full !!!!! custom-table" [ngClass]="{ 'mb-1': isBig, 'mb-4': !isBig }" *ngIf="defaultDeviz?.operatiuni_manopera?.length">
          <thead>
            <tr>
              <th class="border very-important-border text-xs font-normal" style="font-size: 9px !important;"> <!-- Empty TH for alignment -->
                #
              </th>
              <th class="border very-important-border _text-xs">
                <span class="block font-bold "  style="font-size: 9px !important;">Denumire manopera</span>
              </th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Timp</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Preț fără T.V.A.</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Valoare</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Valoare T.V.A.</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let manopera of defaultDeviz.operatiuni_manopera; let i = index">
              <td class="border text-center" style="font-size: 8px !important;">
                {{ i + 1 }} <!-- Displaying row number, starting from 1 -->
              </td>
              <td class="border"  style="font-size: 8px !important;">{{ manopera.operation_name }}</td>
              <td class="border very-important-border text-center"  style="font-size: 8px !important;">{{ getTotalTimp(manopera.cantitate, manopera.norma_de_timp) | number:'1.2-2' }}</td>
              <td class="border very-important-border text-center"  style="font-size: 8px !important;">{{ manopera.pret_norma || defaults.pretNorma | number:'1.2-2':'fr-FR' }}</td>
              <td class="border very-important-border text-center"  style="font-size: 8px !important;">{{ manopera.valoare | number:'1.2-2':'fr-FR' }}</td>
              <td class="border very-important-border text-center"  style="font-size: 8px !important;">{{ manopera.valoare_tva | number:'1.2-2':'fr-FR' }}</td>
            </tr>
          </tbody>
        </table>
        
        
        <table class="table-auto w-full _mb-1 !!!!!!!! custom-table" *ngIf="defaultDeviz?.operatiuni_materiale?.length">
          <thead>
            <tr>
              <th class="border very-important-border text-xs font-normal" style="font-size: 9px !important;"> <!-- Empty TH for alignment -->
                #
              </th>
              <th class="border very-important-border text-xs">
                <span class="block font-bold" style="font-size: 9px !important;">Denumire material</span>
              </th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Cantitate</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Preț fără T.V.A.</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Valoare</th>
              <th class="border very-important-border _text-xs font-bold" style="font-size: 9px;">Valoare T.V.A.</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let material of defaultDeviz.operatiuni_materiale; let i = index">
              <td class="border text-center" style="font-size: 8px !important;">
                {{ i + 1 }} <!-- Displaying row number, starting from 1 -->
              </td>
              <td class="border">
                <span class="text-xs block" style="font-size: 8px !important;">
                  {{ material.denumire_piesa }}<span *ngIf="material.cod_oe_piesa"> - {{ material.cod_oe_piesa }}</span>
                </span>
              </td>
              <td class="border very-important-border text-center" style="font-size: 8px !important;">{{ material.cantitate }}</td>
              <td class="border very-important-border text-center" style="font-size: 8px !important;">
                <span style="font-size: 8px !important;" *ngIf="isNumber(material.pret_unitar_fara_tva_lei)">{{ material.pret_unitar_fara_tva_lei | number:'1.2-2':'fr-FR' }}</span>
              </td>
              <td class="border very-important-border text-center" style="font-size: 8px !important;">{{ material.valoare | number:'1.2-2':'fr-FR' }}</td>
              <td class="border very-important-border text-center" style="font-size: 8px !important;">{{ material.valoare_tva | number:'1.2-2':'fr-FR' }}</td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>


    <div class="flex flex-col w-full" [ngClass]="{ 'pull-up': !isBig }">
      <div class="totals flex justify-between">
        <div class="space-y-2" style="line-height: 2px; font-size: 10px;">
            <div class="flex justify-between">
              <span><strong>Total Manopera:</strong></span>
              <span class="pl-3">{{ defaultDeviz.total_manopera | number:'1.2-2':'fr-FR' }} RON</span>
            </div>
            <div class="flex justify-between">
              <span><strong>Total materiale:</strong></span>
              <span class="pl-3">{{ defaultDeviz.total_materiale | number:'1.2-2':'fr-FR' }} RON</span>
            </div>
            <div class="flex justify-between">
              <span><strong>TOTAL MAT. + MANOPERA:</strong></span>
              <span class="pl-3">{{ defaultDeviz.total_materiale_plus_total_manopera | number:'1.2-2':'fr-FR' }} RON</span>
            </div>
            <div class="flex justify-between">
              <span><strong>Total TVA:</strong></span>
              <span class="pl-3">{{ defaultDeviz.total_tva | number:'1.2-2':'fr-FR' }} RON</span>
            </div>
          </div>
      <p style="line-height: 3px; font-size: 10px;"><strong>Total general:</strong> {{ defaultDeviz.total_general | number:'1.2-2':'fr-FR' }} RON</p>
    </div>
    
    <div class="_text-xs w-full text-center mt-1 _mb-1" style="
    line-height: 7px !important;
    opacity: 0.9;
    font-size: 8px !important;
    padding: 9px 0;
    ">
        <span>
            CERTIFICAT DE CALITATE SI GARANTIE - Unitatea service garanteaza lucrarea executata* si conformitatea acesteia**, potrivit L.449/2003 si Li 296/2004:
        </span>
        <span>
            1. 3 luni de la data receptiei autovehicolului, daca lucrarea nu a necesitat inlocuiri de piese sau daca s-a executat cu piesa clientului
        </span>        
        <span>
            2. Garantia pieselor furnizate de unitate, in baza O.G. 140/2021-conf. declaratiei producatorului prin conventie intre parti,conditionat de resp.indicatiilor de exploatare prevazutede producatorul autovehicolului este de.24.luni.
        </span>
        <span>
            3. La cererea beneficiarului, unitatea va asigura vizualizarea activitatilor desfasurate si inregistrari care sa dovedeasca efectuarea inspectiilor finale.
        </span>
        <span>
            4. Service-ul isi rezerva dreptul de retentie a masinii pana la achitarea acesteia.
        </span>
    </div>
    
    <div class="footer text-center _mt-1 grid grid-cols-2 pb-16 _border border-red-400 border-solid" style="line-height: 9px; font-size: 9px;">
      <p class="block">Responsabil,</p>
      <div class="flex flex-col">
        <p>De acord cu lucrarea executată</p>
        <p>Semnătură client,</p>
      </div>
    </div>
    </div>
  </div>
</div>
  
