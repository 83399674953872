import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { MenuController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/home/auth.service';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';
import { Proprietar } from 'src/app/deviz/providers/models';

@Component({
  selector: 'proprietar-item-view',
  templateUrl: `./proprietar-item-view.component.html`,
  styleUrls: ['./proprietar-item-view.component.scss']
})
export class ProprietarItemView {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
             'SECONDARY_ROUTE' | // used on deviz to create a new proprietar
             'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
             'DIALOG' = 'PRIMARY_ROUTE'; // not applicable anywhere, think just on filters
  // END KEEP


  // @Input() isView = true;
  // standalone: boolean = false;
  // itemPage: boolean = false;

  // apiUrl: string = 'http://127.0.0.1:5000';
  apiUrl: string = environment.apiUrl;
  isSuperAdmin: boolean = false;  

  formEnabled: boolean = false;
  defaultItem: Proprietar = {
    "nume_proprietar": null,
    "firebase_uid": null,
    "numar_telefon": null,
    numere_array: [],
    "observatii": null,
  }
  numar_nou: string | null = null;
  expanded_array: any[] = [];
  richTextContent: string | null | undefined = '';
  curre: string | null = null;
  quillOptions: any = {
    // later: pictures in editor
    toolbar: ['bold', 'italic', 'underline', { 'header': 1 }, { 'header': 2 }, { 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]
  }


  // form
  numeProprietarCtrl = new FormControl();
  firebaseUIDCtrl = new FormControl();  
  contacteCtrl = new FormControl([]);
  clientStateCtrl = new FormControl(null);
  // numarNouCtrl = new FormControl('');

  form: FormGroup = this.fb.group({
    "nume_proprietar": this.numeProprietarCtrl,
    "firebase_uid": this.firebaseUIDCtrl,
    "numere_array": this.contacteCtrl,
    "client_state": this.clientStateCtrl
    // "numar_nou": this.numarNouCtrl
  });

  mergedProprietari: string[] = [];
  mergedProprietariNames: string[] = [];

  clientStates: string[] = [];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private menuCtrl: MenuController,
    private _toastController: ToastController,
    private _authService: AuthService,
    private _interface: InterfaceService
  ) {

  }

  ngOnInit(): void {
    // figure out state of the component
    if(this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }

    const proprietarId = this._activatedRoute.snapshot.paramMap.get('proprietar_id');
    if (proprietarId) {
      this.getProprietar(proprietarId);
      this.contacteCtrl.enable();
    } else {
      this.form.enable();
      this.formEnabled = true;
    }

    const defaults = this._authService.getDefaults();
    this.clientStates = defaults.clientStates;

    this._authService.subscribeIsLoggedIn().subscribe(state => {
      this.isSuperAdmin = !!state?.data?.superAdmin;
    });

    this.numeProprietarCtrl.valueChanges.subscribe(value => {
      if(!value) return;
      this.numeProprietarCtrl.setValue(value.toUpperCase(), { emitEvent: false });
    });

    // http://localhost:8100/tabs/tab4/proprietari-item/fd13ab89-3236-49bd-ba65-e66a5a1ff47a(secondary:proprietari-create-list)?uuids=d9ef4206-851e-406c-9601-fccdc866984a&proprietari_names=FERARU%20STEFAN
    
    const queryParamsSubscription = this._activatedRoute.queryParams.subscribe(params => {
      const firebaseUID = params['firebase_uid'];
      if (params['uuids']) {
        // this.mergedProprietari = ['d9ef4206-851e-406c-9601-fccdc866984a']; //Array.isArray(params['uuids']) ? params['uuids'] : [params['uuids']];
        // this.mergedProprietariNames = [decodeURIComponent('FERARU%20STEFAN')]; //Array.isArray(params['proprietari_names']) ? params['proprietari_names'] : [params['proprietari_names']];
        
        // now mark the new devize and piese ?? not necessary now, see if at least its' refreshed

        this.mergedProprietari = Array.isArray(params['uuids']) ? params['uuids'] : [params['uuids']];
        this.mergedProprietariNames = Array.isArray(params['proprietari_names']) ? params['proprietari_names'] : [params['proprietari_names']];
      }

      if (firebaseUID) {
        // Do something with firebaseUID
        this.form.enable();
        this.formEnabled = true;

        this.defaultItem.firebase_uid = firebaseUID;
        this.curre = firebaseUID;
        
        this.form.setValue({
          "nume_proprietar":this.defaultItem.nume_proprietar,
          "firebase_uid": this.defaultItem.firebase_uid,
          "numere_array": this.defaultItem.numere_array,    
          "client_state": this.defaultItem.client_state || 'INDIVIDUAL',    
          // "numar_nou": null
        });
        this.numar_nou = null;

        // Remove the firebase_uid query parameter
        this._interface.removeQueryParams(['firebase_uid']);
      }

      const prefill = params['prefill'];
      if (prefill) {
        // Do something with firebaseUID
        this.form.enable();
        this.formEnabled = true;

        this.defaultItem.nume_proprietar = prefill;
        // this.curre = firebaseUID;
        
        this.form.setValue({
          "nume_proprietar":this.defaultItem.nume_proprietar,
          "firebase_uid": this.defaultItem.firebase_uid,
          "numere_array": this.defaultItem.numere_array || [],
          "client_state": this.defaultItem.client_state || 'INDIVIDUAL', 
          // "numar_nou": null
        });
        this.numar_nou = null;

        this._interface.removeQueryParams(['prefill']);
      }

      const masina_id = params['masina_id'];
      if(masina_id) {
      }
    });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  // // todo advanced: this shit with phone numbers
  getProprietar(proprietarId: any): void {
    this.form.disable();

    this._http.get(`${this.apiUrl}/proprietari/${proprietarId}`).subscribe((proprietar: any) => {
      // const inputString = proprietar.numar_telefon;
      // const commaCount = (inputString.match(/,/g) || []).length;
      const results: any[] = proprietar.numar_telefon ? proprietar.numar_telefon.split('#') : ''; // this.extractPhoneNumbersAndNames(inputString);
      this.defaultItem.numere_array = results.length ? results : proprietar.numar_telefon ? proprietar.numar_telefon : [];
      this.defaultItem.numere_array = this.defaultItem.numere_array.filter(n => n !== "");
      this.expanded_array = new Array(this.defaultItem.numere_array.length).fill(false);
      // this.defaultItem = proprietar;
      this.defaultItem = Object.assign({}, this.defaultItem, proprietar);
      // this.defaultItem.numere_array = results.length ? results : proprietar.numar_telefon ? proprietar.numar_telefon.split('#') : null;


      // this.contacteCtrl.setValue((<any>this.defaultItem.numere));
      // if (this.defaultItem.numere && this.defaultItem.numere[0] === '') {
      //   this.defaultItem.numere.shift();
      // }
      // this.defaultItem.numere = newNumere;
      // this.defaultItem.numar_nou = null;
      // this.form.setValue(Object.assign({},
      //   this.form.value,
      //   {
      //     'numere': this.defaultItem.numere,
      //     'numar_nou': null
      //   }
      // ));
      // console.log('avem numere array', this.defaultItem.numere_array, proprietar.numar_telefon, proprietar.numar_telefon.split('#'));

      this.richTextContent = this.defaultItem?.observatii;

      this.form.setValue({
        "nume_proprietar": proprietar['nume_proprietar'],
        "firebase_uid": this.curre || proprietar['firebase_uid'] || this.defaultItem.firebase_uid || null,
        "numere_array": this.defaultItem.numere_array,
        "client_state": this.defaultItem.client_state || 'INDIVIDUAL', 
        // "numar_nou": null
      });
      console.log('set form', {
        "nume_proprietar": proprietar['nume_proprietar'],
        "firebase_uid": this.curre || proprietar['firebase_uid'] || this.defaultItem.firebase_uid || null,
        "numere_array": this.defaultItem.numere_array,
        "client_state": this.defaultItem.client_state || 'INDIVIDUAL', 
        // "numar_nou": null
      })
      this.numar_nou = null;
      
      // set the new result to the form
      // if(commaCount > 100) {
      //   this.defaultItem.numar_telefon = results.join('#');
      //   this.updateProprietar();
      // }

    });
  }

  getClientState(state: string | undefined): string {
    switch (state) {
      case 'INDIVIDUAL':
        return 'Persoana fizica'
      case 'BUSINESS':
        return 'Persoana juridica'
      case 'EMPLOYEE':
        return 'Angajat'
      default:
        return 'Persoana fizica'
    }
  }

  // extractPhoneNumbersAndNames(inputString: string): any[] {
  //   // Remove all characters except digits and letters
  //   const cleanedString = inputString.replace(/[^a-zA-Z0-9]/g, '');

  //   // Split the string at points where letters start, assuming it might be a name following a phone number
  //   const potentialNumbersAndNames = cleanedString.split(/(?=[a-zA-Z])/);

  //   const results: any[] = [];

  //   potentialNumbersAndNames.forEach(segment => {
  //       // Separate numbers from names by identifying where numbers end and letters start
  //       const match = segment.match(/^(\d+)([a-zA-Z]+)?$/);
  //       if (match) {
  //           // Assuming phone numbers can be 10 or 11 digits long
  //           const number = match[1];
  //           const name = match[2] ? '-' + match[2] : '';
  //           // Further split the number into 10 or 11 digit segments if it's longer than expected for a phone number
  //           let index = 0;
  //           while (index < number.length) {
  //               const chunkLength = (number.length - index > 11 && number[index] === '1') ? 11 : 10;
  //               if (index + chunkLength <= number.length) {
  //                   results.push(number.substring(index, index + chunkLength) + name);
  //               }
  //               index += chunkLength;
  //           }
  //       }
  //   });

  //   return results;
  // }

  adaugaNumar(): void {
    // const numar_nou = this.form.value.numar_nou;
    const newNumere = this.form.value.numere_array;
    newNumere.unshift(this.numar_nou);
    this.defaultItem.numere_array = newNumere;
    this.defaultItem.numar_nou = null;
    this.form.setValue(Object.assign({},
      // this.defaultItem,
      this.form.value,
      {
        'numere_array': newNumere,
        // 'numar_nou': null
      }
    ));
    this.numar_nou = null;
  }

  deleteNumber(ev: string): void {
    if(!this.defaultItem.numere_array || !this.defaultItem.numere_array.length) return;
    let index = this.defaultItem.numere_array.indexOf(ev);
    if (index !== -1) {
      this.defaultItem.numere_array.splice(index, 1);
      this.form.setValue({
        "nume_proprietar":this.defaultItem.nume_proprietar,
        "firebase_uid": this.defaultItem.firebase_uid,
        "numere_array": this.defaultItem.numere_array,
        "client_state": this.defaultItem.client_state || 'INDIVIDUAL', 
        // "numar_nou": null
      });
      this.numar_nou = null;
    }
  }
  
  copyNumber(ev: any): void {
    this._interface.copyLink(ev);
  }

  edit(): void {
    if (!this.formEnabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.formEnabled = !this.formEnabled;
    this.contacteCtrl.enable();
  }

  // save to db
  saveProprietar(): void {
    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    body.observatii = this.richTextContent;
    body.numar_telefon = body.numere_array.join('#'); // actual db field
    delete body.numar_nou;
    delete body.numere_array;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this._http.post(`${this.apiUrl}/proprietari`, body, { headers: headers }).subscribe((response: any) => {
      this._interface.presentToast('Proprietarul a fost adaugat in baza de date', 'success');
      
      if(this._interface.isSecondaryOutletActive()) {
        this._router.navigate([{outlets: { secondary: null}}], {
          relativeTo: this._activatedRoute,
          queryParams: { proprietar_id: response.proprietar_id },
          queryParamsHandling: 'merge'
        });
      } else {
        this._router.navigate(['tabs', 'tab4', 'proprietari-item', response.proprietar_id])
      }
    }, error => {
      console.error('There was an error:', error);
    });
  }

  updateProprietar(): void {
    const body: any = Object.assign({}, this.defaultItem, this.form.value)
    body.observatii = this.richTextContent;
    body.numar_telefon = body.numere_array.join('#');
    delete body.numar_nou;
    delete body.numere_array;


    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this._http.put(`${this.apiUrl}/proprietari/${this.defaultItem.proprietar_id}`, body, { headers: headers }).subscribe(response => {
      this.edit();
      this.getProprietar(this.defaultItem.proprietar_id);
    }, error => {
      console.error('There was an error: on edit', error);
    });
  }

  deleteProprietar(): void {
    const confirmation = confirm("Doriti sa eliminati proprietarul?");
    if (!confirmation) {
      return;
    }

    this._http.delete(`${this.apiUrl}/proprietari/${this.defaultItem.proprietar_id}`).subscribe(proprietar => {
      this._router.navigate(['tabs/tab4']);
    });
  }

  addFirebaseUID() {
    this._interface.addFirebaseUID();
  }

  async creeazaMasina(): Promise<void> {
    this._interface.masinaCreate(undefined, this.defaultItem.proprietar_id);
  }

  async adaugaDinListaMasini(): Promise<void> {
    this._interface.masinaList();
  }

  createDeviz(): void {
    this._router.navigate(['/tabs/tab1/deviz-create'], { queryParams: { proprietar_id: this.defaultItem.proprietar_id } })
  }

  startMerge(): void {
    // when receiving merge id, do something, or receive query with merged items. or dunno trigger a modal with changes.. give possibility to undo
    this._interface.proprietarList(undefined, this.defaultItem.proprietar_id);
  }
}