<div class="page">
  <div class="pageContent">

    <form class="flex flex-row justify-center items-center mb-2 mt-6 w-full" [formGroup]="form" (ngSubmit)="getOperations()">
      <mat-form-field [floatLabel]="'auto'" class="w-full old_w-10/12 _pr-4 mx-2" [ngClass]="{'w-8/12':  viewState === 'DIALOG'}">
        <input type="text" placeholder="Cauta nomenclator" matInput [matAutocomplete]="auto" [formControl]="operationNameCtrl">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="displayFirst($event)" [displayWith]="displayOperationFn">
            <mat-option *ngFor="let operation of operationsObservable | async" [value]="operation">
            {{ operation.operation_name | json }}
            </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      
      <button mat-stroked-button type="button" (click)="addOperatiune()" *ngIf="viewState === 'DIALOG'" class="w-2/12 flex flex-row justify-center items-center _mb-5 h-14">
        <div class="flex flex-row justify-center items-center">
          <ion-icon name="add-circle-outline" class="text-2xl"></ion-icon>
          <span class="hidden md:block ml-2 mr-1">Creeaza</span>
        </div>
      </button>

      <button class="block" type="submit" *ngIf="viewState === 'PRIMARY_ROUTE'"><ion-icon name="search-outline" class="text-2xl pl-3"></ion-icon></button>
      <button class="block" type="button" (click)="clear()" *ngIf="viewState === 'PRIMARY_ROUTE'"><ion-icon name="close-outline" class="text-2xl pl-3"></ion-icon></button>

    </form>
    
    <div class="component-nomenclator-list-view grid gap-4 w-full mt-6" [ngClass]="{'grid-cols-1': viewState === 'DIALOG', 'grid-cols-1 lg:grid-cols-2': viewState === 'PRIMARY_ROUTE'}">
      <mat-card appearance="outlined"
                *ngFor="let item of operations" 
                (click)="selectItem(item)"
                class="cursor-pointer pb-4"
                id="{{item.operation_id}}"
                style="transition: border-color 0.5s ease;">
        <mat-card-header class="px-2">
          <!-- <div mat-card-avatar class="_example-header-image flex flex-row justify-center items-center">
            <ion-icon name="person" class="text-2xl relative mt-3"></ion-icon>
          </div> -->
          <mat-card-title class="leading-none flex flex-col justify-center items-center h-full pt-1 pb-1">
            <span class="text-lg relative _-bottom-2 leading-5 mt-3">{{ item.operation_name }}</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="false"></mat-card-content>

        <mat-card-footer class="px-2 pt-4 flex flex-row justify-between items-center">
          <div class="flex flex-row  justify-center items-center">
            <button mat-button *ngIf="viewState === 'DIALOG'" class="w-2" style="width: 20px; padding-right: 21px; min-width: 10px;">
              <ion-icon name="arrow-back-circle-outline" class="text-2xl relative mr-1" style="margin-top: 6px;"></ion-icon>
            </button>
            <p class="block">Pret fara TVA: <strong>{{ item.pret_unitar_fara_tva_lei | number }} RON</strong></p>
          </div>
          <p class="block">Norma: <strong>{{ item.norma_de_timp }}</strong></p>
        </mat-card-footer>

      </mat-card>

    </div>


  </div>
</div>

