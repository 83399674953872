
<div class="p-4 w-full h-full">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="grid grid-cols-1 _lg:grid-cols-3 gap-1 w-full p-2">
  
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
      <mat-form-field appearance="fill" class="w-full col-span-1 lg:col-span-2">
        <mat-label>Cauta utilizator</mat-label>
        <input matInput type="text" formControlName="search">
      </mat-form-field>
  
      <!-- <mat-form-field appearance="fill" class="w-full">
        <mat-label>Display Name</mat-label>
        <input matInput type="text" formControlName="display_name">
      </mat-form-field>
    
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email">
      </mat-form-field> -->
    </div>
  
    <!-- [ngClass]="{'grid-cols-1 lg:grid-cols-2': !standalone, 'grid-cols-1': standalone}" -->
    <div class="grid gap-2 w-full grid-cols-1 lg:grid-cols-1">
      <!-- <button mat-raised-button type="submit" class="my-4">Salveaza schimbari</button> -->
      <button mat-raised-button type="button" class="my-4" (click)="search()">Cauta</button>
    </div>
  </form> 
  
  <div class="grid grid-cols-1 _lg:grid-cols-2 gap-4 w-full" [ngClass]="{'lg:grid-cols-2': true}">
    <mat-card *ngFor="let item of users" 
              id="prefix-{{item.uid}}"
              class="cursor-pointer"
              (click)="populate(item)"
              >
  
  
        <mat-card-header>
          <div mat-card-avatar class="example-header-image" [ngStyle]="{
            'background-image': 'url(' + item.photo_url + ')',
            'background-size': 'cover'
          }">
            <!-- <ion-icon name="person"></ion-icon> -->
          </div>
          <mat-card-title>{{ item.display_name }}</mat-card-title>
          <mat-card-subtitle>{{ item.email }}</mat-card-subtitle>
          <mat-card-subtitle>{{ item.provider_id }}</mat-card-subtitle>
          <mat-card-subtitle>{{ item.uid }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-footer>
          <button mat-button (click)="delete(item.uid)">Elimina utilizator</button>
        </mat-card-footer>
      </mat-card>
  </div>
</div>


<mat-divider class="my-4 mt-16 w-full"></mat-divider>
