import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { MenuController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

@Component({
  selector: 'proprietari-firebase',
  templateUrl: `./proprietari-firebase.component.html`,
  styleUrls: ['./proprietari-firebase.component.scss']
})
export class ProprietariFirebase {
  // @Output() itemAdded = new EventEmitter<any>();
  // @Input() isView = true;
  // standalone: boolean = false;

  // apiUrl: string = 'http://127.0.0.1:5000';
  apiUrl: string = environment.apiUrl;
  users: any[] = [];
  // standalone: boolean = false;

  // formEnabled: boolean = false;
  // defaultItem: any = {
  //   "nume_proprietar": null,
  //   "firebase_uid": null
  // }

  // form
  displayNameCtrl = new FormControl();
  emailCtrl = new FormControl();
  uidCtrl = new FormControl();
  searchCtrl = new FormControl();
  form: FormGroup = this.fb.group({
    "display_name": this.displayNameCtrl,
    "email": this.emailCtrl,
    "uid": this.uidCtrl,
    "search": this.searchCtrl
  });


  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private menuCtrl: MenuController,
    private _interface: InterfaceService
  ) {

  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this._http.get(this.apiUrl + '/firebase/users').subscribe((users: any) => {
      this.users = users;
    });
  }

  populate(user: any): void {
    if (this._interface.isSecondaryOutletActive()) {
      this._router.navigate([
        { outlets: { secondary: null } }], {
        relativeTo: this._activatedRoute,
        queryParams: { firebase_uid: user.uid },
        queryParamsHandling: 'merge'  // Preserve existing query params.
      });
      this._interface.clearMenu();
    } else {
      this.form.setValue({
        'display_name': user.display_name,
        'email': user.email,
        'uid': user.uid,
        'search': null
      });
    }
  }

  delete(firebase_uid: string): void {
    this._http.delete(this.apiUrl + '/firebase/users/' + firebase_uid).subscribe((user: any) => {
      this.getUsers();
      this.form.setValue({
        'display_name': null,
        'email': null,
        'uid': null,
        'search': null
      })
    });
  }

  onSubmit(): void {
    const updated = this.form.getRawValue();
    const uid = updated.uid;
    delete updated.uid;
    delete updated.search;

    this._http.put(this.apiUrl + '/firebase/users/' + uid, updated).subscribe((user: any) => {
      this.getUsers();
    });

    this.getUsers();
  }

  search(): void {
    const term = this.searchCtrl.getRawValue();

    let params = new HttpParams()
      .set('search', term)

    this._http.get(this.apiUrl + '/firebase/users', { params }).subscribe((users: any) => {
      this.users = users;
    });

  }
}