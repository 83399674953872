<div class="page">
  <div class="pageContent">
    <div class="rounded-lg bg-slate-800 border border-slate-500 p-3 flex flex-col w-full" *ngIf="merge_id && mergingCar">
      <span>Alege masinile cu care doresti sa unesti {{ mergingCar.nr_masina }} - {{ mergingCar.serie_sasiu }}</span>

       <div class="flex flex-row">
         <mat-checkbox [checked]="mergeOptions.devize" (change)="mergeOptions.devize = $event.checked">
           Devize
         </mat-checkbox>
         <mat-checkbox [checked]="mergeOptions.proprietari" (change)="mergeOptions.proprietari = $event.checked">
           Proprietari
         </mat-checkbox>
         <mat-checkbox [checked]="mergeOptions.lucrari" (change)="mergeOptions.lucrari = $event.checked">
           Piese
         </mat-checkbox>
       </div>

       <button mat-stroked-button (click)="merge()">Uneste</button>
    </div>
    <masina-list-filters [filters]="filters" (triggerSearch)="setFilters($event)" class="w-full mt-2"></masina-list-filters>
    <mat-form-field class="w-full mt-6" *ngIf="masini.length">
      <mat-label>Coloane</mat-label>
      <mat-select [formControl]="columns" multiple>
        <mat-option *ngFor="let column of defaultTableColumns" [value]="column">{{getColumnDisplayName(column)}}</mat-option>
      </mat-select>
    </mat-form-field>

    <span class="text-2xl" *ngIf="!masini.length">Nu exista masini</span>
      <table mat-table [dataSource]="masini" class="mat-elevation-z8 masini-table" *ngIf="masini.length">

      <!-- Serie Sasiu Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let item" class="cursor-pointer whitespace-nowrap overflow-hidden" > 
          <mat-checkbox (change)="addCarForMerge(item.masina_id)">
            <!-- Devize [checked]="mergeOptions.devize"-->
          </mat-checkbox>
        </td>
      </ng-container>
    
        <!-- Serie Sasiu Column -->
        <ng-container matColumnDef="serie_sasiu">
          <th mat-header-cell *matHeaderCellDef> Serie Șasiu </th>
          <td mat-cell *matCellDef="let item" (click)="copyLink(item.serie_sasiu)" class="cursor-pointer whitespace-nowrap overflow-hidden" > 
            <span class="text-ellipsis" [ngClass]="{'highlight-class': isMatch(item, 'serie_sasiu')}">{{ item.serie_sasiu }}</span>
          </td>
        </ng-container>

        <!-- Numar Masina Column -->
        <ng-container matColumnDef="nr_masina">
          <th mat-header-cell *matHeaderCellDef> Număr Mașină </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis" [ngClass]="{'highlight-class': isMatch(item, 'nr_masina')}">{{ item.nr_masina }} </span>
          </td>
        </ng-container>

        <!-- Marca Masina Column -->
        <ng-container matColumnDef="marca_masina">
          <th mat-header-cell *matHeaderCellDef> Marcă </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.marca_masina }} </span>
          </td>
        </ng-container>
    
        <!-- Model Masina Column -->
        <ng-container matColumnDef="model_masina">
          <th mat-header-cell *matHeaderCellDef> Model </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.model_masina }} </span>
          </td>
        </ng-container>
    
        <!-- Tip Motor Column -->
        <ng-container matColumnDef="tip_motor_masina">
          <th mat-header-cell *matHeaderCellDef> Tip Motor </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.tip_motor_masina }} </span>
          </td>
        </ng-container>
    
        <!-- An Fabricatie Column -->
        <ng-container matColumnDef="an_fab_masina">
          <th mat-header-cell *matHeaderCellDef> An Fabricație </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.an_fab_masina }} </span>
          </td>
        </ng-container>
    
        <!-- Capacitate Cilindrica Column -->
        <ng-container matColumnDef="cap_cil_masina">
          <th mat-header-cell *matHeaderCellDef> Capacitate Cilindrică </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.cap_cil_masina }} </span>
          </td>
        </ng-container>
    
        <!-- Putere Motor Column -->
        <ng-container matColumnDef="kw_masina">
          <th mat-header-cell *matHeaderCellDef> Putere Motor (kW) </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.kw_masina }} </span>
          </td>
        </ng-container>
    
        <!-- Masina ID Column -->
        <ng-container matColumnDef="masina_id">
          <th mat-header-cell *matHeaderCellDef> ID Mașină </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.masina_id }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let item" (click)="selectItem(item)" class="cursor-pointer text-clip"> 
            <button mat-button>
              <ion-icon *ngIf="viewState === 'PRIMARY_ROUTE' || merge_id" name="enter-outline" class="text-2xl relative" style="top: 3px; right: 1px;"></ion-icon>
              <ion-icon *ngIf="viewState === 'SECONDARY_ROUTE' && !merge_id" name="add-circle-outline" class="text-2xl relative" style="top: 3px; right: 1px;"></ion-icon>
            </button>
          </td>
        </ng-container>
        <!-- <ion-icon name="enter-outline"></ion-icon> -->
    
        <tr mat-header-row *matHeaderRowDef="columns.value"></tr>
        <tr mat-row *matRowDef="let row; columns: columns.value"></tr>
        
      </table>    

      <mat-paginator [length]="totalItems" 
               [pageSize]="filters.per_page" 
               [pageIndex]="filters.page - 1" 
               (page)="pageChange($event)" class="w-full">
      </mat-paginator>

  </div>
</div>

