

<!-- <div class="page">
  <div class="pageContent"> -->

    <!-- (ngSubmit)="getDevize()" -->
    <form [formGroup]="searchForm" class="flex flex-row flex-wrap justify-center items-center w-full mt-4 _md:p-0"
      [ngClass]="{'p-5': isModal}" (ngSubmit)="search()">

      <!-- TODO: cautare dupa serie 38f2403b-5731-49ef-9413-65a3f2448a80 -->
      <!-- [color]="subtask.color" -->
      <!-- (ngModelChange)="updateAllComplete()" -->
      <!--  -->
      <mat-checkbox [formControl]="closed" class="mr-3 hidden md:block">
        Inchis
      </mat-checkbox>

      <mat-form-field class="w-full _mt-2" [ngClass]="{
        'md:w-1/2': !this.isModal
      }">
        <mat-label>Nume Proprietar / Numar masina / Numar deviz / Serie</mat-label>
        <input matInput formControlName="nume_proprietar" #numeProprietarInput>
        <!-- <mat-hint>Nume Proprietar / Numar masina</mat-hint> -->
      </mat-form-field>

      <div class="flex flex-row items-center justify-center">
        <mat-checkbox [formControl]="closed" class="mr-3 block md:hidden">
          Inchis
        </mat-checkbox>
        <button class="block" type="submit" *ngIf="!isModal"><ion-icon name="search-outline" class="text-2xl pt-1 pl-3"></ion-icon></button>
        <button class="block" type="button" (click)="clear()" *ngIf="!isModal"><ion-icon name="close-outline" class="text-2xl pt-1 pl-3"></ion-icon></button>
        <button class="block" type="button" (click)="openFilters()" *ngIf="!isModal"><ion-icon name="filter-circle-outline" class="text-2xl pt-1 pl-3"></ion-icon></button>
        <button class="block" type="button" (click)="openTemplates()" *ngIf="!isModal">
          <ion-icon name="bookmarks" class="text-xl pt-1 pl-3"></ion-icon>
        </button>
        <!-- <button mat-stroked-button>
          <div class="flex flex-row justify-between w-full">
            <ion-icon name="add-circle-outline" class="highlight-class text-2xl"></ion-icon><span class="block"> Creeaza deviz</span>
          </div>
        </button> -->
      </div>

      <!-- <div class="w-full flex flex-col md:flex-row items-center justify-center mt-2" [ngClass]="{   
        'md:w-1/2 md:pl-4': !this.isModal
      }">
        <mat-form-field class="w-full">
          <mat-label>Cauta</mat-label>
          <input matInput formControlName="numar_deviz">
          <mat-hint>Numar deviz</mat-hint>
        </mat-form-field>
      </div> -->


      <mat-form-field class="w-full _md:w-4/12 _md:px-2 mt-2" [ngClass]="{
        'hidden _md:block': !this.isModal,
        'block': this.isModal
      }">
        <mat-label>Masina ID</mat-label>
        <input matInput formControlName="masina_id">
        <mat-hint>ID-ul masinii din baza de date</mat-hint>
      </mat-form-field>


      <mat-form-field class="w-full  _md:w-4/12 mt-2" [ngClass]="{
        'hidden _md:block': !this.isModal,
        'block': this.isModal
      }">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start_date" placeholder="Start date">
          <input matEndDate formControlName="end_date" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    
        <mat-error *ngIf="searchForm.controls.start_date.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="searchForm.controls.end_date.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

      <div class="flex flex-row justify-center items-center pt-6 w-full">
        <button class="mt-3 ml-4" mat-button (click)="onNoClick()" *ngIf="isModal">Cancel</button>
        <button class="mt-3" mat-flat-button (click)="search()" [mat-dialog-close]="searchForm.value" cdkFocusInitial *ngIf="isModal">Cauta</button>
      </div>
      <!-- <div class="flex flex-row justify-center items-center w-3/12 md:w-full md:mt-4 md:mb-1">
        <button mat-stroked-button type="button" (click)="clear()" class="mx-1">Clear</button>
        <button mat-raised-button color="primary" type="submit" class="mx-1">Search</button>
        <button mat-stroked-button type="button" (click)="clear()" class="mx-1">Filters</button>
      </div> -->
    </form>
  <!-- </div>
</div> -->

