import { Component, HostBinding, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ShareDataItem, ShareExtensionDataPlugin, ShareExtensionDataPluginService } from './share.service';
import { HttpClient } from '@angular/common/http';
// import { AuthService } from './auth.service';

@Component({
  selector: 'forbidden-s1',
  templateUrl: 's1.component.html',
  styleUrls: ['s1.component.scss'],
})
export class S1Component {
  @HostBinding('class.h-full') isSpecial = true;
  public apiUrl: string = 'http://localhost:3000';

  selectedTabIndex = 0;

  // other feature
  data: ShareDataItem[] = [];

  constructor(
    private _router: Router,
    private menuCtrl: MenuController,
    private shareService: ShareExtensionDataPluginService,
    private _httpClient: HttpClient,
    private _activatedRoute: ActivatedRoute
  ) {
    
    
    this._activatedRoute.queryParams.subscribe(qp => {
      if(qp['section'] && (qp['section'] !== this.selectedTabIndex)) {
        this.selectedTabIndex = Number(qp['section']);
      }
    });
  }

  /* 
 
    PULL / TAG
 
  */



  onChangeTab(tabIndex: any): void {
    this.selectedTabIndex = tabIndex;

    const queryParams: NavigationExtras = {
      queryParams: { section: tabIndex }
    };
    this._router.navigate([], queryParams);
  }


  manageTag(tag: any): void {
  }

  /* 
  
    TAG MANAGEMENT

  */


  // UTILS
  goTo(routeArray: string[]): void {
    this._router.navigate(routeArray);
  }






  // OTHER FEATURE
  async read() {
    let data = await this.shareService.read();
    this.data = data ?? [];
  }

  async clear() {
    await this.shareService.clear();
    this.data = [];
  }

  async openAlert() {
    // await this.capMethodsPluginService.openAlert(this.alertText);
  }

  onLogin(): void {
    // this._authService.login(this.email, this.password);
  }

  onRegister(): void {
    // this._authService.register(this.email, this.password, this.admin_name, this.role);
  }

  logout(): void {
    // this._authService.logout().then();
  }
}
