import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListenService } from '../../providers/listen.service';
import { ModalController } from '@ionic/angular';
import { CreateManageComponent } from '../create-manage/create-manage.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'listen-component',
  templateUrl: './Listen.component.html',
  styleUrls: ['./listen.component.scss']
})
export class ListenComponent {
  public apiUrl: string = 'http://localhost:3000';

  tagsToListen: any[] = [];
  songsToListen: any[] = [];
  tableSelected: any[] = [];

  per: number = 50;
  page: number = 1;
  filtersForm: FormGroup = this.fb.group({
    created_at: [''],
    track_artists_original: [''],
    track_album_original: [''],
    release_date: [''],
    release_country: ['']
  })

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _httpClient: HttpClient,
    private _listenService: ListenService,
    private fb: FormBuilder,
    private _modalCtrl: ModalController
  ) {
    this._listenService.getTagsToListen().subscribe(tagsToListen => {
      if(tagsToListen) {
        this.getTaggedSongs(tagsToListen);
      }
    });

    this.getTaggedSongs();
  }

  ngOnInit(): void {
    // this.filtersForm = this.fb.group({
    //   created_at: [''],
    //   track_artists_original: [''],
    //   track_album_original: [''],
    //   release_date: [''],
    //   release_country: ['']
    // });
  }

  getTaggedSongs(
    tagsToListen: any[] = [],
    next?: number
  ): void {
    this.tagsToListen = tagsToListen.length ? tagsToListen : this.tagsToListen;

    const whitelistedTags = this.tagsToListen.filter(tag => !tag?.blacklist);
    const blacklistedTags = this.tagsToListen.filter(tag => tag?.blacklist);

    console.log('whitelisted', whitelistedTags);
    console.log('blacklisted', blacklistedTags);
  
    const whitelist_tag_ids = whitelistedTags.map(tag => tag.id);
    const blacklist_tag_ids = blacklistedTags.map(tag => tag.id);

    const filters = this.filtersForm.value;

    if (filters.created_at) {
      filters.created_at = this.formatDate(filters.created_at);
    }
    if (filters.release_date) {
      filters.release_date = this.formatDate(filters.release_date);
    }
    
    const paramsObject = {
      whitelistedTags: whitelist_tag_ids,
      blacklistedTags: blacklist_tag_ids,
      filters: filters,
      per: this.per,
      page: next || this.page
    };
  
    this._httpClient.post(this.apiUrl + '/get_tagged', paramsObject).subscribe((data: any) => {
      this.songsToListen = data;
      console.log('got songs to listen', this.songsToListen);
    });
  }

  formatDate(date: Date): string {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();
  
    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  }

  clearFilters(): void {
    this.filtersForm.reset();
    this.getTaggedSongs(); // Optionally re-fetch songs without filters
  }

  sendToSpotify(): void {
    const playlist_name = this.tagsToListen.map(tag => `#${tag.tag}`).join('');

    const songsToListen = this.tableSelected.length ? this.tableSelected : this.songsToListen;
    console.log('sending songs to listen', songsToListen);
    return;
    const song_ids = songsToListen.map((song: any) => {
      //todo: if no song id for this specific service, open pop-up to look up
      if(!song?.ids?.spotify?.song) return;
      return `spotify:track:${song.ids.spotify.song}`
    }).filter(song_id => !!song_id);

    const payload = {
      playlist_name: playlist_name,
      song_ids: song_ids 
    }

    this._httpClient.post(this.apiUrl + '/spotify/playlist', payload).subscribe(data => {
      // playlist should be in spotify
    });
  }

  setSelected(selected: any[]): void {
    this.tableSelected = selected;
  }

  async openTags(): Promise<void> {
    const modal = await this._modalCtrl.create({
      component: CreateManageComponent,
      componentProps: {
        // 'songId': song_id,
        'selectedTags': this.tagsToListen // aaaaa should be ones to blacklist too
        // tagsNotToListen
      },

      // cssClass: 'rotate-that-shit',
      // showBackdrop: false,
      breakpoints: [0.75],
      // initialBreakpoint: 1,
      // canDismiss: false,
      // handle: false,
      backdropDismiss: false,
      // backdropBreakpoint: 1
    });

    modal.present();

    const { data } = await modal.onWillDismiss();
    console.log('dismissed with tags', data);
    // if(data && data.length) {
      this.tagsToListen = data || [];
    // }
  }
}