<div class="w-full h-full">
    <!-- do: currently playing -->
    <div class="flex flex-row w-full text-center">
        <span>literal place for all filtering. search by name. pop-up with tags then show active ones with true false etc</span>
    </div>

    <div class="flex flex-row w-full">
        <button mat-flat-button color="accent" (click)="sendToSpotify()">
            Send to spotify
        </button>

        <button mat-stroked-button color="accent" (click)="openTags()">
            Open Tags
        </button>

        <button mat-flat-button color="accent" (click)="getTaggedSongs()">
            Set Filters
        </button>

        <button mat-stroked-button color="warn" type="button" (click)="clearFilters()">
            Clear Filters
        </button>
    </div>

    <div class="flex flex-row w-full">
        <form [formGroup]="filtersForm" (ngSubmit)="getTaggedSongs()">
            <mat-form-field appearance="fill">
                <mat-label>Created At</mat-label>
                <input matInput [matDatepicker]="createdAtPicker" formControlName="created_at">
                <mat-datepicker-toggle matSuffix [for]="createdAtPicker"></mat-datepicker-toggle>
                <mat-datepicker #createdAtPicker></mat-datepicker>
              </mat-form-field>
          
            <mat-form-field appearance="fill">
              <mat-label>Track Artists Original</mat-label>
              <input matInput formControlName="track_artists_original">
            </mat-form-field>
          
            <mat-form-field appearance="fill">
              <mat-label>Track Album Original</mat-label>
              <input matInput formControlName="track_album_original">
            </mat-form-field>
          
            <mat-form-field appearance="fill">
                <mat-label>Release Date</mat-label>
                <input matInput [matDatepicker]="releaseDatePicker" formControlName="release_date">
                <mat-datepicker-toggle matSuffix [for]="releaseDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #releaseDatePicker></mat-datepicker>
            </mat-form-field>
          
            <mat-form-field appearance="fill">
              <mat-label>Release Country</mat-label>
              <input matInput formControlName="release_country">
            </mat-form-field>
        </form>
    </div>

    <!-- do: make filter on date create etc, filtering on the songs table if possible -->
    <song-list-component [songs]="songsToListen" (sendSelected)="setSelected($event)"></song-list-component>
</div>