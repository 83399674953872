<div class="page">
  <div class="pageContent">

    <piese-list-filters *ngIf="(viewState !== 'DIALOG' || true)" [filters]="filters" (triggerSearch)="setFilters($event)" class="w-full mt-2"></piese-list-filters>

    <span class="text-2xl" *ngIf="!lucrari.length">Nu exista piese</span>
    <!-- {{defaultTableColumns}} -->
    <mat-form-field class="w-full" *ngIf="(viewState !== 'DIALOG') && lucrari.length">
      <mat-label>Coloane</mat-label>
      <mat-select [formControl]="columns" multiple>
        <ng-container *ngFor="let coloana of defaultTableColumns">
          <mat-option [value]="coloana" *ngIf="coloana !== 'options'">{{getColumnDisplayName(coloana)}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <div class="_mat-elevation-z8 w-full" *ngIf="lucrari.length">
      <table mat-table [dataSource]="lucrari" class="_mat-elevation-z8" [ngClass]="{'small-screen-class': this.smallScreen || (viewState === 'DIALOG')}">

        <!-- Options Column -->
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let item" (click)="selectItem(item)" class="cursor-pointer"> 
            <button mat-button>
              <ion-icon *ngIf="(viewState !== 'DIALOG')" name="enter-outline" class="text-2xl relative" style="top: 3px; right: 1px;"></ion-icon>
              <ion-icon *ngIf="(viewState === 'DIALOG')" name="arrow-back-circle-outline" class="text-2xl relative" style="top: 3px; right: 1px;"></ion-icon>

            </button>
          </td>
        </ng-container>

        <!-- Lucrare ID Column -->
        <ng-container matColumnDef="lucrare_id">
          <th mat-header-cell *matHeaderCellDef> Lucrare ID </th>
          <td mat-cell *matCellDef="let item" class="pr-3 overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer hover:underline"> 
            {{ item.lucrare_id }} 
          </td>
        </ng-container>
      
        <!-- FK Masina ID Column -->
        <ng-container matColumnDef="fk_masina_id">
          <th mat-header-cell *matHeaderCellDef> Mașină ID </th>
          <td mat-cell *matCellDef="let item" class="pr-3 overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer hover:underline"> 
            {{ item.fk_masina_id }} 
          </td>
        </ng-container>
      
        <!-- Old Excel Index Column -->
        <ng-container matColumnDef="old_excel_index">
          <th mat-header-cell *matHeaderCellDef> Old Excel Index </th>
          <td mat-cell *matCellDef="let item"> 
            {{ item.old_excel_index }} 
          </td>
        </ng-container>
      
        <!-- Denumire Piesa Column -->
        <ng-container matColumnDef="denumire_piesa">
          <th mat-header-cell *matHeaderCellDef> Denumire Piesă </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span *ngIf="(viewState !== 'DIALOG')" class="text-ellipsis">{{ item.denumire_piesa }} </span>
            <a [routerLink]="'/tabs/tab5/piese-item/' + item.lucrare_id" (click)="dialog.closeAll()" *ngIf="(viewState === 'DIALOG')" class="text-ellipsis hover:underline">{{ item.denumire_piesa }} </a>
            <span class="text-ellipsis block supporting-text" *ngIf="(viewState === 'DIALOG')">{{ item.cod_oe_piesa }} </span>
          </td>
        </ng-container>
      
        <!-- Cod OE Piesa Column -->
        <ng-container matColumnDef="cod_oe_piesa">
          <th mat-header-cell *matHeaderCellDef> Cod OE Piesă </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.cod_oe_piesa }} </span>
          </td>
        </ng-container>
      
        <!-- Denumire Producator Column -->
        <ng-container matColumnDef="denumire_producator">
          <th mat-header-cell *matHeaderCellDef> Denumire Producător </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.denumire_producator }} </span>
            <span class="text-ellipsis block supporting-text" *ngIf="(viewState === 'DIALOG')">{{ item.cod_producator }} </span>
          </td>
        </ng-container>
      
        <!-- Cod Producator Column -->
        <ng-container matColumnDef="cod_producator">
          <th mat-header-cell *matHeaderCellDef> Cod Producător </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.cod_producator }} </span>
          </td>
        </ng-container>
      
        <!-- Adaos Column -->
        <ng-container matColumnDef="adaos">
          <th mat-header-cell *matHeaderCellDef> Adaos </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.adaos }} </span>
          </td>
        </ng-container>
      
        <!-- Pret Unitar Fara TVA Lei Column -->
        <ng-container matColumnDef="pret_unitar_fara_tva_lei">
          <th mat-header-cell *matHeaderCellDef> Pret fara TVA </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.pret_unitar_fara_tva_lei || 0 | number:'1.2-2'}} &nbsp; RON </span>
            <span class="text-ellipsis block supporting-text" *ngIf="(viewState === 'DIALOG') && item.adaos">%{{ item.adaos }} </span>
          </td>
        </ng-container>
      
        <!-- Approximate Date Column -->
        <ng-container matColumnDef="approximate_date">
          <th mat-header-cell *matHeaderCellDef> Data Aproximativă </th>
          <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
            <span class="text-ellipsis">{{ item.approximate_date | date }} </span>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="columns.value"></tr>
        <tr mat-row *matRowDef="let row; columns: columns.value;"></tr>
      
      </table>
      <mat-paginator [length]="totalItems" 
               [pageSize]="filters.per_page" 
               [pageIndex]="filters.page - 1" 
               (page)="pageChange($event)" class="w-full">
      </mat-paginator>
    </div>
    
    

  </div>
</div>


