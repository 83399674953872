import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Merchant } from '../deviz/providers/models';
import { InterfaceService } from '../deviz/providers/interface.service';


export interface Defaults {
  tva: number,
  pretNorma: number,
  userPermissions: string[]
  clientStates: string[]
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl: string = environment.apiUrl;
  enablePublicRegistrations: boolean = true;
  merchantId: string | null = null;

  defaultState: any = {
    isLoggedIn: false,
    data: null
  }

  defaults: Defaults = {
    tva: 19,
    pretNorma: 120,
    userPermissions: ['SUPER_ADMIN', 'ADMIN', 'USER', 'READONLY'],
    clientStates: ['INDIVIDUAL', 'BUSINESS', 'EMPLOYEE']
  }

  currentToken: string | null = null;

  $loggedIn = new BehaviorSubject<any>(this.defaultState);

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private _router: Router,
    private _toastController: ToastController,
    private _http: HttpClient,
    private _interface: InterfaceService
  ) {

    this.afAuth.idToken.subscribe(token => {
      this.currentToken = token;
    })

    this.afAuth.authState.subscribe(res => {
      const alwaysLoggedIn = true;
      if (alwaysLoggedIn) {
        setTimeout(() => {
          // inter live
          // this.login('tudosedotstefan+admin2@gmail.com', '1qazxsw2').then();

          // inter demo

        }, 1000);
      }

      if (!res) {
        const state = this.defaultState;
        state.isLoggedIn = false;
        state.data = null;
        this.currentToken = null;
        this.merchantId = null;
        this.$loggedIn.next(state);

        // 
        // TODO: navigate home
        this._router.navigate(['/tabs/home']);
      } else {

        const state = this.defaultState;
        state.isLoggedIn = true;

        if (res.email?.includes('tudosedotstefan')) {
          state.data = {};
          state.data.superAdmin = true;
          state.data.admin_name = 'Stefan Tudose'; // temp, delete
          state.data.role = 'admin'; // temp, delete
        }
        this.$loggedIn.next(state);

        const $subscription = this.getUserRole(res?.uid);
        $subscription.subscribe(role => {
          if (role) {
            this.merchantId = role.merchant_id;
            this.setItem('alon_merchant_id', this.merchantId);
            const testy = this.getItem('alon_merchant_id');
            // state.merchant_id = role.merchant_id;
            state.data = Object.assign({}, state.data, role);

            // todo: need to add to interceptor
            this.$loggedIn.next(state);
          }
        }, error => {
          // firebase error
        })
      }
    });

  }

  getToken(): string | null {
    return this.currentToken;
  }

  getMerchantId(): any {
    return this.merchantId || this.getItem('alon_merchant_id') || null;
  }

  getDefaults(): Defaults {
    return this.defaults;
  }

  getEnablePublicRegistrations(): boolean {
    return this.enablePublicRegistrations;
  }

  async login(email: string, password: string): Promise<any> {
    this.merchantId = null;
    this.currentToken = null;
    this.removeItem('alon_merchant_id');

    this._toastController.dismiss();
    const res: any = {};
    try {
      const res = await this.afAuth.signInWithEmailAndPassword(email, password);
      if (res?.user?.uid) {
        // get role etc and set alon
        return res.user;
      }
    } catch (error) {
      res.error = error;
      this.presentToast('Parola sau emailul sunt incorecte');
    }
    return res;
  }

  async register(email: string, password: string, admin_name: string, role?: string, merchantDetails?: any): Promise<void> {
    if (merchantDetails) {
      this.currentToken = null;
      this.merchantId = null;
      // also! business owner should see just his business users
      // review when adding a new user to the business
      this.removeItem('alon_merchant_id');
    }

    this._toastController.dismiss();
    try {
      const userCredentials = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const user = userCredentials.user;

      // user created
      // todo create merchant
      // review when adding a new user


      if (merchantDetails) {
        const payload: any = {
          name: merchantDetails.business_name,
          cui: merchantDetails.business_cui,
          email: email
        }
        this.createMerchant(payload).subscribe(data => {
          // if current user does not have (ie not logged in), generate one and require merchantDetails
          let merchant_id: any = data.merchant; //this.getMerchantId();   
          // if(!merchant_id && this.enablePublicRegistrations) {
          //   merchant_id = user?.uid;
          // }
          this.setItem('alon_merchant_id', merchant_id);


          // show fields if no merchantId and enablePublicRegistrations is true, otherwise either a merchant is registering another user or no public registrations are enabled

          const collectionResult = this.db.collection('users').doc(user?.uid).set({
            uid: user?.uid,
            admin_name: admin_name || merchantDetails?.business_name,
            email: user?.email,
            role: role ? role : merchantDetails ? 'admin' : 'user', // Default role
            merchant_id: merchant_id,
            business_name: merchantDetails?.business_name,
            business_cui: merchantDetails?.business_cui
          }).then(res => {
          });
        });
      } else {
        const collectionResult = this.db.collection('users').doc(user?.uid).set({
          uid: user?.uid,
          admin_name: admin_name,
          email: user?.email,
          role: role ? role : 'user', // Default role
          merchant_id: this.getMerchantId(),
          // business_name: merchantDetails?.business_name,
          // business_cui: merchantDetails?.business_cui
        }).then(res => {
          //!!!! does registering another user log you into that user???
        });
      }

    } catch (error) {
      // Handle errors
      this.presentToast(JSON.stringify(error));
    }

    this._interface.dismissLoader();
  }

  registerBusiness(merchantDetails: any): void {

    
    this.getUserRole(this.$loggedIn.value?.data?.uid).subscribe(data => {
      const user: any = data;

      const payload: any = {
        name: merchantDetails.business_name,
        cui: merchantDetails.business_cui,
        email: user.email
      }
      this.createMerchant(payload).subscribe(data => {
        // if current user does not have (ie not logged in), generate one and require merchantDetails
        let merchant_id: any = data.merchant; //this.getMerchantId();   
        // if(!merchant_id && this.enablePublicRegistrations) {
        //   merchant_id = user?.uid;
        // }
        this.setItem('alon_merchant_id', merchant_id);
  
  
        // show fields if no merchantId and enablePublicRegistrations is true, otherwise either a merchant is registering another user or no public registrations are enabled
  
        const collectionResult = this.db.collection('users').doc(user?.uid).set({
          uid: user?.uid,
          admin_name: user.admin_name || merchantDetails?.business_name,
          email: user?.email,
          role: user?.role ? user.role : 'admin',

          merchant_id: merchant_id,
          business_name: merchantDetails?.business_name,
          business_cui: merchantDetails?.business_cui
        }).then(res => {
        });
      });
    });
    // return;
  }

  getUserRole(userId?: string): Observable<any> {
    return this.db.collection('users').doc(userId).valueChanges();
  }

  updateUserRole(userId?: string, role?: string): Promise<void> {
    return this.db.collection('users').doc(userId).update({ role });
  }

  async logout(): Promise<void> {
    this.currentToken = null;
    this.merchantId = null;
    this.removeItem('alon_merchant_id');

    try {
      await this.afAuth.signOut();
      // Additional logic after successful logout (e.g., redirecting to login page)
    } catch (error) {
      // Handle errors (e.g., show a notification)
    }
  }

  subscribeIsLoggedIn(): Observable<any> {
    return this.$loggedIn;
  }


  ///

  async presentToast(message: string, position?: 'top' | 'middle' | 'bottom') {
    const toast = await this._toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      color: 'danger',
      cssClass: 'text-xl'
    });

    await toast.present();
  }

  setItem(key: string, value: any): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  }

  getItem<T>(key: string): T | null {
    const serializedValue = localStorage.getItem(key);
    if (!serializedValue || serializedValue !== 'undefined') {
      return null;
    }
    if (serializedValue && serializedValue !== 'undefined') {
      return JSON.parse(serializedValue) as T;
    }

    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }


  // merchants
  createMerchant(merchant: Merchant): Observable<any> {
    return this._http.post(this.apiUrl + '/merchants', merchant);
  }

  updateMerchant(merchant_id: string, merchant: Merchant): Observable<any> {
    return this._http.put(this.apiUrl + '/merchants/' + merchant_id, merchant);
  }

  deleteMerchant(merchant_id: string): Observable<any> {
    return this._http.delete(this.apiUrl + '/merchants/' + merchant_id);
  }

  getMerchant(merchant_id?: string): Observable<Merchant> {
    const merchant = merchant_id || this.getMerchantId();
    return this._http.get<Merchant>(this.apiUrl + '/merchants/' + merchant);
  }

  getMerchants(): Observable<Merchant[]> {
    return this._http.get<Merchant[]>(this.apiUrl + '/merchants');
  }
}
