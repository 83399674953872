import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { MenuController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

@Component({
  selector: 'masina-item-view',
  templateUrl: `./masina-item-view.component.html`,
  styleUrls: ['./masina-item-view.component.scss']
})
export class MasinaItemView {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
             'SECONDARY_ROUTE' | // used on deviz to create a new car
             'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
             'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG

  // END KEEP

  apiUrl: string = environment.apiUrl;

  formEnabled: boolean = false;
  defaultItem: any = {
    "proprietar_id": null,
    "serie_sasiu": null, // "XXXXXXXXXXXXXX3",
    "tip_motor_masina": null, // "diesel",
    "cap_cil_masina": null, // "1598",
    "kw_masina": null, // "104",
    "an_fab_masina": null, // "2002",
    "model_masina": null, // "Avensis",
    "marca_masina": null, // "Toyota",
    "nr_masina": null, // "GL14TDS"
  }
  richTextContent: string = '';
  quillOptions: any = {
    // later: pictures in editor
    toolbar: ['bold', 'italic', 'underline', { 'header': 1 }, { 'header': 2 }, { 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]
  }

  proprietari: any = [];
  currentOwner: any = null;

  // form
  proprietar_id_ctrl = new FormControl();
  serie_sasiu_ctrl = new FormControl(null, [
    Validators.required,
    Validators.pattern(/^.{17}$/)
  ]);
  nr_masina_ctrl = new FormControl(null, [
    Validators.required
  ]);
  tip_motor_masina_ctrl = new FormControl(null, [
    Validators.required
  ]);
  cap_cil_masina_ctrl = new FormControl();
  kw_masina_ctrl = new FormControl();
  an_fab_masina_ctrl = new FormControl();
  model_masina_ctrl = new FormControl(null, [
    Validators.required
  ]);
  marca_masina_ctrl = new FormControl(null, [
    Validators.required
  ]);

  form: FormGroup = this.fb.group({
    proprietar_id: this.proprietar_id_ctrl,
    serie_sasiu: this.serie_sasiu_ctrl,
    nr_masina: this.nr_masina_ctrl,
    tip_motor_masina: this.tip_motor_masina_ctrl,
    cap_cil_masina: this.cap_cil_masina_ctrl,
    kw_masina: this.kw_masina_ctrl,
    an_fab_masina: this.an_fab_masina_ctrl,
    model_masina: this.model_masina_ctrl,
    marca_masina: this.marca_masina_ctrl,
  });

  mergedCars: string[] = [];
  mergedLicenceNumbers: string[] = [];


  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private menuCtrl: MenuController,
    private _interface: InterfaceService
  ) {
  }

  // specific for creating a car on devize view and automatically associate it with a proprietar
  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      if (params['uuids']) {
        // this.mergedCars = ['f10007f8-e50e-4391-afc3-73794f29e894', '7ca83e3f-67d2-43f6-853f-386e36bbf3d9'];
        // this.mergedLicenceNumbers = ['GL30KTX', 'IT'];

        this.mergedCars = Array.isArray(params['uuids']) ? params['uuids'] : [params['uuids']];
        this.mergedLicenceNumbers = Array.isArray(params['license_numbers']) ? params['license_numbers'] : [params['license_numbers']];
        // now mark the new devize and piese ?? not necessary now, see if at least its' refreshed
      }
    });

    /* BLOCK THAT FIGURES OUT THE STATE */
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }
    // ELSE, REMAINS 'PRIMARY_ROUTE'
    /* END BLOCK THAT FIGURES OUT THE STATE */


    const masinaId = this._activatedRoute.snapshot.paramMap.get('masina_id');
    if (masinaId) {
      this.getProprietari(masinaId);
      this.getMasina(masinaId);
    } else {
      this.form.enable();
      this.formEnabled = true;
    }

    // ROUTE
    this._activatedRoute.queryParams.subscribe(params => {
      const numar = params['prefill_numar'];
      const serie = params['prefill_serie'];
      const proprietarId = params['proprietar_id'];

      if (numar) {
        this.defaultItem.nr_masina = numar;
        this.form.setValue(Object.assign(this.form.value, { nr_masina: numar }));
      }
      if (serie) {
        this.defaultItem.serie_sasiu = serie
        this.form.setValue(Object.assign(this.form.value, { serie_sasiu: serie }));
      }

      if (proprietarId) {
        this.getProprietar(proprietarId);
        this.defaultItem = Object.assign(this.defaultItem, { proprietar_id: proprietarId });
        this.form.setValue(Object.assign(this.form.value, { proprietar_id: proprietarId }));
      }

      this.menuCtrl.close();
    });

    // FORM
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (!control) return;
      control.valueChanges.subscribe(value => {
        if (!value) return;
        control.setValue(value.toUpperCase(), { emitEvent: false });
      });
    });
  }

  // in case of edit
  getMasina(masinaId: any): void {
    this.form.disable();
    this.formEnabled = false;

    this._http.get(`${this.apiUrl}/masini/${masinaId}`).subscribe((masina: any) => {

      this.defaultItem = masina;
      this.richTextContent = this.defaultItem.observatii;
      this.form.setValue({
        // temp - because there is no way correntyl to add a proprietar to a car. so it is set by default with null, and if there is a property, a new relation is created
        // !!!!!!! TODO: try to make a put request with the same proprietar id
        proprietar_id: null,
        serie_sasiu: this.defaultItem['serie_sasiu'] || null,
        nr_masina: this.defaultItem['nr_masina'] || null,
        tip_motor_masina: this.defaultItem['tip_motor_masina'] || null,
        cap_cil_masina: this.defaultItem['cap_cil_masina'] || null,
        kw_masina: this.defaultItem['kw_masina'] || null,
        an_fab_masina: this.defaultItem['an_fab_masina'] || null,
        model_masina: this.defaultItem['model_masina'] || null,
        marca_masina: this.defaultItem['marca_masina'] || null
      });
    });
  }

  edit(): void {
    if (!this.formEnabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.formEnabled = !this.formEnabled;
  }

  // save to db
  saveMasina(): void {
    console.log('salveaza 1', this.defaultItem);
    if (this.serie_sasiu_ctrl?.errors) {
      if (this.serie_sasiu_ctrl.errors['pattern'] || this.serie_sasiu_ctrl.errors['required']) {
        this._interface.presentToast('Completati casutele obligatorii', 'danger');
        return;
      }
    }

    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    body.observatii = this.richTextContent;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (!body.proprietar_id) {
      delete body.proprietar_id;
    }

    if (this.currentOwner) {
      body.proprietar_id = this.currentOwner.proprietar_id;
    }

    console.log('salveaza 2', this.defaultItem);

    this._http.post(`${this.apiUrl}/masini`, body, { headers: headers }).subscribe((response: any) => {
      this.currentOwner = null;
      this.proprietar_id_ctrl.reset();
      this.getProprietari(response.masina_id);

      console.log('salveaza 3', response);

      this._interface.presentToast('Masina a fost adaugata in baza de date');

      // add qp without changing the form on the main route
      if (this.viewState === 'SECONDARY_ROUTE') {
        this._router.navigate([{ outlets: { secondary: null } }], {
          relativeTo: this._activatedRoute,
          queryParams: { masina_id: response.masina_id },
          queryParamsHandling: 'merge'
        });
        this.menuCtrl.close();
      } else {
        this._router.navigate(['tabs', 'tab3', 'masini-item', response.masina_id])
      }
    }, error => {
      console.log('salveaza 4', error);
      
      if (error.error.error === 'O masina cu aceasta serie de sasiu exista deja in baza de date') {
        this.form.controls['serie_sasiu'].setErrors(['eerr']);
      } 
      
      this._interface.presentToast(error.error.error, 'danger');
    });
  }

  updateMasina(): void {
    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    body.observatii = this.richTextContent;

    if (!body.proprietar_id) {
      delete body.proprietar_id;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this._http.put(`${this.apiUrl}/masini/${this.defaultItem.masina_id}`, body, { headers: headers }).subscribe(response => {
      this.edit();

      this.currentOwner = null;
      this.proprietar_id_ctrl.reset();
      this.getProprietari(this.defaultItem.masina_id);
      this.getMasina(this.defaultItem.masina_id);

      this._interface.presentToast('Noile informatii au fost salvate')
    }, error => {
      console.error('There was an error: on edit', error);
    });
  }

  deleteMasina(): void {
    const confirmation = confirm("Doriti sa eliminati masina?");
    if (!confirmation) {
      return;
    }
    this._http.delete(`${this.apiUrl}/masini/${this.defaultItem.masina_id}`).subscribe(masina => {
      this._router.navigate(['tabs/tab3']);
    });
  }

  async selectProprietar(): Promise<void> {
    this._interface.proprietarList();
  }

  async createProprietar(): Promise<void> {
    this._interface.proprietarCreate();
  }

  async addPiesa(): Promise<void> {
    this._interface.piesaCreate(this.defaultItem.masina_id);
  }

  getProprietari(masinaId: string): void {
    this._http.get(`${this.apiUrl}/masina/${masinaId}/proprietari`).subscribe(proprietari => {
      this.proprietari = proprietari;
    });
  }

  getProprietar(proprietar_id: string): void {
    this._http.get(`${this.apiUrl}/proprietari/${proprietar_id}`).subscribe(proprietar => {
      this.currentOwner = proprietar;
    });
  }

  isOwner(): boolean {
    return this.proprietari.some((obj: any) =>
      Object.keys(this.currentOwner).every(key => obj[key] === this.currentOwner[key])
    );
  }

  removeOwner(proprietar: any): void {
    const confirmation = confirm("Doriti sa eliminati?");
    if (!confirmation) {
      return;
    }

    this._http.delete(`${this.apiUrl}/proprietari_masini/${proprietar.proprietar_id}/${this.defaultItem.masina_id}`).subscribe(deletedOwner => {
      this.getProprietari(this.defaultItem.masina_id);
      this.currentOwner = null;
      this.proprietar_id_ctrl.reset();
      this._interface.presentToast(proprietar.nume_proprietar + ' nu mai este proprietarul masinii');
    });
  }

  checkOwner(owner: any): void {
    console.log('check owner', owner);
    const url = this._router.serializeUrl(this._router.createUrlTree(['/tabs/tab4/proprietari-item/' + owner.proprietar_id]));
    window.open(url, '_blank');
  }

  selectionChange(event: any): void {
    this.currentOwner = event.source.value;
  }

  createDeviz(): void {
    // if (!this.currentOwner?.proprietar_id) {
    //   this._interface.presentToast('Un proprietar trebuie selectat', 'danger');
    //   return;
    // }

    this._router.navigate(['/tabs/tab1/deviz-create'], { queryParams: { masina_id: this.defaultItem.masina_id/*, proprietar_id: this.currentOwner.proprietar_id*/ } });
  }

  startMerge(): void {
    // when receiving merge id, do something, or receive query with merged items. or dunno trigger a modal with changes.. give possibility to undo
    this._interface.masinaList('', this.defaultItem.masina_id);
  }
}