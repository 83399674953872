<div class="page" style="overflow: scroll;">
  <div class="pageContent">
        <!-- <mat-expansion-panel hideToggle class="w-full mt-2" *ngIf="!standalone">
          <mat-expansion-panel-header>
            <mat-panel-title class="flex flex-row justify-between items-center">
              <strong class="block text-base hover:underline opacity-75">Sabloane</strong>
              <ion-icon class="block" name="apps-outline"></ion-icon>
            </mat-panel-title> 
          </mat-expansion-panel-header>
          <deviz-widget [justTemplates]="true" class="w-full"></deviz-widget>
        </mat-expansion-panel> -->

        <div class="grid grid-cols-1 md:grid-cols-5 w-full">
          <deviz-list-filters [filters]="filters" (triggerSearch)="setFilters($event)" class="col-span-5"></deviz-list-filters>
        </div>
        
        <span *ngIf="!devize_deschise.length" class="text-2xl font-bold col-span-1 md:col-span-2">Nu exista devize deschise</span>
        <table mat-table [dataSource]="devize_deschise" class="mat-elevation-z8 masini-table" *ngIf="devize_deschise.length">
      
          <ng-container matColumnDef="numar_deviz">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Numar deviz </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span [ngClass]="{'highlight-class': isMatch(item, 'numar_deviz')}" class="text-ellipsis">#{{ item.numar_deviz }} </span>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="nr_masina">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Numar masina </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span [ngClass]="{'highlight-class': isMatch(item, 'nr_masina')}" class="text-ellipsis">{{ item.nr_masina }} </span>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="nume_proprietar">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Nume proprietar </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span [ngClass]="{'highlight-class': isMatch(item, 'nume_proprietar')}" class="text-ellipsis">{{ item.nume_proprietar }} </span>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="executant">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Executant </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span class="text-ellipsis">{{ item.executant }} </span>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="total">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Total </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span class="text-ellipsis">{{ item.total_general | number:'1.2-2' }} RON </span>
            </td>
          </ng-container>
      
          <!-- Model Masina Column -->
          <ng-container matColumnDef="closed">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let item" class="whitespace-nowrap overflow-hidden"> 
              <span class="text-ellipsis">
                <!-- <span *ngIf="!item.closed">DESCHIS</span>
                <span *ngIf="item.closed">INCHIS</span> -->
                <span>{{item.status}}</span>
              </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="options">
            <th class="font-bold" mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let item" (click)="selectItem(item);closeModal();" class="cursor-pointer"> 
              <a [routerLink]="['/tabs/tab1/deviz-item/' + item.deviz_id]" class="highlight-class">
                <ion-icon name="enter-outline" class="text-2xl relative" style="top: 3px; right: 1px;"></ion-icon>
              </a>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="columns.value"></tr>
          <tr mat-row *matRowDef="let row; columns: columns.value"></tr>
          
        </table>

        <!-- <mat-paginator [length]="totalItems" 
               [pageSize]="filters.per_page" 
               [pageIndex]="filters.page - 1" 
               (page)="pageChange($event)" class="w-full">
        </mat-paginator> -->
      </div>
</div>

