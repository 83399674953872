import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MasinaListFilters } from '../masina-list-filters/masina-list-filters.component';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import * as _ from 'lodash';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'masina-list',
  templateUrl: 'masina-list.component.html',
  styleUrls: ['masina-list.component.scss']
})
export class MasinaList {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
    'SECONDARY_ROUTE' | // used on deviz to create a new car
    'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
    'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  merge_id: string | null = null;
  mergingCar: any = null;
  mergeOptions: any = {
    devize: true,
    proprietari: true,
    lucrari: true
  }
  selectedCars: any[] = [];

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  @Input() filters: any = {
    search_query: null,
    serie_sasiu: null,
    nr_masina: null,
    proprietar_id: null,
    start_date: '',
    end_date: '',
    page: 1,
    per_page: 50
  };
  totalItems: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isPageChangeTriggered: boolean = false;

  // posibil fiters ceva
  // DIALOG

  masini: any[] = [];
  apiUrl: string = environment.apiUrl;
  columns = new FormControl('');
  tableColumns: string[] = ['select', 'serie_sasiu', 'nr_masina', 'marca_masina', 'model_masina', 'tip_motor_masina', 'an_fab_masina', 'cap_cil_masina', 'kw_masina'/*, 'masina_id'*/, 'options'];
  defaultTableColumns: string[] = ['select', 'serie_sasiu', 'nr_masina', 'marca_masina', 'model_masina', 'tip_motor_masina', 'an_fab_masina', 'cap_cil_masina', 'kw_masina'/*, 'masina_id'*/, 'options'];
  // END KEEP

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private menuCtrl: MenuController,
    private dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private _toastController: ToastController,
    private _interface: InterfaceService
  ) { }

  ngOnInit(): void {
    /* BLOCK THAT FIGURES OUT THE STATE */
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }
    if (this.filters.proprietar_id) {
      this.viewState = 'STANDALONE';
    }
    // ELSE, REMAINS 'PRIMARY_ROUTE'
    /* END BLOCK THAT FIGURES OUT THE STATE */


    this.setTable(this.defaultTableColumns);
    if (this.viewState === 'STANDALONE') {
      this.setTable(['marca_masina', 'model_masina', 'nr_masina']);
    }

    this.breakpointObserver
      .observe(['(min-width: 700px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches && this.viewState !== 'STANDALONE') {
          this.setTable(this.defaultTableColumns);
        } else {
          this.setTable(['marca_masina', 'model_masina', 'nr_masina', 'options'])
        }
      });

      this._activatedRoute.queryParams.subscribe(params => {
        this.merge_id = params['merge_id_masina'];
        if(this.merge_id) {
          this.getMasina(this.merge_id);
          this.setTable();
        }

        if(params['prefill_numar']) {
          this.filters.search_query = params['prefill_numar'];
          this.getMasini();
        }

        if(params['prefill_serie']) {
          this.filters.search_query = params['prefill_serie'];
          this.getMasini();
        }
      });

    this.getMasini();
  }

  // DATA RELATED
  getMasini(newFilters?: any): void {
    let params = new HttpParams();
    const filters = newFilters ? Object.assign({}, this.filters, newFilters) : this.filters;
    this.filters = _.cloneDeep(filters);

    if (filters.search_query) {
      filters.q = filters.search_query;
    }

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        params = params.append(key, filters[key]);
      }
    });

    params = params.append('page', this.filters.page);
    params = params.append('per_page', this.filters.per_page);

    this._http.get(`${this.apiUrl}/masini`, { params: params }).subscribe((masini: any) => {
      this.masini = masini.data;
      this.totalItems = masini.total_items;

      this.filters.page = masini.current_page;
    });
  }

  setFilters(newFilters: any): void {
    if(!_.isEqual(newFilters, this.filters)) {
      this.filters = newFilters;
      this.getMasini();
    }
  }

  pageChange(event: any): void {
    this.filters.page = event.pageIndex + 1;
    this.getMasini(this.filters);
  }

  // FORM
  clear(): void {
    this.filters = {
      search_query: '',
      serie_sasiu: '',
      nr_masina: '',
      proprietar_id: '',
      start_date: '',
      end_date: '',
      page: 1,
      per_page: 50
    };

    this.getMasini();
  }

  // INTERFACE RELATED
  selectItem(item: any): void {
    if(this.merge_id) {
      const url = this._router.serializeUrl(this._router.createUrlTree(['/tabs/tab3/masini-item/' + item.masina_id]));
      window.open(url, '_blank');
      return;
    }
    if (this.viewState === 'SECONDARY_ROUTE') {
      this._router.navigate([
        { outlets: { secondary: null } }], {
        relativeTo: this._activatedRoute,
        queryParams: { masina_id: item.masina_id },
        queryParamsHandling: 'merge'
      });
      this._interface.clearMenu()
    } else {
      this._router.navigate(['/tabs/tab3/masini-item/' + item.masina_id])
    }
  }

  copyLink(text: string): void {
    // if (this.viewState === 'SECONDARY_ROUTE') {}
    this._interface.copyLink(text);
  }

  getColumnDisplayName(column_id: string): string {
    switch (column_id) {
      case 'serie_sasiu':
        return 'Serie sasiu'
      case 'tip_motor_masina':
        return 'Tip motor'
      case 'an_fab_masina':
        return 'An Fabricație'
      case 'cap_cil_masina':
        return 'Capacitate cilindrica'
      case 'kw_masina':
        return 'Putere Motor (kW)'
      case 'marca_masina':
        return 'Marcă'
      case 'model_masina':
        return 'Model'
      case 'nr_masina':
        return 'Număr Mașină'
      case 'options':
        return 'Optiuni'
      case 'select':
        return 'Select'
      default:
        return ''
    }
  }

  setTable(columns?: any): void {
    let cols = columns ? columns : this.columns.getRawValue();
    console.log('baaaa',)
    if(this.merge_id) {
      console.log('doing?0');
      cols.unshift('select');
    } else {
      cols = cols.filter((item: any) => item !== 'select');
    }
    
    // else 
    if(this.viewState === 'SECONDARY_ROUTE' && !this.merge_id) {
      console.log('doing?1', this.merge_id);
      // this.tableColumns.unshift(this.tableColumns.pop()!);
      // this.defaultTableColumns.unshift(this.defaultTableColumns.pop()!);
      // cols.unshift(cols.pop());

      const index = cols.indexOf('options');
      if (index !== -1) {
        const options = cols.splice(index, 1)[0]; // Remove and store 'options'
        cols.unshift(options); // Add it to the beginning
      }

    } else if(this.merge_id) {
      // put options back last
      const index = cols.indexOf('options');
      if (index !== -1) {
        const options = cols.splice(index, 1)[0]; // Remove and store 'options'
        cols.push(options); // Add it to the end
      }
    }

    // deselect optional fields
    if(this.viewState === 'SECONDARY_ROUTE') {
      // 'tip_motor_masina' 'cap_cil_masina', 'kw_masina'
      cols = cols.filter((item: any) => (item !== 'tip_motor_masina' && item !== 'cap_cil_masina' && item !== 'kw_masina'));
    }

    console.log('set cols', cols);
    this.columns.setValue(cols);
  }

  isMatch(item: any, field: 'nr_masina' | 'serie_sasiu'): boolean {
    if (!this.filters.search_query) return false;
    return item[field]?.toString().toLowerCase().includes(this.filters.search_query.toString().toLowerCase());
  }

  // other uses of the component - as modal for merging

  getMasina(masinaId: any): void {
    this._http.get(`${this.apiUrl}/masini/${masinaId}`).subscribe((masina: any) => {
      this.mergingCar = masina;
    })
  }

  merge(): void {

    // this._interface.clearMenu();
    // setTimeout(() => {
    //   this._router.navigate([], {
    //     relativeTo: this._activatedRoute,
    //     queryParams: { uuids: this.selectedCars, license_numbers: this.masini.filter(car => this.selectedCars.includes(car.masina_id)).map(c => c.nr_masina)},
    //     queryParamsHandling: 'merge' // preserves the existing query params
    //   });
    // }, 1000);
    // return;

    // confirm('Esti sigur ca vrei sa stergi masinile selectate si toate datele lor sa fie transferate la ' + this.mergingCar.nr_masina + ' - ' + this.mergingCar.serie_sasiu)
    if (confirm('Doresti ca toate datele masinilor selectate sa fie transferate la ' + this.mergingCar.nr_masina + ' - ' + this.mergingCar.serie_sasiu)) {
    
      let params = new HttpParams();
      if (this.mergeOptions.lucrari !== undefined) {
        params = params.set('lucrari', this.mergeOptions.lucrari.toString());
      }
      if (this.mergeOptions.proprietari !== undefined) {
        params = params.set('proprietari', this.mergeOptions.proprietari.toString());
      }
      if (this.mergeOptions.devize !== undefined) {
        params = params.set('devize', this.mergeOptions.devize.toString());
      }
  
      const body = {
        masina_id: this.merge_id,
        masina_ids: this.selectedCars
      };
  
      this._http.post<any>(this.apiUrl + '/merge_cars', body, { params }).subscribe(data => {
        // do toast
        this._interface.presentToast('Datele masinilor selectate au fost transferate. Masinile selectate au fost eliminate din baza de date.');
        this.merge_id = null;

        // add qp with array of car ids
        this._interface.clearMenu();
        setTimeout(() => {
          this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: { uuids: this.selectedCars, license_numbers: this.masini.filter(car => this.selectedCars.includes(car.masina_id)).map(c => c.nr_masina)},
            queryParamsHandling: 'merge' // preserves the existing query params
          });
          this.selectedCars = [];
        }, 1000);
      })
    }
    return
  }

  addCarForMerge(masina_id: string): void {
    const index = this.selectedCars.indexOf(masina_id);
    if (index !== -1) {
      // If the string exists, remove it
      this.selectedCars.splice(index, 1);
    } else {
      // Add the string to the array
      this.selectedCars.push(masina_id);
    }
  }
}
