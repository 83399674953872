import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, debounceTime, map, of, startWith, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/home/auth.service';
import { ToastController } from '@ionic/angular';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

interface DialogData {
  any?: any
}

@Component({
  selector: 'nomenclator-item-view',
  templateUrl: `./nomenclator-item-view.component.html`,
  styleUrls: ['./nomenclator-item-view.component.scss']
})
export class NomenclatorItemView {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
    'SECONDARY_ROUTE' | // used on deviz to create a new car
    'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
    'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG

  // END KEEP
  apiUrl: string = environment.apiUrl;
  formEnabled: boolean = false;

  defaultItem: any = {
    "U/M": null, // only materiale
    "norma_de_timp": null, // only manopera
    "type": null, // only manopera
    "operation_name": null,
    "pret_unitar_fara_tva_lei": null,
    "pret_unitar_cu_tva_lei": null,
  }

  // form
  umCtrl = new FormControl();
  normaDeTimpCtrl = new FormControl();
  typeCtrl = new FormControl();
  operationNameCtrl = new FormControl();
  pretFaraTVACtrl = new FormControl();
  pretCuTVACtrl = new FormControl();
  form: FormGroup = this.fb.group({
    "U/M": this.umCtrl,
    "norma_de_timp": this.normaDeTimpCtrl,
    "type": this.typeCtrl,
    "operation_name": this.operationNameCtrl,
    "pret_unitar_fara_tva_lei": this.pretFaraTVACtrl,
    "pret_unitar_cu_tva_lei": this.pretCuTVACtrl
  });

  defaults: any = this._authService.getDefaults();

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NomenclatorItemView>,
    private _authService: AuthService,
    private _toastController: ToastController,
    private _interface: InterfaceService,

    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  ngOnInit(): void {
    // figure out state
    this.viewState = !!Object.keys(this.data).length ? 'DIALOG' : this.viewState;
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }

    const operationId = this._activatedRoute.snapshot.paramMap.get('operation_id');
    if (operationId)
      this.getOperation(operationId);

    const prefill = this._activatedRoute.snapshot.queryParamMap.get('prefill');
    if (prefill)
      this.form.setValue(Object.assign({}, this.form.value, { operation_name: prefill }));

    this.normaDeTimpCtrl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe(pret => this.calculatePretFaraTva(pret))

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (!control) return;
      control.valueChanges.subscribe(value => {
        if (!value || typeof value === 'number') return;
        control.setValue(value.toUpperCase(), { emitEvent: false })
      });
    });

    if (this.viewState === 'PRIMARY_ROUTE') {
      this.pretFaraTVACtrl.disable();
    }
  }

  calculatePretFaraTva($event: number): void {
    const pretFaraTVA = $event * this.defaults.pretNorma;
    this.pretFaraTVACtrl.setValue(pretFaraTVA.toFixed(2));
  }

  // in case of edit
  getOperation(operationId: any): void {
    this.form.disable();

    const page = 1;
    const pageSize = 10;
    this._http.get(`${this.apiUrl}/nomenclator/${operationId}?page=${page}&page_size=${pageSize}`).subscribe((operation: any) => {

      this.defaultItem = operation;
      this.form.setValue({
        "U/M": null,
        "norma_de_timp": operation['norma_de_timp'],
        "operation_name": operation['operation_name'],
        "pret_unitar_fara_tva_lei": operation['pret_unitar_fara_tva_lei'],
        "pret_unitar_cu_tva_lei": operation['pret_unitar_cu_tva_lei'],
        "type": operation['type']
      });
    });
  }

  edit(): void {
    if (!this.formEnabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.formEnabled = !this.formEnabled;
  }

  // save to db

  saveOperation(): void {
    this.pretFaraTVACtrl.enable();
    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    body.type = 'manopera';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    delete body['U/M'];

    this._http.post(`${this.apiUrl}/nomenclator`, body, { headers: headers }).subscribe((response: any) => {
      this.pretFaraTVACtrl.disable();
      this._interface.presentToast('Operatiunea a fost adaugata in baza de date');

      if (this._interface.isSecondaryOutletActive()) {
        this._router.navigate([{ outlets: { secondary: null } }], {
          relativeTo: this._activatedRoute,
          queryParams: { operation_id: response.operation_id },
          queryParamsHandling: 'merge'
        });
        this._interface.clearMenu();
      } else {
        this._router.navigate(['tabs', 'tab2', 'nomenclator-item', response.operation_id]);
      }
    }, error => {
      console.error('There was an error:', error);
    });
  }

  updateOperation(): void {
    const body: any = Object.assign({}, this.defaultItem, this.form.value)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this._http.put(`${this.apiUrl}/nomenclator/${this.defaultItem.operation_id}`, body, { headers: headers }).subscribe(response => {
      this.edit();
    }, error => {
    });
  }

  deleteOperation(): void {
    this._http.delete(`${this.apiUrl}/nomenclator/${this.defaultItem.operation_id}`).subscribe(operation => {
      this._router.navigate(['tabs/tab2'])
    });
  }
}