import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-saving-dialog',
  template: `
  <mat-card>
    <mat-card-content class="text-3xl font-bold">
        <h1>{{ data.message }}</h1>
    </mat-card-content>
  </mat-card>
  `,
})
export class SavingDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) { }
}