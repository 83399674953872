<div class="w-full h-full">
    <div class="flex flex-row w-full">
        <button mat-raised-button (click)="setSelectedToSong()">Set selected to song</button>
        <button mat-raised-button (click)="setSelectedToFilters()">Set selected to filters</button>
        <!-- <button mat-raised-button (click)="sendToListen()">Send to listen</button> -->
    </div>

    <mat-divider class="my-2 w-full"></mat-divider>
    <div class="flex flex-col w-full overflow-y-auto">
        <!-- by type each strip -->
        <span>Selected tags:</span>
        <div *ngFor="let item of selectedTags" class="flex flex-row overflow-auto w-full">
            <button mat-flat-button color="accent" (click)="removeTag(item.id)">
              <p>{{item.tag}}</p>
            </button>

            <button mat-stroked-button color="warn" *ngIf="songId" (click)="removeTagFromSong(item.id)">
                x
            </button>

            <button mat-stroked-button (click)="item.blacklist = !item.blacklist">
                [
                <span *ngIf="item.blacklist">*</span>
                ]
            </button>
        </div>
    </div>

    <mat-divider class="my-2 w-full"></mat-divider>
    <div class="flex flex-col w-full">
        <!-- do by type -->
        <span>All tags:</span>
        <div *ngFor="let type of types" class="flex flex-col">
            <span>{{type}}</span>
            <div *ngFor="let item of getTagsByType(type)" class="flex flex-row overflow-auto w-full">
                <button mat-flat-button (click)="selectTag(item)" [disabled]="item.disabled">
                  <p>{{item.tag}} - {{ item.Description }}</p>
                </button>
                <button mat-flat-button (click)="deleteTag(item.id)" *ngIf="!songId">
                    Delete
                </button>
                <button mat-flat-button (click)="editTag(item)" *ngIf="!songId">
                    Edit
                </button>
            </div>
        </div>
        <div class="flex flex-col">
            <span>Untyped</span>
            <div *ngFor="let item of getTagsByType(null)" class="flex flex-row overflow-auto w-full">
                <button mat-flat-button (click)="selectTag(item)">
                    <p>{{item.tag}} - {{ item.Description }}</p>
                  </button>
                  <button mat-flat-button (click)="deleteTag(item.id)">
                      Delete
                  </button>
                  <button mat-flat-button (click)="editTag(item)">
                      Edit
                  </button>
            </div>
        </div>

    </div>

    <mat-divider class="my-2 w-full"></mat-divider>
    <div class="flex flex-row w-full">
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search term" type="text" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}" (ngModelChange)="filterTags($event)" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Tag Name</mat-label>
            <input matInput placeholder="Tag Name" type="text" [(ngModel)]="newTag.tag" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tag Type</mat-label>
            <input matInput placeholder="Tag Type" type="text" [(ngModel)]="newTag.type" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tag Color</mat-label>
            <input matInput placeholder="Tag Color" type="text" [(ngModel)]="newTag.Color" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Tag Description</mat-label>
            <input matInput placeholder="Tag Description" type="text" [(ngModel)]="newTag.Description" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
        <button mat-raised-button (click)="createTag(newTag.tag, newTag.type, newTag.Color, newTag.Description)">Create</button>
        <button mat-stroked-button (click)="updateTag(newTag.id, newTag.tag, newTag.type, newTag.Color, newTag.Description)">Update</button>
    </div>

</div>

