export const environment = {
  production: true,
  // apiUrl: 'http://python-api.cartedevizita.info',
  // merchantId: '293872c8-5967-4f58-9cb1-95c27a8d7e27'
  // apiUrl: 'http://192.168.0.118:5001'
  // apiUrl: 'http://192.168.0.118:5001'

  merchantId: null,
  apiUrl: 'https://demo-api.cartedevizita.info/'
};
