import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DevizPrint } from './deviz/components/deviz-print/deviz-print.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from "@sentry/angular-ivy";

import { AuthInterceptor } from './home/auth.interceptor';
registerLocaleData(localeFr);

// inter live
// const firebaseConfig = {
//   apiKey: "AIzaSyBD-2eyIUD77mnPNb2dqAno1c882-1PFqI",
//   authDomain: "inter-live-e9397.firebaseapp.com",
//   projectId: "inter-live-e9397",
//   storageBucket: "inter-live-e9397.appspot.com",
//   messagingSenderId: "567011464458",
//   appId: "1:567011464458:web:c28d2d29c960c83729d6f3"
// };

// demo
const firebaseConfig = {
  apiKey: "AIzaSyA80JKZLMm6jxAmWxoRfvOtOageNAw1kgw",
  authDomain: "inter-demo-fc723.firebaseapp.com",
  projectId: "inter-demo-fc723",
  storageBucket: "inter-demo-fc723.appspot.com",
  messagingSenderId: "1066437437073",
  appId: "1:1066437437073:web:e68a66c512bf642a854130"
};

@NgModule({
  declarations: [AppComponent, DevizPrint],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,     BrowserAnimationsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
