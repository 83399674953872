import { Component, Renderer2 } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/home/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  constructor(
    private menuCtrl: MenuController, 
    private router: Router,
    private renderer: Renderer2,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'is-menu');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'is-menu');
  }

  async clearMenu(): Promise<void> {
    await this.menuCtrl.close('second-menu');
    // Navigate with outlets if needed, uncomment the next line and adjust as necessary
    // this.router.navigate([{outlets: { secondary: null}}], { skipLocationChange: false });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
    this.clearMenu()
  }

  logout(): void {
    this._authService.logout().then(res => {
      this.clearMenu();
    });
  }
}