import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { MenuController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InterfaceService } from 'src/app/deviz/providers/interface.service';

@Component({
  selector: 'piesa-item-view',
  templateUrl: `./piesa-item-view.component.html`,
  styleUrls: ['./piesa-item-view.component.scss']
})
export class PiesaItemView {
  // START KEEP
  viewState: 'PRIMARY_ROUTE' | // normal item route
             'SECONDARY_ROUTE' | // used on deviz to create a new car
             'STANDALONE' | // not applicable, this is mainly on item view's where there's a list of this type partaining it
             'DIALOG' = 'PRIMARY_ROUTE'; // think not applicable anywhere

  // PRIMARY_ROUTE  
  // SECONDARY_ROUTE
  // STNADALONE
  // DIALOG

  // END KEEP

  // @Output() itemAdded = new EventEmitter<any>();
  // @Input() isView = true;

  apiUrl: string = environment.apiUrl;

  itemPage: boolean = false;
  formEnabled: boolean = false;
  defaultItem: any = {
    lucrare_id: null,           // Assuming UUID or similar, so null is a placeholder
    fk_masina_id: null,         // Similarly, assuming a reference to another entity
    adaos: null,         // Similarly, assuming a reference to another entity
    pret_unitar_fara_tva_lei: null,         // Similarly, assuming a reference to another entity
    old_excel_index: null,      // Example: "12345"
    denumire_piesa: null,       // Example: "Filtru Aer"
    cod_oe_piesa: null,         // Example: "OE12345678"
    denumire_producator: null,  // Example: "Bosch"
    cod_producator: null,       // Example: "1234ABCD"
    approximate_date: null,     // Example: "2023-01-01"
  }

  // form
  
  @ViewChild('denumirePiesaInput') denumirePiesaInput!: ElementRef;
  form = new FormGroup({
    lucrare_id: new FormControl(''),
    fk_masina_id: new FormControl(''),
    adaos: new FormControl(0),
    pret_unitar_fara_tva_lei: new FormControl(0),
    old_excel_index: new FormControl('9999999'),
    denumire_piesa: new FormControl('', [
      Validators.required,
      // Validators.pattern(/^.{17}$/)
    ]),
    cod_oe_piesa: new FormControl('', [
      Validators.required,
      // Validators.pattern(/^.{17}$/)
    ]),
    denumire_producator: new FormControl(''),
    cod_producator: new FormControl(''),
    approximate_date: new FormControl(''),
    // ... other existing controls ...
  });


  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private menuCtrl: MenuController,
    private _toastController: ToastController,
    private _cdr: ChangeDetectorRef,
    private _interface: InterfaceService
  ) {

  }

  async addMasina(): Promise<void> {
    this._router.navigate([{outlets: { 
      secondary: 'masini-list',
    }}], { 
      // queryParams: { proprietar_id: this.defaultDeviz.proprietar_id },
      skipLocationChange: false 
    });
    await this.menuCtrl.open('second-menu');
  }

  // specific for creating a car on devize view and automatically associate it with a proprietar
  ngOnInit(): void {
    // figure out state
    if (this._interface.isSecondaryOutletActive()) {
      this.viewState = 'SECONDARY_ROUTE';
    }

    this.form.controls.fk_masina_id.disable();
    const piesaId = this._activatedRoute.snapshot.paramMap.get('piesa_id');
    const adaos = this._activatedRoute.snapshot.paramMap.get('adaos');
    console.log('vine?', piesaId, adaos);
    if (piesaId)
      this.getPiesa(piesaId, adaos);

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if(!control) return;
      control.valueChanges.subscribe(value => {
        if(!value || typeof value === 'number') return;
        control.setValue(value.toUpperCase(), { emitEvent: false })
      });
    });

    const queryParamsSubscription = this._activatedRoute.queryParams.subscribe(params => {
      const masinaId = params['masina_id'];      
      const piesaId = params['piesa_id'];
      const adaos = params['adaos'];
      if (piesaId)
        this.getPiesa(piesaId, adaos);

      if (masinaId) {
        this.form.enable();
        this.form.controls.fk_masina_id.enable();
        this.formEnabled = true;

        this.defaultItem.fk_masina_id = masinaId;
        
        this.form.setValue({
          lucrare_id: this.defaultItem['lucrare_id'] || null,
          fk_masina_id: this.defaultItem['fk_masina_id'] || null,
          adaos: this.defaultItem['adaos'] || null,
          pret_unitar_fara_tva_lei: this.defaultItem['pret_unitar_fara_tva_lei'] || null,
          old_excel_index: this.defaultItem['old_excel_index'] || '9999999',
          denumire_piesa: this.defaultItem['denumire_piesa'] || null,
          cod_oe_piesa: this.defaultItem['cod_oe_piesa'] || null,
          denumire_producator: this.defaultItem['denumire_producator'] || null,
          cod_producator: this.defaultItem['cod_producator'] || null,
          approximate_date: this.defaultItem['approximate_date'] || null,
        });

        // Remove the firebase_uid query parameter
        this.removeQueryParam('firebase_uid');
      }
      this.form.controls.fk_masina_id.disable();
    });
  }

  ngAfterViewInit() {
    if(this.viewState === 'DIALOG') {
      // this.denumirePiesaInput.nativeElement.focus();
      this._cdr.detectChanges();
    }
  }

  removeQueryParam(paramKey: string): void {
    const queryParams = { ...this._activatedRoute.snapshot.queryParams };
    delete queryParams[paramKey]; // Remove the specific parameter

    // Navigate without the parameter
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // Preserve other query params
    });
  }

  // in case of edit
  getPiesa(piesaId: any, adaos?: any): void {
    this.itemPage = true; // if true deactivated / if true and edit is true
    this.form.disable();
    console.log('vine cu adaos?', adaos);
    this._http.get(`${this.apiUrl}/lucrare/${piesaId}`).subscribe((piesa: any) => {

      this.defaultItem = piesa;
      this.form.setValue({
        // temp - because there is no way correntyl to add a proprietar to a car. so it is set by default with null, and if there is a property, a new relation is created
        // !!!!!!! TODO: try to make a put request with the same proprietar id
        // proprietar_id: null, 
        lucrare_id: this.defaultItem['lucrare_id'] || null,
        fk_masina_id: this.defaultItem['fk_masina_id'] || null,
        adaos: adaos || this.defaultItem['adaos'] || null,
        pret_unitar_fara_tva_lei: this.defaultItem['pret_unitar_fara_tva_lei'] || null,
        old_excel_index: this.defaultItem['old_excel_index'] || '9999999',
        denumire_piesa: this.defaultItem['denumire_piesa'] || null,
        cod_oe_piesa: this.defaultItem['cod_oe_piesa'] || null,
        denumire_producator: this.defaultItem['denumire_producator'] || null,
        cod_producator: this.defaultItem['cod_producator'] || null,
        approximate_date: this.defaultItem['approximate_date'] || null,
      });
    });
  }
  // 655bcbf3-28cf-4a1d-bbe3-688c57cf6232

  edit(): void {
    if (!this.formEnabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.formEnabled = !this.formEnabled;
  }

  // save to db
  savePiesa(): void {
    this.form.controls.fk_masina_id.enable();

    if(this.form.controls.denumire_piesa?.errors || this.form.controls.cod_oe_piesa?.errors) {
      if((<any>this.form)?.controls?.denumire_piesa?.errors['required'] || (<any>this.form)?.controls?.cod_oe_piesa?.errors['required']) {
        this.presentToast('Completati casutele obligatorii', 'danger');
        this.form.updateValueAndValidity();
        return;
      }
    }

    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    const savedAdaos = body.adaos;
    console.log('saving adaos back', savedAdaos);
    body.adaos = '0'; // body.adaos || 0; do not ever save adaos for the moment

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if(!body.proprietar_id) {
      delete body.proprietar_id;
    }

    if(!body.fk_masina_id) {
      delete body.fk_masina_id;
    }

    delete body.lucrare_id;


    this._http.post(`${this.apiUrl}/lucrare`, body, { headers: headers }).subscribe((response: any) => {
      // 
      this.form.controls.fk_masina_id.disable();
      // in this case don't redirect the above
      // check router state
      this.presentToast('Piesa a fost adaugata in baza de date');
      // add qp without changing the form on the main route

      if(this.isSecondaryOutletActive()) {
        this._router.navigate([{outlets: { secondary: null}}], {
          relativeTo: this._activatedRoute,
          queryParams: { lucrare_id: response.lucrare_id, adaos: savedAdaos},
          queryParamsHandling: 'merge'  // Preserve existing query params.
        });
        this.menuCtrl.close();
      } else {
        this._router.navigate(['tabs', 'tab5', 'piese-item', response.lucrare_id])
      }
    }, error => {
      console.error('There was an error:', error);
    });
  }

  async presentToast(message: string, color?: string) {
    const toast = await this._toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      color: color || 'success',
      cssClass: 'text-xl'
    });

    await toast.present();
  }

  isSecondaryOutletActive(): boolean {
    return this._router.url.includes('secondary');
  }

  updatePiesa(): void {
    const body: any = Object.assign({}, this.defaultItem, this.form.value);
    const savedAdaos = body.adaos;
    body.adaos = '0' // body.adaos || '0';
    body.pret_unitar_fara_tva_lei = body.pret_unitar_fara_tva_lei || "0";
    delete body.lucrare_id;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this._http.put(`${this.apiUrl}/lucrare/${this.defaultItem.lucrare_id}`, body, { headers: headers }).subscribe((response: any) => {
      if(this.isSecondaryOutletActive()) 
        this._router.navigate([{outlets: { secondary: null}}], {
          relativeTo: this._activatedRoute,
          queryParams: { lucrare_id: this.defaultItem.lucrare_id, adaos: savedAdaos },
          queryParamsHandling: 'merge'  // Preserve existing query params.
        });
        this.menuCtrl.close();
      
      this.edit();
    }, error => {
      console.error('There was an error: on edit', error);
    });
  }

  deletePiesa(): void {
    const confirmation = confirm("Doriti sa eliminati piesa?");
    if (!confirmation) {
      return;
    }

    this._http.delete(`${this.apiUrl}/lucrare/${this.defaultItem.lucrare_id}`).subscribe(masina => {
      if(this.isSecondaryOutletActive()) {
        this._router.navigate([{outlets: { secondary: null}}], {
          relativeTo: this._activatedRoute,
          queryParams: { lucrare_id: this.defaultItem.lucrare_id },
          queryParamsHandling: 'merge'  // Preserve existing query params.
        });
        this.menuCtrl.close();
      } else {
        this._router.navigate(['tabs/tab5'])
      }
    });
  }
}